import {
	UnlinkedComponent as UnlinkedComponentInterface,
	Database,
	NumericDictionary,
	LinkedComponent,
	Serializable,
	Serialized
} from '../types';

import InventoryItem from './InventoryItem';
import Unit from './Unit';
import Item from './Item';
import ComponentGroup from './ComponentGroup';
import ComponentVersion from './ComponentVersion';
import Config from './Config';
import { serialize } from '../utils/serialize';

export class UnlinkedComponent implements UnlinkedComponentInterface {
	readonly linked = false;
	isValid = false;

	componentId: number;
	name: string;
	suppressNameFromRecipe: boolean;
	ticketName?: string;
	unitId: number;
	inventoryItemId: number;
	servings: number;
	componentVersionId?: number;
	baseComponentId: number;

	itemIds: number[];
	otherVersionItemIds: number[];
	componentGroupIds: number[];
	tax: number;
	otherComponentIds: number[];
	componentVersionIds: number[];
	unitRatios: Database.MappedComponentUnitRatiosRow[];
	associatedModifierId: number;

	isVariableQuantity: boolean;
	static: boolean;

	constructor(
		componentFields: Database.MappedComponentsRow,
		tables: Database.MappedTables
	) {
		this.componentId = componentFields.componentId;
		this.name = componentFields.name;
		this.suppressNameFromRecipe = componentFields.suppressNameFromRecipe;
		this.ticketName = componentFields.ticketName || undefined;
		this.unitId = componentFields.unitId;
		this.inventoryItemId = componentFields.inventoryItemId;
		this.servings = componentFields.servings;
		this.componentVersionId =
			componentFields.componentVersionId || undefined;
		this.baseComponentId =
			componentFields.baseComponentId || this.componentId;
		this.isVariableQuantity = componentFields.isVariableQuantity;
		this.static = componentFields.static || false;

		// item ids
		this.itemIds = tables.itemsComponents
			.filter(
				itemComponent => itemComponent.componentId === this.componentId
			)
			.map(({ itemId }) => itemId)
			.sort();

		// other version item ids
		if (this.baseComponentId) {
			const otherItemsComponentIds = tables.components
				.filter(
					component =>
						component.baseComponentId === this.baseComponentId ||
						component.componentId === this.componentId
				)
				.map(({ componentId }) => componentId);

			const otherVersionItemIds = new Set<number>();

			for (const itemComponent of tables.itemsComponents) {
				if (
					otherItemsComponentIds.includes(
						itemComponent.componentId
					) &&
					!otherVersionItemIds.has(itemComponent.itemId)
				) {
					otherVersionItemIds.add(itemComponent.itemId);
				}
			}

			this.otherVersionItemIds = Array.from(otherVersionItemIds);
		} else {
			this.otherVersionItemIds = this.itemIds;
		}

		// component group ids
		this.componentGroupIds = tables.componentsComponentGroups
			.filter(({ componentId }) => componentId === this.componentId)
			.map(({ componentGroupId }) => componentGroupId)
			.sort();

		// tax
		this.tax = this.getTaxAmount(this.componentGroupIds);

		// other component ids and component version ids
		this.otherComponentIds = [];
		this.componentVersionIds = [];

		const componentIdToFilterBy = this.baseComponentId || this.componentId;
		for (const { componentId, baseComponentId, componentVersionId } of [
			...tables.components
		].sort(
			(a, b) => (a.componentVersionId || 0) - (b.componentVersionId || 0)
		)) {
			if (
				componentVersionId &&
				baseComponentId === componentIdToFilterBy &&
				componentId !== this.componentId
			) {
				this.otherComponentIds.push(componentId);
				this.componentVersionIds.push(componentVersionId);
			}
		}

		// unit ratios
		this.unitRatios = tables.componentUnitRatios.filter(
			({ componentId }) => componentId === this.componentId
		);

		// associated modifier id
		const associatedModifierId = tables.componentsModifiers.find(
			({ componentId }) => componentId === this.componentId
		);

		if (associatedModifierId === undefined) {
			throw new Error('Could not find component associatedModifierId');
		}

		this.associatedModifierId = associatedModifierId.modifierId;

		this.isValid = true;
	}

	getTaxAmount(componentGroupIds: number[]): number {
		let foundOneState = false;
		let foundOneLocal = false;
		let highestState = 0;
		let highestLocal = 0;

		for (const id of componentGroupIds) {
			const stateTaxHash = Component.stateTaxHash[id];
			const localTaxHash = Component.localTaxHash[id];

			if (stateTaxHash !== undefined) {
				foundOneState = true;

				if (stateTaxHash > highestState) {
					highestState = stateTaxHash;
				}
			}

			if (localTaxHash !== undefined) {
				foundOneLocal = true;

				if (localTaxHash > highestState) {
					highestLocal = localTaxHash;
				}
			}
		}

		if (!foundOneState) {
			highestState = Component.stateTaxHash[0]!;
		}

		if (!foundOneLocal && Component.localTaxHash[0] !== undefined) {
			highestLocal = Component.localTaxHash[0];
		}

		return highestState + highestLocal;
	}
}

export default class Component
	implements LinkedComponent, Serializable<Serialized.Component> {
	readonly linked = true;

	id: number;
	componentId: number;
	name: string;
	tax: number;
	ticketName?: string;
	unitId: number;
	inventoryItemId: number;
	servings: number;
	componentVersionId?: number;
	baseComponentId: number;
	isVariableQuantity: boolean;
	static: boolean;
	associatedModifierId: number;
	suppressNameFromRecipe: boolean;

	itemIds: number[];
	otherVersionItemIds: number[];
	componentGroupIds: number[];
	otherComponentIds: number[];
	componentVersionIds: number[];
	unitRatios: Database.MappedComponentUnitRatiosRow[];

	recipeUnits: string;
	recipeUnitsPlural: string;
	inventoryItem: InventoryItem;
	items: Item[] = [];
	otherVersionItems: Item[] = [];
	componentGroups: ComponentGroup[] = [];
	componentVersion?: ComponentVersion;
	componentVersions: ComponentVersion[] = [];
	otherComponents: Component[] = [];

	static stateTaxHash: NumericDictionary<number | undefined> = {};
	static localTaxHash: NumericDictionary<number | undefined> = {};

	static initialized = false;
	static componentIds: number[] = [];
	static components: NumericDictionary<
		Component | UnlinkedComponent | undefined
	> = {};

	static isComponent(
		component: UnlinkedComponent | Component
	): component is Component {
		return component.linked;
	}

	static getComponent(componentId: number): Component | undefined {
		let component = this.components[componentId];

		if (component) {
			if (this.isComponent(component)) {
				return component;
			}

			return new Component(component);
		}
	}

	static async initialize(tables: Database.MappedTables) {
		this.initialized = false;
		this.componentIds = [];
		this.components = {};

		this.stateTaxHash = {};
		this.localTaxHash = {};

		for (const { tax, componentGroupId } of tables.taxState.filter(
			taxState => taxState.stateId === Config.stateId
		)) {
			this.stateTaxHash[componentGroupId] = tax;
		}

		if (Config.locale !== 0) {
			for (const { tax, componentGroupId } of tables.taxLocal.filter(
				taxLocal => taxLocal.localeId === Config.locale
			)) {
				this.localTaxHash[componentGroupId] = tax;
			}
		}

		for (const row of tables.components) {
			const component = new UnlinkedComponent(row, tables);

			if (component.isValid) {
				this.componentIds.push(component.componentId);
				this.components[component.componentId] = component;
			}
		}

		if (this.componentIds.length > 0) this.initialized = true;

		return this.initialized;
	}

	static async linkAll() {
		for (const component of Object.values(Component.components)) {
			if (component) {
				// try {
				new Component(component);
				// } catch (err) {
				// 	// console.error(err);
				// }
			}
		}

		return true;
	}

	constructor(component: UnlinkedComponent | Component) {
		Component.components[component.componentId] = this;

		this.id = component.componentId;
		this.componentId = component.componentId;
		this.name = component.name;
		this.tax = component.tax;
		this.ticketName = component.ticketName;
		this.unitId = component.unitId;
		this.inventoryItemId = component.inventoryItemId;
		this.servings = component.servings;
		this.componentVersionId = component.componentVersionId;
		this.baseComponentId = component.baseComponentId;
		this.isVariableQuantity = component.isVariableQuantity;
		this.static = component.static;
		this.associatedModifierId = component.associatedModifierId;
		this.suppressNameFromRecipe = component.suppressNameFromRecipe;

		this.itemIds = component.itemIds;
		this.otherVersionItemIds = component.otherVersionItemIds;
		this.componentGroupIds = component.componentGroupIds;
		this.otherComponentIds = component.otherComponentIds;
		this.componentVersionIds = component.componentVersionIds;
		this.unitRatios = component.unitRatios;

		if (Component.isComponent(component)) {
			this.recipeUnits = component.recipeUnits;
			this.recipeUnitsPlural = component.recipeUnitsPlural;

			this.inventoryItem = component.inventoryItem;
			this.items = component.items;
			this.otherVersionItems = component.otherVersionItems;
			this.componentGroups = component.componentGroups;
			this.componentVersion = component.componentVersion;
			this.otherComponents = component.otherComponents;
		} else {
			// link inventory item
			const inventoryItem = InventoryItem.getInventoryItem(
				this.inventoryItemId
			);

			if (!inventoryItem) {
				throw new Error(
					'Failed to link component ' +
						this.id +
						' to inventory item ' +
						this.inventoryItemId
				);
			}

			this.inventoryItem = inventoryItem;

			// link unit
			const unit = Unit.getUnit(this.unitId);

			if (!unit) {
				throw new Error('Failed to link component to unit');
			}

			const {
				unitName: recipeUnits,
				unitNamePlural: recipeUnitsPlural
			} = unit;

			this.recipeUnits = recipeUnits;
			this.recipeUnitsPlural = recipeUnitsPlural;

			// link items
			for (const id of this.itemIds) {
				const item = Item.getItemById(id);

				if (!item) {
					throw new Error('Failed to link component to item');
				}

				this.items.push(item);
			}

			// link other version items
			for (const id of this.otherVersionItemIds) {
				const item = Item.getItemById(id);

				if (!item) {
					throw new Error(
						'Failed to link component to other version item'
					);
				}

				this.otherVersionItems.push(item);
			}

			// link component groups
			for (const id of this.componentGroupIds) {
				const componentGroup = ComponentGroup.getComponentGroup(id);

				if (!componentGroup) {
					throw new Error(
						'Failed to link component to component group'
					);
				}

				this.componentGroups.push(componentGroup);
			}

			// link component version
			this.componentVersion = this.componentVersionId
				? ComponentVersion.getComponentVersion(this.componentVersionId)
				: undefined;

			// link component versions
			for (const id of this.componentVersionIds) {
				const componentVersion = ComponentVersion.getComponentVersion(
					id
				);

				if (!componentVersion) {
					throw new Error(
						'Failed to link component to component version'
					);
				}

				this.componentVersions.push(componentVersion);
			}

			// link other components
			for (const id of this.otherComponentIds) {
				if (Component.components[id] === undefined) {
					const otherComponent = Component.components[id];

					if (!otherComponent) {
						throw new Error(
							'Failed to link component to other component'
						);
					}

					new Component(otherComponent);
				}

				this.otherComponents.push(Component.getComponent(id)!);
			}

			// link unit ratios
			for (const unitRatio of this.unitRatios) {
				if (!Unit.getUnit(unitRatio.unitId)) {
					throw new Error('Failed to link component to unit ratio');
				}
			}

			// if (this.associatedModifierId !== 0) {
			// 	const modifier = Modifier.getModifier(
			// 		this.associatedModifierId
			// 	);

			// 	if (!modifier) {
			// 		throw new Error('Failed to link component to modifier');
			// 	}
			// }
		}
	}

	hasUnitRatioUnit(unitId: number): boolean {
		if (this.unitRatios[unitId]) return true;

		return false;
	}

	getUnitRatio(unitId: number): number {
		if (this.unitRatios[unitId])
			return this.unitRatios[unitId].conversionRatio;

		return 0;
	}

	static hasVersion(
		thisId: number,
		versionId: number,
		otherComponent: Component
	) {
		return (
			otherComponent.componentVersion?.id === versionId &&
			otherComponent.id !== thisId
		);
	}

	getOtherVersion(versionId: number): Component | undefined {
		return this.otherComponents.find((otherComponent: Component) =>
			Component.hasVersion(this.id, versionId, otherComponent)
		);
	}

	hasCostAppliesComponentGroup(): boolean {
		return this.componentGroups.some(
			componentGroup => componentGroup.id === Config.costApplies
		);
	}

	serialize(): Serialized.Component {
		return {
			id: this.id,
			name: this.name,
			suppressNameFromRecipe: this.suppressNameFromRecipe,
			ticketName: this.ticketName,
			unitId: this.unitId,
			servings: this.servings,
			isVariableQuantity: this.isVariableQuantity,
			static: this.static,
			recipeUnits: this.recipeUnits,
			recipeUnitsPlural: this.recipeUnitsPlural,
			unitRatios: this.unitRatios,
			baseComponentId: this.baseComponentId,
			itemIds: this.itemIds,
			otherVersionItemIds: this.otherVersionItemIds,
			otherComponentIds: this.otherComponentIds,
			componentVersion: this.componentVersion?.serialize(),
			componentVersions: serialize(this.componentVersions),
			inventoryItem: this.inventoryItem,
			componentGroups: serialize(this.componentGroups)
		};
	}
}
