import {
	LinkedModifierScale,
	NumericDictionary,
	Database,
	Serializable,
	Serialized,
	UnlinkedModifierScale as UnlinkedModifierScaleInterface
} from '../types';

import { ItemQualifier, Modifier, TransItem } from '.';

export class UnlinkedModifierScale implements UnlinkedModifierScaleInterface {
	readonly linked = false;
	isValid = false;

	modifierScaleId: number;
	name: string;
	priority: number;
	isPriceIncremental: boolean;
	freeModId: number;
	isPriceDecremental: boolean;

	itemQualifierIds: number[];
	itemQualifiersIsOr: boolean[];
	modifierIds: number[];

	constructor(
		modifierScaleFields: Database.MappedModifierScalesRow,
		tables: Database.MappedTables
	) {
		this.modifierScaleId = modifierScaleFields.modifierScaleId;
		this.name = modifierScaleFields.name;
		this.priority = modifierScaleFields.priority;
		this.isPriceIncremental = modifierScaleFields.isPriceIncremental;
		this.freeModId = modifierScaleFields.freeModId;
		this.isPriceDecremental = modifierScaleFields.isPriceDecremental;

		const thisFilter = ({ modifierScaleId }: { modifierScaleId: number }) =>
			modifierScaleId === this.modifierScaleId;

		this.modifierIds = tables.modifierScalesModifiers
			.filter(thisFilter)
			.sort((a, b) => a.priority - b.priority)
			.map(({ modifierId }) => modifierId);

		const [
			itemQualifierIds,
			itemQualifiersIsOr
		] = tables.modifierScalesItemQualifiers
			.filter(thisFilter)
			.reduce<[number[], boolean[]]>(
				(
					[itemQualifierIds, itemQualifiersIsOr],
					{ itemQualifierId, isOr }
				) => [
					[...itemQualifierIds, itemQualifierId],
					[...itemQualifiersIsOr, isOr]
				],
				[[], []]
			);

		this.itemQualifierIds = itemQualifierIds;
		this.itemQualifiersIsOr = itemQualifiersIsOr;

		this.isValid = true;
	}
}
export default class ModifierScale
	implements LinkedModifierScale, Serializable<Serialized.ModifierScale> {
	readonly linked = true;

	id: number;
	modifierScaleId: number;
	name: string;
	priority: number;
	isPriceIncremental: boolean;
	freeModId: number;
	isPriceDecremental: boolean;

	itemQualifierIds: number[];
	itemQualifiersIsOr: boolean[];
	modifierIds: number[];

	freeModifier?: Modifier;
	itemQualifiers: ItemQualifier[] = [];
	modifiers: Modifier[] = [];

	static initialized = false;
	static modifierScaleIds: number[] = [];
	static modifierScales: NumericDictionary<
		ModifierScale | UnlinkedModifierScale | undefined
	> = {};

	static isModifierScale(
		modifierScale: UnlinkedModifierScale | ModifierScale
	): modifierScale is ModifierScale {
		return modifierScale.linked;
	}

	static getModifierScale(
		modifierScaleId: number
	): ModifierScale | undefined {
		const modifierScale = this.modifierScales[modifierScaleId];

		if (modifierScale) {
			if (this.isModifierScale(modifierScale)) {
				return modifierScale;
			}

			return new ModifierScale(modifierScale);
		}
	}

	static async initialize(tables: Database.MappedTables) {
		this.initialized = false;
		this.modifierScaleIds = [];
		this.modifierScales = {};

		for (const row of tables.modifierScales) {
			const modifierScale = new UnlinkedModifierScale(row, tables);

			if (modifierScale.isValid) {
				this.modifierScaleIds.push(modifierScale.modifierScaleId);
				this.modifierScales[
					modifierScale.modifierScaleId
				] = modifierScale;
			}
		}

		if (this.modifierScaleIds.length > 0) this.initialized = true;

		return this.initialized;
	}

	static async linkAll() {
		for (const modifierScale of Object.values(
			ModifierScale.modifierScales
		)) {
			if (modifierScale) {
				new ModifierScale(modifierScale);
			}
		}

		return true;
	}

	constructor(modifierScale: ModifierScale | UnlinkedModifierScale) {
		ModifierScale.modifierScales[modifierScale.modifierScaleId] = this;

		this.id = modifierScale.modifierScaleId;

		this.modifierScaleId = modifierScale.modifierScaleId;
		this.name = modifierScale.name;
		this.priority = modifierScale.priority;
		this.isPriceIncremental = modifierScale.isPriceIncremental;
		this.freeModId = modifierScale.freeModId;
		this.isPriceDecremental = modifierScale.isPriceDecremental;

		this.itemQualifierIds = modifierScale.itemQualifierIds;
		this.itemQualifiersIsOr = modifierScale.itemQualifiersIsOr;
		this.modifierIds = modifierScale.modifierIds;

		if (modifierScale.linked) {
			this.freeModifier = modifierScale.freeModifier;
			this.itemQualifiers = modifierScale.itemQualifiers;
			this.modifiers = modifierScale.modifiers;
		} else {
			if (this.freeModId) {
				const freeModifier = Modifier.getModifier(this.freeModId);

				if (!freeModifier) {
					throw new Error(
						'Failed to link modifier scale to free modifier'
					);
				}

				this.freeModifier = freeModifier;
			}

			// link item qualifiers
			for (const id of this.itemQualifierIds) {
				const itemQualifier = ItemQualifier.getItemQualifier(id);

				if (!itemQualifier) {
					throw new Error(
						'Failed to link modifier to item qualifier'
					);
				}

				this.itemQualifiers.push(itemQualifier);
			}

			// link modifiers
			for (const id of this.modifierIds) {
				const modifier = Modifier.getModifier(id);

				if (!modifier) {
					throw new Error(
						'Failed to link modifier group to modifier'
					);
				}

				this.modifiers.push(modifier);
			}
		}
	}

	static getModifierScales(transItem: TransItem): ModifierScale[] {
		const modifierScales: ModifierScale[] = [];

		for (const scale of Object.values(this.modifierScales)) {
			if (scale && scale.linked && scale.qualifies(transItem)) {
				modifierScales.push(scale);
			}
		}

		return modifierScales;
	}

	qualifies(transItem: TransItem): boolean {
		if (this.itemQualifiers.length < 1) {
			return true;
		}

		let orQualifies: boolean = false;
		let orExists: boolean = false;

		for (const index in this.itemQualifiers) {
			const qualifies: boolean = this.itemQualifiers[index].qualifies(
				transItem
			);

			if (this.itemQualifiersIsOr[index]) {
				orExists = true;

				if (qualifies) orQualifies = true;
			} else if (!qualifies) return false;
		}

		if (!orQualifies && orExists) return false;

		return true;
	}

	serialize(): Serialized.ModifierScale {
		return {
			id: this.id,
			name: this.name,
			priority: this.priority,
			modifiers: this.modifiers.map(modifier => modifier.serialize())
		};
	}
}

export class ModifierScaleModel
	implements Serializable<Serialized.ModifierScaleModel> {
	transItem: TransItem;
	modifierScales: ModifierScale[] = [];
	modifierScaleIndices: number[] = [];

	static modifierScaleModel: ModifierScaleModel;

	constructor(transItem: TransItem) {
		ModifierScaleModel.modifierScaleModel = this;

		this.transItem = transItem;

		this.setTransItem(transItem);
	}

	setTransItem(transItem: TransItem): void {
		transItem.settingTransItem = true;

		this.modifierScales = ModifierScale.getModifierScales(transItem);

		for (const modifierScale of this.modifierScales) {
			let defaultIndex = 0;

			for (const index in modifierScale.modifiers) {
				if (transItem.hasModifier(modifierScale.modifiers[index])) {
					defaultIndex = Number(index);

					break;
				}
			}

			if (defaultIndex === 0) {
				for (const index in modifierScale.modifiers) {
					if (!modifierScale.modifiers[index].hasEffect(transItem)) {
						defaultIndex = Number(index);

						break;
					}
				}
			}

			this.modifierScaleIndices.push(defaultIndex);
		}

		transItem.settingTransItem = false;
	}

	increaseLevel(index: number) {
		// console.log('ModifierScaleModel: increaseLevel');
		// console.log('ModifierScaleModel: increaseLevel: index', index);

		this.transItem.modify(
			this.modifierScales[index].modifiers[
				this.modifierScaleIndices[index] + 1
			]
		);

		this.modifierScaleIndices[index] = this.modifierScaleIndices[index] + 1;
	}

	decreaseLevel(index: number) {
		// console.log('ModifierScaleModel: decreaseLevel');
		// console.log('ModifierScaleModel: decreaseLevel: index', index);

		this.transItem.modify(
			this.modifierScales[index].modifiers[
				this.modifierScaleIndices[index] - 1
			]
		);

		this.modifierScaleIndices[index] = this.modifierScaleIndices[index] - 1;
	}

	serialize() {
		return {
			modifierScales: this.modifierScales.map(modifierScale =>
				modifierScale.serialize()
			),
			modifierScaleIndices: this.modifierScaleIndices
		};
	}
}
