import { Dictionary, Serializable } from '../types';

import { defined } from '.';

export const serializeDictionary = <T, K extends Serializable<T>>(
	dictionary: Dictionary<K>
): T[] =>
	Object.values(dictionary)
		.filter(defined)
		.map(value => value.serialize());
