import { Serialized, Serializable } from '../../types';

import TransItem from '.';

export class TransItemModel implements Serializable<Serialized.TransItemModel> {
	private transItems: TransItem[];
	// private linkedItems: Dictionary<LinkedTransItem> = {};

	static transItemModel: TransItemModel = new TransItemModel();

	constructor(transItems?: TransItem[] | TransItemModel) {
		TransItemModel.transItemModel = this;

		if (transItems === undefined) {
			this.transItems = [];

			return;
		}

		if (Array.isArray(transItems)) {
			this.transItems = transItems;

			// TODO: slots

			return;
		}

		if (transItems instanceof TransItemModel) {
			this.transItems = transItems.transItems;

			// TODO: slots

			return;
		}

		throw new Error('TransItemModel: constructor: invalid input.');
	}

	get rowCount(): number {
		return this.transItems.length;
	}

	getTransItem(index: number): TransItem {
		if (index in this.transItems) {
			return this.transItems[index];
		}

		throw new Error('TransItemModel: getTransItem: invalid input.');
	}

	getItem(index: number): TransItem {
		return this.transItems[index];
	}

	getNumItems(): number {
		return this.transItems.length;
	}

	addItem(transItem: TransItem): void {
		// this.beginResetModel(); // TODO: slots

		this.transItems.push(transItem);

		// TODO: slots
		// this.endResetModel(); // TODO: slots
	}

	removeItem(item: number | TransItem): void {
		// this.beginResetModel(); // TODO: slots

		if (item instanceof TransItem) {
			this.transItems = this.transItems.filter(
				transItem => transItem.id !== item.id
			);
		} else {
			this.transItems = this.transItems.filter(
				(_, index) => index !== item
			);

			// TODO: slots
		}

		// this.endResetModel(); // TODO: slots
	}

	setItems(transItems: TransItem[]): void {
		// this.beginResetModel(); // TODO: slots
		// TODO: slots

		this.transItems = transItems;

		// TODO: slots
		// this.endResetModel(); // TODO: slots
	}

	serialize(): Serialized.TransItemModel {
		return {
			transItems: this.transItems.map(transItem => transItem.serialize())
		};
	}
}
