import { Slot } from '../types';

export class Signal<TSlotArgs extends any[] = []> {
	private slot: Slot<TSlotArgs>;

	constructor(slot: Slot<TSlotArgs>) {
		this.slot = slot;
	}

	emit<TArgs extends TSlotArgs>(...args: TArgs): void {
		const handlers = Object.values(this.slot);

		for (const handler of handlers) {
			handler(...args);
		}
	}
}
