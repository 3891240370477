import { v4 } from 'uuid';

import {
	Serialized,
	Serializable,
	FoamStatus,
	ItemErrorCode,
	Slot
} from '../../types';

import { Signal } from '../../utils';

import {
	// Money,
	Modifier,
	ComponentGroup,
	ModifierGroup,
	Item,
	ModifierScale,
	TransModifier,
	TransComponent,
	Component,
	ProductGroup,
	Container,
	ComponentGroupRatioType,
	ComponentGroupRatio,
	Config,
	Size,
	ProductVersion
} from '..';

import Money from '../Money';

interface VisibleModifier {
	id: string;
	mod: TransModifier;
	like: TransModifier[];
	quantity: number;
}

/**
 * Used to track the counts of the modifiers that a TransItem has
 */
type ModifierCount = [
	modifierId: number,
	modifierScaleIndex: number,
	extraCount: number,
	freeCount: number,
	id: string
];

/**
 * An item that is part of a Transaction.
 * @class TransItem
 */
export default class TransItem implements Serializable<Serialized.TransItem> {
	/*
	 * custom properties initalized in the constructor
	 */
	/**
	 * uuid to uniquely identify this transitem
	 */
	id: string;

	/*
	 * these are dynamically initialized in the constructor in percomatic
	 */
	/**
	 * The base item this transItem is an instance of
	 */
	item: Item;
	timeAdded: Date;
	isPrintable: boolean;
	// transStartTime: Date; // TODO: decide if this is needed
	/**
	 * Foam Setting on TransItem
	 */
	foamStatus?: FoamStatus;
	/**
	 * If the TransItem is digital or not.
	 */
	isDigital: boolean;

	/*
	 * these have their types set statically in the constructor in percomatic,
	 * but are not given concrete values
	 */
	discountId?: string;
	timeVoided?: Date;
	selectedModifier?: Modifier;

	/*
	 * these are statically initialized in the constructor by percomatic
	 */
	recipeMode: boolean = false;
	hasChangedSinceLastSent: boolean = false;
	taxAmount: Money = new Money();
	discountAmount: Money = new Money();
	isReturn: boolean = false;
	isVoided: boolean = false;
	isSent: boolean = false;
	wasPrinted: boolean = false;
	disableSlot: boolean = false;
	/**
	 * A Tally to know how many recursive calls have been made in
	 * TransItem::modify. Incremented any time the function is called,
	 * decremented when exited.
	 */
	modifyTally: number = 0;
	/**
	 * A Tally to know how many recursive calls have been made in
	 * TransItem::unModify. Incremented any time the function is called,
	 * decremented when exited.
	 */
	unModifyTally: number = 0;
	/**
	 * A variable to be marked true when a Modifier Scale is setting a TransItem.
	 */
	settingTransItem: boolean = false;
	/**
	 * The comments attached to this item by the user.
	 */
	typewriterNote: string = '';
	/**
	 * Whether or not the liquid base is overflowing (by adding too much to the
	 * TransItem).
	 */
	liquidBaseOverflow: boolean = false;
	/**
	 * If we should prompt the user letting them know the max Liquid Base has
	 * been reached.
	 */
	liquidBaseMessage: boolean = false;

	/*
	 * these are statically assigned in the header file in percomatic
	 */
	isDiscountable: boolean = false;
	isTransferring: boolean = false;

	/*
	 * these are declared as instance properties in percomatic but not assigned
	 * in the constructor. Instead, they are assigned in one of these methods:
	 * 	- initializeComponentsToRecipeDefaults()
	 * 	- applyNameModifiers()
	 * 	- calculatePrice()
	 * 	- calculateTax()
	 * 	- determineTicketName()
	 * This means that these properties can have initializers/definite assignment
	 * assertions.
	 */

	// initializeComponentsToRecipeDefaults()
	components: TransComponent[] = [];
	componentGroups: ComponentGroup[] = [];
	requiredComponentGroups: ComponentGroup[] = [];

	// applyNameModifiers()
	modifierCount: ModifierCount[] = [];
	modifiers: TransModifier[] = [];

	// calculatePrice()
	/**
	 * The current price of this transitem.
	 */
	price: Money = new Money();

	// calculateTax()
	taxRate: number = 0;

	// determineTicketName()
	ticketName: string = '';

	/*
	 * these are declared in the header file in percomatic but not assigned in
	 * one of the above methods
	 */
	visibleModifiers: VisibleModifier[] = [];
	selectedTransModifiers: TransModifier[] = [];
	// this is declared in the header file but never used anywhere in percomatic
	// recipeComponents: TransComponent[] = [];

	/*
	 * SLOTS
	 */
	onSomethingChanged: Slot = {};

	/*
	 * SIGNALS
	 */
	somethingChanged = new Signal(this.onSomethingChanged);

	// TODO: magic numbers
	/**
	 * Amount to deduct from liquid if Whip Component is present in TransItem.
	 * @default 0.5
	 */
	static whipAdjustment = 0.5;
	/**
	 * Amount to deduct from liquid if With Room Modifier is present in TransItem.
	 * @default 0.5
	 */
	static withRoomAdjustment = 0.5;
	/**
	 * Amount to deduct from Milk with Latte Foam
	 * @default 0.5
	 */
	static latteFoamHeight = 0.5;
	/**
	 * Foam ratio to Steamed Milk for latticinos
	 * @default 1/3
	 */
	static latticcinoFoamRatio = 1.0 / 3.0;
	/**
	 * Foam ratio to Steamed Milk for cappuccinos
	 * @default 0.5
	 */
	static cappuccinoFoamRatio = 0.5;
	/**
	 * Foam ratio to Steamed Milk for dry cappuccinos
	 * @default 2/3
	 */
	static dryCappuccinoFoamRatio = 2.0 / 3.0;
	/**
	 * the amount of room to leave in a beverage if there is no foam and no whip
	 * @default 0.5
	 */
	static noFoamNoWhipRoom = 0.5;
	/**
	 * Standard fl oz deduction for mugs
	 * @default 1.0
	 */
	static mugDeduction = 1.0;

	static transItem: TransItem;

	/**
	 * Constructs a TransItem based on an Item.
	 * @param  {Item} item
	 * @param  {boolean} set
	 * @param  {string | undefined} id?
	 */
	constructor(item: Item, set: boolean = true, id?: string) {
		if (set) {
			TransItem.transItem = this;
		}

		// console.log('TransItem');

		if (id) {
			this.id = id;
		} else {
			this.id = v4();
		}

		this.item = item;
		this.timeAdded = new Date();
		this.isPrintable = item.product.printable;
		this.foamStatus = item.foamStatus;
		// this.isDigital = isDigitalOrder;
		/**
		 * TODO: is this relevant to online ordering? it's provided to the
		 * constructor in percomatic
		 */
		this.isDigital = false;

		this.initializeComponentsToRecipeDefaults();
		this.applyNameModifiers();
		this.calculatePrice();
		this.calculateTax();
		this.determineTicketName();
	}

	modify(modifier: Modifier): ItemErrorCode {
		// console.log('TransItem: modify');

		++this.modifyTally;

		this.hasChangedSinceLastSent = true;
		this.selectedModifier = modifier;

		if (modifier.inverseModifier) {
			if (this.hasModifier(modifier.inverseModifier)) {
				this.unModify(modifier.inverseModifier);
			}
		}

		// If this TransItem already has this Modifier, do not add it again
		if (this.hasModifier(modifier) && !modifier.isVariableQuantity) {
			--this.modifyTally;

			return 'ITEM_ALREADY_EXISTS';
		}

		// If this modifier is part of a modifier scale and it doesn't qualify, we don't add it
		if (modifier.modifierScale) {
			// Check if this modifier is also a part of a modifier
			// group also, like in the case of Sprinkles, in which case we still want
			// to add it since it already qualifies
			if (modifier.modifierGroups.length === 0) {
				if (!modifier.modifierScale.qualifies(this)) {
					--this.modifyTally;

					return 'ITEM_MODIFIER_NOT_QUALIFIED';
				}
			}
		}

		if (this.modifyTally === 1) {
			// TODO: beginResetModel() ???
		}

		let transModifier: TransModifier;
		let price = new Money();

		const modifierScale = modifier.modifierScale;
		let currentScaleModifier: Modifier | undefined;

		// If this Modifier is a part of a ModifierScale
		if (modifierScale) {
			// Loop through this ModifierScale and remove any Modifiers that
			// exist in the TransItem already. Only one Modifier of a ModifierScale
			// can exist in a TransItem at a time.
			for (currentScaleModifier of modifierScale.modifiers) {
				const transModifier = this.getModifier(currentScaleModifier);

				if (transModifier) {
					this.removeModifier(currentScaleModifier);
					this.resetItemToRecipeAndReapplyModifiers(modifier);

					break;
				}
			}

			if (
				modifierScale.isPriceIncremental ||
				modifierScale.isPriceDecremental
			) {
				const defaultTransItem = new TransItem(
					this.item,
					false,
					this.id
				);

				// Loop through Modifiers in this ModifierScale until we reach
				// the default Modifier for this TransItem or until we reach the
				// Modifier to be added
				let defaultModifierIndex = modifierScale.modifiers.findIndex(
					modifierScaleModifier =>
						!modifierScaleModifier.affectsComponents(
							defaultTransItem
						)
				);

				if (
					defaultModifierIndex < 0 ||
					defaultModifierIndex >= modifierScale.modifiers.length
				) {
					defaultModifierIndex = 0;
				}

				// If this modifier is above the default, keep adding prices to the modifier
				// until we reach the modifier to be added
				if (
					modifier.modifierScaleIndex !== undefined &&
					modifier.modifierScaleIndex > defaultModifierIndex &&
					modifierScale.isPriceIncremental
				) {
					// Loop through each remaining modifier to the current one,
					// adding each one's price to the cumulative total
					for (
						let modifierIndex = defaultModifierIndex + 1;
						modifierIndex <= modifier.modifierScaleIndex;
						modifierIndex++
					) {
						if (!modifier) {
							--this.modifyTally;

							return 'ITEM_MODIFIER_NO_EFFECT';
						}

						const tempscale = modifier.modifierScale;

						if (!tempscale) {
							--this.modifyTally;

							return 'ITEM_MODIFIER_NO_EFFECT';
						}

						const tempmodifier = tempscale.modifiers[modifierIndex];

						if (!tempmodifier) {
							--this.modifyTally;

							return 'ITEM_MODIFIER_NO_EFFECT';
						}

						price.add(tempmodifier.price);
					}
				} else if (
					modifier.modifierScaleIndex !== undefined &&
					modifier.modifierScaleIndex < defaultModifierIndex &&
					modifierScale.isPriceDecremental &&
					defaultModifierIndex > 0
				) {
					for (
						let modifierIndex = defaultModifierIndex - 1;
						modifierIndex >= modifier.modifierScaleIndex;
						modifierIndex--
					) {
						if (!modifier) {
							--this.modifyTally;

							return 'ITEM_MODIFIER_NO_EFFECT';
						}

						const tempscale = modifier.modifierScale;

						if (!tempscale) {
							--this.modifyTally;

							return 'ITEM_MODIFIER_NO_EFFECT';
						}

						const tempmodifier = tempscale.modifiers[modifierIndex];

						if (!tempmodifier) {
							--this.modifyTally;

							return 'ITEM_MODIFIER_NO_EFFECT';
						}

						price.add(tempmodifier.price);
					}
				}
			}
		} else {
			// Else this is a member of a modifier group
			let modPrice: number = modifier.price;

			if (modPrice > 0) {
				if (modifier.doesCostApply(this)) {
					price.assign(modPrice);
				} else {
					price.assign(0);
				}
			} else {
				price.assign(0);
			}
		}

		// For each modifier group this new modifier is a part of, we have to
		// see if we are at the maximum number of modifiers. If so, we remove
		// the latest modifier before applying this one
		const modifierGroups = modifier.modifierGroups;

		// Loop through each modifier group
		for (const modifierGroup of modifierGroups) {
			// If this modifier group has a maximum, we have to make sure
			// that we are not exceeding the maximum
			if (modifierGroup.max > 0) {
				let modifierCount = 0;

				// Loop through all modifiers and count how many are in this
				// group
				for (const transModifier of this.modifiers) {
					if (transModifier.modifier.inModifierGroup(modifierGroup)) {
						++modifierCount;
					}
				}

				// If the number of modifiers is at or above the maximum, then
				// we remove the oldest modifier in this group
				if (modifierCount >= modifierGroup.max) {
					// Loop through the modifiers in this item and remove the

					// first one that is in this group
					for (const transModifier of this.modifiers) {
						if (
							transModifier.modifier.inModifierGroup(
								modifierGroup
							)
						) {
							this.removeModifier(modifier);

							break;
						}
					}
				}
			}
		}

		transModifier = new TransModifier(modifier, price);

		let modifiers = this.modifiers;
		let sortedModifiers = this.sortTransModifiers(modifiers, transModifier);

		// Add trans modifiers to selectedTransModifiers
		if (this.selectedTransModifiers.length === 0) {
			this.selectedTransModifiers.push(transModifier);
		} else {
			let duplicate = false;

			for (const selectedTransModifier of this.selectedTransModifiers) {
				if (selectedTransModifier.id === transModifier.id) {
					duplicate = true;

					break;
				}
			}

			if (!duplicate) {
				// We want to remove the first Modifier if it is not variable quantity
				// and the same as the TransModifier.
				// Certain cases could cause problems, such as a Iced Coffee moving to
				// a Red Eye.  Two Single Trans Mods would get added to this list
				for (const selectedTransModifier of this
					.selectedTransModifiers) {
					if (
						selectedTransModifier.modifier.id ===
						transModifier.modifier.id
					) {
						this.selectedTransModifiers.filter(
							({ id }) => id !== transModifier.id
						);
					}
				}

				this.selectedTransModifiers.push(transModifier);
			}
		}

		this.modifiers = [];
		this.visibleModifiers = [];

		const originalItem = this.item;

		let appliedModifier = modifier.hasEffect(this) && modifier.apply(this);

		// Reset this item to its default state, in case applying the modifier changed Ids
		this.resetItemToDefaults(modifier);

		modifiers = this.modifiers;

		for (const transModifier of modifiers) {
			let matchingMod = false;
			let duplicateModScales = false;

			for (const sortedTransModifier of sortedModifiers) {
				if (
					transModifier.modifier.id ===
					sortedTransModifier.modifier.id
				) {
					matchingMod = true;

					// Under rare circumstances, the modifiers list could have a trans mod
					// with a different price then sortedModifiers (example would be adding crisp crunch mod
					// then adding salted caramel, crisp crunch stays at $.50 when it should be free at that point)
					if (transModifier.price !== sortedTransModifier.price) {
						let modPrice = transModifier.price;
						let sortedModPrice = sortedTransModifier.price;

						const sortedTransModifierIndex = sortedModifiers.findIndex(
							({ id }) => id === sortedTransModifier.id
						);

						if (sortedTransModifierIndex >= 0) {
							if (modPrice > sortedModPrice) {
								sortedModifiers[
									sortedTransModifierIndex
								].price.add(
									Money.subtract(sortedModPrice, modPrice)
								);
							} else {
								sortedModifiers[
									sortedTransModifierIndex
								].price.add(
									Money.subtract(modPrice, sortedModPrice)
								);
							}
						} else {
							throw new Error(); // TODO
						}
					}

					break;
				}

				if (
					transModifier.modifier.modifierScale &&
					sortedTransModifier.modifier.modifierScale &&
					transModifier.modifier.modifierScale.id ===
						sortedTransModifier.modifier.modifierScale.id
				) {
					duplicateModScales = true;
				}
			}

			if (!matchingMod && !duplicateModScales) {
				sortedModifiers = this.sortTransModifiers(
					sortedModifiers,
					transModifier
				);
			}
		}

		// Reclear any modifiers that might have gotten added in appliedModifier
		this.modifiers = [];
		this.visibleModifiers = [];

		// Attempt to reapply all of this item's modifiers
		let reappliedModifier: Modifier;
		let itemChange = false;

		// Find out how many ComponentGroupRatio Modifiers are in the list
		let componentGroupRatioCount = 0;
		let hasComponentGroupRatios = false;
		let isComponentGroupRatioMod = false;

		for (const sortedMod of sortedModifiers) {
			if (sortedMod.modifier.hasComponentGroupRatio) {
				hasComponentGroupRatios = true;
				++componentGroupRatioCount;
			}
		}

		// reapply modifiers
		for (const sortedModifier of sortedModifiers) {
			isComponentGroupRatioMod = false;

			if (sortedModifier.modifier.hasComponentGroupRatio) {
				isComponentGroupRatioMod = true;
			}

			// If the modifier does not qualify, it should not be in the trans item
			if (!sortedModifier.modifier.qualifies(this)) {
				if (isComponentGroupRatioMod) {
					--componentGroupRatioCount;
				}

				// We want to apply Component Group Ratios on the final loop
				// where a ComponentGroupRatio ModifierFunction is applied
				if (
					componentGroupRatioCount === 0 &&
					hasComponentGroupRatios &&
					isComponentGroupRatioMod
				) {
					this.addComponentGroupRatio();
				}

				continue;
			}

			reappliedModifier = sortedModifier.modifier;

			// Check if they are the same modifiers in memory
			if (sortedModifier.id === transModifier.id) {
				// Reapply this modifier to make sure it overrides any effects of other modifiers

				// If the new item is the same as the old item, we attempt to reapply the modifier
				if (originalItem.id === this.item.id) {
					appliedModifier =
						modifier.hasEffect(this) && modifier.apply(this);
				} else if (!modifier.isVariableQuantity) {
					this.disableSlot = true;
					this.modify(modifier);
					this.disableSlot = false;
					itemChange = true;
				} else {
					appliedModifier = false;
				}

				if (appliedModifier && !itemChange) {
					this.modifiers.push(transModifier);

					let newMod = true;

					if (!transModifier.modifier.modifierScale) {
						for (const modCount of this.modifierCount) {
							if (modCount[0] === transModifier.modifier.id) {
								++modCount[1];
								++modCount[2];

								newMod = false;
							} else {
								const modCountMod = Modifier.getModifier(
									modCount[0]
								);

								if (modCountMod === undefined) {
									throw new Error(
										'Could not find modifier given in modCount.'
									);
								}

								const modCountModifierScale =
									modCountMod.modifierScale;

								if (
									(modCountMod.freeModifier &&
										modCountMod.freeModifier.id ===
											transModifier.modifier.id) ||
									(modCountModifierScale &&
										modCountModifierScale.freeModifier &&
										modCountModifierScale.freeModifier
											.id === transModifier.modifier.id)
								) {
									++modCount[1];
									++modCount[3];

									newMod = false;
								}
							}
						}

						if (newMod) {
							this.modifierCount.push([
								transModifier.modifier.id,
								1, // why is the scale index being set to 1 here???
								1,
								0,
								v4()
							]);
						}
					} else {
						let extraCount = -1;
						let freeCount = 0;

						for (const modCount of this.modifierCount) {
							const mod = Modifier.getModifier(modCount[0]);

							if (!mod) {
								throw new Error(
									'Could not find modifier given in modCount.'
								);
							}

							const modScale = mod.modifierScale;

							if (
								modScale &&
								modScale.id ===
									transModifier.modifier.modifierScale?.id
							) {
								extraCount = modCount[2];
								freeCount = modCount[3];

								this.modifierCount = this.modifierCount.filter(
									([, , , , id]) => id === modCount[4]
								);
							}
						}

						this.modifierCount.push([
							transModifier.modifier.id,
							transModifier.modifier.modifierScaleIndex || 0,
							extraCount + 1,
							freeCount,
							v4()
						]);
					}

					if (modifier.itemName === '') {
						let exists = -1;

						const lastTransMod = this.modifiers[
							this.modifiers.length - 1
						];
						for (const visibleModifier of this.visibleModifiers) {
							let visibleModPrice: number;
							let modPrice: number;

							// if(this.isDigital) { // TODO
							if (false) {
								// TODO
							} else {
								visibleModPrice =
									visibleModifier.mod.modifier.price;
								modPrice = lastTransMod.modifier.price;
							}

							if (
								visibleModifier.mod.modifier.ticketName ===
									lastTransMod.modifier.ticketName &&
								Math.abs(visibleModPrice) - Math.abs(modPrice) <
									0.01
							) {
								exists = this.visibleModifiers.findIndex(
									({ id }) => id === visibleModifier.id
								);
							}
						}

						if (exists === -1) {
							this.visibleModifiers.push({
								id: v4(),
								like: [],
								quantity: 1,
								mod: lastTransMod
							});
						} else {
							++this.visibleModifiers[exists].quantity;
							this.visibleModifiers[exists].like.push(
								lastTransMod
							);
						}
					}
				} else {
					for (const selectedTransModifier of this
						.selectedTransModifiers) {
						if (selectedTransModifier.id === transModifier.id) {
							this.selectedTransModifiers = this.selectedTransModifiers.filter(
								({ id }) => id !== transModifier.id
							);
						}
					}
				}

				if (isComponentGroupRatioMod) {
					--componentGroupRatioCount;
				}

				// We want to apply Component Group Ratios on the final loop
				// where a ComponentGroupRatio ModifierFunction is applied
				if (
					componentGroupRatioCount === 0 &&
					hasComponentGroupRatios &&
					isComponentGroupRatioMod
				) {
					this.addComponentGroupRatio();
				}

				continue;
			}

			// If this modifier doesn't have an effect or failed to apply,
			// remove it

			// Check if this modifier still qualifies after any changes were made
			else if (
				reappliedModifier.hasEffect(this) &&
				reappliedModifier.apply(this)
			) {
				this.modifiers.push(sortedModifier);

				if (reappliedModifier.itemName === '') {
					let exists = -1;

					for (const visibleModifier of this.visibleModifiers) {
						let visibleModPrice: number;
						let sortedModPrice: number;

						// if (this.isDigital) { // TODO
						if (false) {
							// TODO
						} else {
							visibleModPrice =
								visibleModifier.mod.modifier.price;
							sortedModPrice = sortedModifier.modifier.price;
						}

						if (
							visibleModifier.mod.modifier.ticketName ===
								sortedModifier.modifier.ticketName &&
							Math.abs(visibleModPrice) -
								Math.abs(sortedModPrice) <
								0.01
						) {
							exists = this.visibleModifiers.findIndex(
								({ id }) => id === visibleModifier.id
							);
						}
					}

					if (exists === -1) {
						this.visibleModifiers.push({
							id: v4(),
							like: [],
							quantity: 1,
							mod: sortedModifier
						});
					} else {
						++this.visibleModifiers[exists].quantity;
						this.visibleModifiers[exists].like.push(sortedModifier);
					}
				}

				if (isComponentGroupRatioMod) {
					--componentGroupRatioCount;
				}

				// We want to apply Component Group Ratios on the final loop
				// where a ComponentGroupRatio ModifierFunction is applied
				if (
					componentGroupRatioCount === 0 &&
					hasComponentGroupRatios &&
					isComponentGroupRatioMod
				) {
					this.addComponentGroupRatio();
				}
			} else {
				if (isComponentGroupRatioMod) {
					--componentGroupRatioCount;
				}

				// We want to apply Component Group Ratios on the final loop
				// where a ComponentGroupRatio ModifierFunction is applied
				if (
					componentGroupRatioCount === 0 &&
					hasComponentGroupRatios &&
					isComponentGroupRatioMod
				) {
					this.addComponentGroupRatio();
				}
			}
		}

		if (!this.disableSlot) {
			// Do some adjustments to LiquidBase Adjustable Product Group Products, only under
			// the condition that this is the last time passing through this
			// function, as determined by modifyTally_
			const liquidBaseAdjustable = ProductGroup.getProductGroupOrThrow(
				Config.liquidBaseAdjustable
			);
			const liquidBase = ComponentGroup.getComponentGroup(
				Config.liquidBase
			);

			if (!liquidBaseAdjustable) {
				throw new Error(
					'Could not find liquid base adjustable product group.'
				);
			}

			if (!liquidBase) {
				throw new Error('Could not find liquid base component group.');
			}

			if (this.modifyTally === 1) {
				// adjust liquid base on applicable items
				if (liquidBaseAdjustable.containsProduct(this.item.product)) {
					if (this.hasComponentGroup(liquidBase)) {
						this.adjustLiquidBase();

						// If this causes the overflow of the liquid base, undo the
						// modification of the item
						if (this.liquidBaseOverflow) {
							this.unModify(modifier);

							if (modifierScale && currentScaleModifier) {
								this.modify(currentScaleModifier);
							}

							this.liquidBaseOverflow = false;
							this.liquidBaseMessage = true;
							this.adjustLiquidBase();
						}
					}

					// this.endResetModel();
				}
			}

			this.calculatePrice();
			this.calculateTax();
			this.determineTicketName();
			this.determineId();
			this.somethingChanged.emit();
		}

		--this.modifyTally;
		return 'ITEM_OK';
	}

	unModify(modifier: Modifier): ItemErrorCode {
		// console.log('TransItem: unModify');

		++this.unModifyTally;

		this.hasChangedSinceLastSent = true;

		const removedModifier = this.removeModifier(modifier) === 'ITEM_OK';

		if (!modifier.unapply(this) && !removedModifier) {
			--this.unModifyTally;
			return 'ITEM_UNKNOWN_ERROR';
		}

		// console.log('TransItem: unModify: Attempting to update modifier count');

		for (const modCount of this.modifierCount) {
			if (modCount[0] === modifier.id) {
				this.modifierCount = this.modifierCount.filter(
					([, , , , id]) => id === modCount[4]
				);
			} else {
				const modifier = Modifier.getModifier(modCount[0]);

				if (modifier && modifier.linked) {
					let adjustCounts = false;

					if (modifier.freeModifier?.id === modifier.id) {
						adjustCounts = true;
					} else {
						if (
							modifier.modifierScale?.freeModifier?.id ===
							modifier.id
						) {
							adjustCounts = true;
						}
					}

					if (adjustCounts) {
						--modCount[1];
						--modCount[3];
					}
				}
			}
		}

		this.determineTicketName();

		if (!this.determineId() && this.unModifyTally === 1) {
			this.resetItem();
		}

		--this.unModifyTally;

		return 'ITEM_OK';
	}

	adjustLiquidBase(): boolean {
		// console.log('TransItem: adjustLiquidBase');

		const liquidBaseComponents = this.getComponentGroupComponents(
			Config.liquidBase
		);
		const liquidBaseAdjustmentComponents = this.getComponentGroupComponents(
			Config.liquidBaseAdjustment
		);

		if (liquidBaseComponents.length === 0) {
			return false;
		}

		const container = this.item.container;

		if (!container) {
			return false;
		}

		let customerMug = container.id === Config.customerMug;

		if (!customerMug) {
			for (const transModifier of this.modifiers) {
				if (transModifier.modifier.id === Config.inAMug) {
					customerMug = true;
					break;
				}
			}
		}

		const coffeeRefill = this.item.product?.id === Config.coffeeRefill;

		// set deduction amount (room or whip)
		let deduction = 0;
		let hasWhip = this.hasComponentById(Config.whip, false);

		if (!customerMug) {
			if (hasWhip) {
				deduction += TransItem.whipAdjustment;
			}

			let withRoomCount = this.modifiers.reduce(
				(acc, transModifier) =>
					transModifier.modifier.id === Config.withRoom
						? acc + 1
						: acc,
				0
			);

			if (withRoomCount > 0) {
				deduction += TransItem.withRoomAdjustment * withRoomCount;
			}
		} else {
			if (hasWhip) {
				deduction += TransItem.mugDeduction;
			}

			let withRoomCount = this.modifiers.reduce(
				(acc, transModifier) =>
					transModifier.modifier.id === Config.withRoom
						? acc + 1
						: acc,
				0
			);

			if (withRoomCount > 0) {
				deduction += TransItem.mugDeduction * withRoomCount;
			}
		}

		this.setFoamStatus();

		const catering = ProductGroup.getProductGroupOrThrow(Config.catering);

		if (
			this.foamStatus === 'NO_FOAM' &&
			!hasWhip &&
			!catering.containsProduct(this.item.product) &&
			!coffeeRefill
		) {
			if (!customerMug) {
				deduction += TransItem.noFoamNoWhipRoom;
			} else {
				deduction += TransItem.mugDeduction;
			}
		}

		let volume = 0.0;

		if (!customerMug) {
			volume = container.getVolume(deduction);
		} else {
			volume = this.item.capacity || 0;

			if (!(volume > 0)) {
				volume += liquidBaseComponents.reduce(
					(acc, liquidBaseComponent) =>
						acc + liquidBaseComponent.quantity,
					0
				);
			}

			volume = volume - deduction;
		}

		if (volume <= 0) {
			this.liquidBaseOverflow = true;
			return false;
		}

		const liquidBaseUnit = ComponentGroup.getComponentGroup(
			Config.liquidBase
		)?.unit?.id;

		if (!liquidBaseUnit) {
			throw new Error('No liquid base unit.');
		}

		for (const liquidBaseAdjustmentComponent of liquidBaseAdjustmentComponents) {
			if (
				liquidBaseAdjustmentComponent.component.hasUnitRatioUnit(
					liquidBaseUnit
				)
			) {
				volume -=
					liquidBaseAdjustmentComponent.quantity *
					liquidBaseAdjustmentComponent.component.getUnitRatio(
						liquidBaseUnit
					);
			}
		}

		if (volume <= 0) {
			this.liquidBaseOverflow = true;
			return false;
		}

		let foamVolume = 0;
		if (this.foamStatus !== 'NO_FOAM') {
			if (!customerMug && !coffeeRefill) {
				foamVolume = this.getFoamQuantity(
					this.foamStatus,
					volume,
					deduction,
					container
				);
			} else if (customerMug && !coffeeRefill) {
				foamVolume = TransItem.mugDeduction;
			}

			volume -= foamVolume;

			if (volume < 0) {
				this.liquidBaseOverflow = true;
				return false;
			}

			// After reducing volume by the volume that foam takes up, multiply
			// the foamVolume by milk foam liquid %, and then add that back into
			// volume
			const foamLiquid = Config.milkFoamLiquidPercent * foamVolume;

			const foamComponents = this.getComponentGroupComponents(
				Config.milkFoam
			);

			const count = foamComponents.length;

			// // console.log('modifying the foamComponents quantities...');
			// console.log(
			// 	'here they are before the modification: ',
			// 	this.components
			// );

			for (const transComponent of foamComponents) {
				transComponent.quantity = foamLiquid / count;
			}

			// console.log(
			// 	'here they are AFTER the modification: ',
			// 	this.components
			// );
			// // console.log('make sure the code here actually adjusts the amounts');
		}

		// console.log("modifying the liquidBaseComponents' quantities...");
		// // console.log('here they are before the modification: ', this.components);

		// Adjust the quantity evenly of all the Components that are Liquid Base
		let liquidBaseCount = liquidBaseComponents.length;

		for (const liquidBaseComponent of liquidBaseComponents) {
			liquidBaseComponent.quantity = volume / liquidBaseCount;
		}

		// // console.log('here they are AFTER the modification: ', this.components);
		// // console.log('make sure the code here actually adjusts the amounts');

		return true;
	}

	getFoamQuantity(
		foamStatus: string | undefined,
		volume: number,
		deduction: number,
		container: Container
	): number {
		// console.log('TransItem: getFoamQuantity');

		let foamRatio = 0.0;
		let foamVolume = 0.0;

		switch (foamStatus) {
			case 'LATTE': {
				foamVolume =
					container.getVolume(deduction) -
					container.getVolume(deduction + TransItem.latteFoamHeight);
				break;
			}
			case 'LATTICCINO': {
				foamRatio = TransItem.latticcinoFoamRatio;
				foamVolume = volume * foamRatio;
				break;
			}
			case 'CAPPUCCINO': {
				foamRatio = TransItem.cappuccinoFoamRatio;
				foamVolume = volume * foamRatio;
				break;
			}
			case 'DRY_CAPPUCCINO': {
				foamRatio = TransItem.dryCappuccinoFoamRatio;
				foamVolume = volume * foamRatio;
				break;
			}
			case 'LIGHT_FOAM': {
				foamVolume =
					container.getVolume(deduction) -
					container.getVolume(deduction + TransItem.latteFoamHeight);
				foamVolume = foamVolume / 2;
				break;
			}
			default: {
				return 0.0;
			}
		}

		return foamVolume;
	}

	setFoamStatus(foamStatus?: FoamStatus) {
		// console.log('TransItem: setFoamStatus');

		if (foamStatus) {
			this.foamStatus = foamStatus;
		} else {
			const milkFoam = ComponentGroup.getComponentGroup(Config.milkFoam);
			const cappuccinoProductId = Config.cappuccinoProductId;
			const latticcino = Modifier.getModifier(Config.latticcino);
			const dryCappuccino = Modifier.getModifier(Config.dryCappuccino);
			const lightFoam = Modifier.getModifier(Config.lightFoam);

			if (
				!milkFoam ||
				!cappuccinoProductId ||
				!latticcino ||
				!dryCappuccino ||
				!lightFoam
			) {
				throw new Error('Failed to load something for setFoamStatus');
			}

			if (this.hasComponentGroup(milkFoam)) {
				if (this.getComponentGroupQuantity(milkFoam) > 0) {
					if (this.item.product?.id === cappuccinoProductId) {
						if (this.hasModifier(latticcino)) {
							this.foamStatus = 'LATTICCINO';
						} else if (this.hasModifier(dryCappuccino)) {
							this.foamStatus = 'DRY_CAPPUCCINO';
						} else {
							this.foamStatus = 'CAPPUCCINO';
						}
					} else if (this.hasModifier(lightFoam)) {
						this.foamStatus = 'LIGHT_FOAM';
					} else {
						this.foamStatus = 'LATTE';
					}
				} else {
					this.foamStatus = 'NO_FOAM';
				}
			} else {
				this.foamStatus = 'NO_FOAM';
			}
		}
	}

	getComponentGroupQuantity(group: ComponentGroup): number {
		// console.log('TransItem: getComponentGroupQuantity');
		let quantity = 0;

		for (const componentGroup of this.componentGroups) {
			const ingredient = this.item.recipe.find(
				ingredient =>
					ingredient.componentGroup &&
					ingredient.componentGroup.id === componentGroup.id
			);

			if (componentGroup.id === group.id && ingredient) {
				quantity += ingredient.quantity;
			}

			if (group.containsComponentGroup(componentGroup.id) && ingredient) {
				quantity += ingredient.quantity;
			}
		}

		for (const component of this.components) {
			if (group.containsComponent(component.component.id)) {
				quantity += component.quantity;
			}
		}

		return quantity;
	}

	getComponentGroupComponents(componentGroupId: number): TransComponent[] {
		// console.log('TransItem: getComponentGroupComponents');
		const transComponents = this.components;
		const componentGroupComponents: TransComponent[] = [];
		const componentGroup = ComponentGroup.getComponentGroup(
			componentGroupId
		);

		if (!componentGroup) {
			throw new Error(
				'Failed to find componentGroup for getComponentGroupComponents'
			);
		}

		for (const transComponent of transComponents) {
			if (componentGroup.containsComponent(transComponent.component.id)) {
				componentGroupComponents.push(transComponent);
			}
		}

		return componentGroupComponents;
	}

	endResetModel(): void {
		// TODO: itemChanged()
		// console.error('Method not implemented.');
	}

	getModifier(modifier: Modifier): TransModifier | undefined {
		return this.modifiers.find(
			transModifier => transModifier.modifier.id === modifier.id
		);
	}

	addComponentGroupRatio(): void {
		// console.log('TransItem: addComponentGroupRatio');
		const componentGroupRatio = this.getComponentGroupRatio();

		if (componentGroupRatio) {
			this.applyComponentGroupRatio(componentGroupRatio);
		}
	}

	getComponentGroupRatio(): ComponentGroupRatio | undefined {
		// console.log('TransItem: getComponentGroupRatio');
		let output: ComponentGroupRatio | undefined;

		let sizeId = 0;
		if (this.item.size) {
			sizeId = this.item.size.id;
		}

		const componentGroupRatioTypes = Object.values(
			ComponentGroupRatioType.componentGroupRatioTypes
		);

		let componentGroupRatioType = componentGroupRatioTypes
			.filter(
				componentGroupRatioType =>
					componentGroupRatioType && componentGroupRatioType.linked
			)
			.find(componentGroupRatioType =>
				(componentGroupRatioType as ComponentGroupRatioType).qualifies(
					this
				)
			) as ComponentGroupRatioType | undefined;

		if (componentGroupRatioType === undefined) {
			return;
		}

		const distinctComponentGroups = this.getDistinctComponentGroups();
		let componentGroupRatios: ComponentGroupRatio[] = componentGroupRatioType.getComponentGroupRatios(
			distinctComponentGroups
		);

		let maxComponentGroupCount = 0;
		let componentGroupRatioSizeId = 0;

		for (
			let ratioIndex = 0;
			ratioIndex < componentGroupRatios.length;
			++ratioIndex
		) {
			const returnUndefined = (): boolean => {
				componentGroupRatios = componentGroupRatios.filter(
					(_, index) => index !== ratioIndex
				);

				if (componentGroupRatios.length === 0) {
					return true;
				}

				--ratioIndex;
				return false;
			};

			if (
				componentGroupRatios[ratioIndex].componentGroupRatioType
					.componentGroupRatioTypeId !==
				componentGroupRatioType.componentGroupRatioTypeId
			) {
				if (returnUndefined()) return;
				else continue;
			}

			if (componentGroupRatios[ratioIndex].sizeId) {
				componentGroupRatioSizeId = componentGroupRatios[ratioIndex]
					.sizeId!;
			} else {
				componentGroupRatioSizeId = 0;
			}

			if (componentGroupRatioSizeId !== sizeId) {
			}

			if (distinctComponentGroups.length === 0) {
				if (returnUndefined()) return;
				else continue;
			}

			const ratioComponentGroups =
				componentGroupRatios[ratioIndex].componentGroups;

			if (ratioComponentGroups.length < maxComponentGroupCount) {
				if (returnUndefined()) return;
				else continue;
			}

			let matchingComponentGroups = 0;

			for (let i = 0; i < ratioComponentGroups.length; ++i) {
				for (let j = 0; j < distinctComponentGroups.length; ++j) {
					if (
						ratioComponentGroups[i].id ===
						distinctComponentGroups[j].id
					) {
						++matchingComponentGroups;
					}
				}
			}

			if (ratioComponentGroups.length !== matchingComponentGroups) {
				if (returnUndefined()) return;
				else continue;
			}

			if (matchingComponentGroups > maxComponentGroupCount) {
				maxComponentGroupCount = matchingComponentGroups;

				ratioIndex = -1;
				continue;
			}

			let componentGroupCounts =
				componentGroupRatios[ratioIndex].componentGroupCounts;

			if (componentGroupCounts.length !== ratioComponentGroups.length) {
				if (returnUndefined()) return;
				else continue;
			}

			let counts = true;

			for (let i = 0; i < ratioComponentGroups.length; ++i) {
				const componentCount: number = this.getComponentGroupComponentCount(
					ratioComponentGroups[i].id
				);

				if (componentCount !== componentGroupCounts[i]) {
					counts = false;
					break;
				}
			}

			if (counts === false) {
				if (returnUndefined()) return;
				else continue;
			}
		}

		if (componentGroupRatios.length > 1) {
			let componentGroups: ComponentGroup[];

			for (
				let ratioIndex = 0;
				ratioIndex < componentGroupRatios.length;
				++ratioIndex
			) {
				componentGroups =
					componentGroupRatios[ratioIndex].componentGroups;

				if (componentGroups.length !== maxComponentGroupCount) {
					componentGroupRatios = componentGroupRatios.filter(
						(_, index) => index !== ratioIndex
					);

					if (componentGroupRatios.length === 0) {
						return;
					}

					--ratioIndex;
					continue;
				}
			}
		}

		if (componentGroupRatios.length > 1) {
			const ratiosThatQualify: ComponentGroupRatio[] = [];
			const ratiosWithoutItemQualifiers: ComponentGroupRatio[] = [];

			for (const componentGroupRatio of componentGroupRatios) {
				if (componentGroupRatio.itemQualifiers.length === 0) {
					ratiosWithoutItemQualifiers.push(componentGroupRatio);

					continue;
				} else {
					if (componentGroupRatio.qualifies(this)) {
						ratiosThatQualify.push(componentGroupRatio);
					}
				}
			}

			if (ratiosThatQualify.length === 0) {
				if (ratiosWithoutItemQualifiers.length === 0) {
					return;
				} else {
					output = ratiosWithoutItemQualifiers[0];

					return output;
				}
			} else if (ratiosThatQualify.length > 1) {
				return;
			} else {
				output = ratiosThatQualify[0];
				return output;
			}
		} else if (componentGroupRatios.length === 0) {
			return;
		} else {
			output = componentGroupRatios[0];
		}

		return output;
	}

	getComponentGroupComponentCount(componentGroupId: number): number {
		// console.log('TransItem: getComponentGroupComponentCount');
		let count = 0;

		for (const transComponent of this.components) {
			const componentGroups = transComponent.component.componentGroups;

			if (componentGroups.length === 0) continue;

			for (const componentGroup of componentGroups) {
				if (componentGroup.id === componentGroupId) {
					++count;

					break;
				}
			}
		}

		return count;
	}

	getDistinctComponentGroups(): ComponentGroup[] {
		// console.log('TransItem: getDistinctComponentGroups');
		const distinctComponentGroups: ComponentGroup[] = [];
		const distinctComponentGroupIds: number[] = [];

		let componentGroups: ComponentGroup[] = [];

		for (const transComponent of this.components) {
			componentGroups = transComponent.component.componentGroups.map(
				componentGroup => new ComponentGroup(componentGroup)
			);

			for (const componentGroup of componentGroups) {
				const componentGroupId = componentGroup.id;

				if (distinctComponentGroups.length === 0) {
					distinctComponentGroups.push(componentGroup);
					distinctComponentGroupIds.push(componentGroupId);
					continue;
				}

				if (distinctComponentGroupIds.includes(componentGroupId))
					continue;

				distinctComponentGroups.push(componentGroup);
				distinctComponentGroupIds.push(componentGroupId);
			}
		}

		return distinctComponentGroups;
	}

	applyComponentGroupRatio(
		componentGroupRatio: ComponentGroupRatio
	): boolean {
		// console.log('TransItem: applyComponentGroupRatio');
		const {
			componentGroups,
			componentGroupCounts,
			componentGroupQuantities
		} = componentGroupRatio;

		if (componentGroups.length !== componentGroupCounts.length)
			return false;

		for (let i = 0; i < componentGroups.length; ++i) {
			const componentGroup = componentGroups[i];
			const componentGroupCount = componentGroupCounts[i];
			const componentGroupQuantity = componentGroupQuantities[i];
			const quantityToSet = componentGroupQuantity / componentGroupCount;

			for (const transComponent of this.components) {
				if (
					componentGroup.containsComponent(
						transComponent.component.id
					)
				) {
					transComponent.quantity = quantityToSet;
				}
			}
		}

		return true;
	}

	addRecipeComponent(componentId: number): ItemErrorCode {
		// console.log('TransItem: addRecipeComponent');
		this.hasChangedSinceLastSent = true;

		if (this.isVoided) {
			return 'ITEM_VOIDED';
		}

		if (this.isSent) {
			return 'ITEM_SENT';
		}

		if (this.hasComponentById(componentId, true)) {
			return 'ITEM_ALREADY_EXISTS';
		}

		let components: Component[] = [];

		// Get all the unvoided components in this trans item
		for (const transComponent of this.components) {
			if (!transComponent.isVoided) {
				components.push(transComponent.component);
			}
		}

		for (const componentGroup of this.componentGroups) {
			if (componentGroup.containsComponent(componentId)) {
				const component = Component.getComponent(componentId);

				if (component) {
					const newComponet = new TransComponent(
						component,
						this.item.getQuantity(componentId)
					);

					const error = this.addTransComponent(newComponet);

					if (error !== 'ITEM_OK') return error;

					// Check to see if this component is the default of a given component group
					for (const ingredient of this.item.recipe) {
						if (
							ingredient.componentGroup?.id ===
								componentGroup.id &&
							ingredient.component?.id === componentId
						) {
							return 'ITEM_ALREADY_EXISTS';
						}
					}

					return 'ITEM_OK';
				}
			}
		}

		// Check to see if there are any default component group components that this would replace
		for (const ingredient of this.item.recipe) {
			if (ingredient.component && ingredient.componentGroup) {
				if (ingredient.componentGroup.containsComponent(componentId)) {
					// if (
					// 	new ComponentGroup(
					// 		ingredient.componentGroup
					// 	).containsComponent(componentId)
					// ) {
					for (const component of components) {
						if (component.id === ingredient.component.id) {
							const comp = Component.getComponent(componentId);

							if (comp) {
								const newComponet = new TransComponent(
									comp,
									this.item.getQuantity(component.id)
								);

								this.removeComponent(component.id);

								const error = this.addTransComponent(
									newComponet
								);

								if (error !== 'ITEM_OK') return error;

								return 'ITEM_OK';
							}
						}
					}
				}
			}
		}

		const component = Component.getComponent(componentId);

		if (!component) {
			throw new Error('Failed to get component');
		}

		components.push(component);

		const newItem = Item.getItem(
			this,
			components,
			this.componentGroups,
			this.item.size,
			this.item
		);

		if (!newItem) return 'ITEM_ALREADY_EXISTS';

		if (newItem.getQuantity(componentId) <= 0) {
			return 'ITEM_UNKNOWN_ERROR';
		}

		const comp = Component.getComponent(componentId);

		if (!comp) {
			throw new Error('Failed to get comp');
		}

		const newComponent = new TransComponent(
			comp,
			newItem.getQuantity(componentId)
		);

		const error = this.addTransComponent(newComponent);

		this.determineId();

		// recipe = item_->getRecipe(); // TODO

		if (error !== 'ITEM_OK') {
			return error;
		}

		return 'ITEM_OK';
	}

	determineId(): boolean {
		// console.log('TransItem: determineId');
		this.hasChangedSinceLastSent = true;

		// get all the unvoided components in this trans item
		const components: Component[] = this.components
			.filter(transComponent => !transComponent.isVoided)
			.map(transComponent => transComponent.component);

		// console.log('TransItem: determineId: 1');

		const newItem = Item.getItem(
			this,
			components,
			this.componentGroups,
			this.item.size,
			this.item
		);
		// console.log('TransItem: determineId: 2');

		// console.log('newItem', newItem);

		if (!newItem || newItem.id === this.item.id) {
			// console.log('TransItem: determineId: done 1');
			return false;
		}

		this.item = newItem;

		this.resetItem();
		// console.log('TransItem: determineId: done 2');

		return true;
	}

	resetItem(reapplyVarQMods: boolean = false) {
		// console.log('TransItem: resetItem');
		this.hasChangedSinceLastSent = true;

		this.foamStatus = this.item.foamStatus;

		let component: Component | undefined;

		const modifiers: Modifier[] = [];
		this.modifierCount = [];

		for (
			let componentIndex = 0;
			componentIndex < this.components.length;
			++componentIndex
		) {
			component = this.components[componentIndex].component;

			if (this.item.inRecipe(component, false, false)) {
				if (!component.isVariableQuantity) {
					this.components[
						componentIndex
					].quantity = this.item.getQuantity(component.id);
				}
			} else {
				if (component.static) {
					if (component.associatedModifierId !== 0) {
						const mod = Modifier.getModifier(
							component.associatedModifierId
						);

						if (!mod) {
							throw new Error('Failed to get modifier');
						}

						let append = true;
						for (const transModifier of this.modifiers) {
							if (mod.id === transModifier.modifier.id) {
								append = false;
								break;
							}
						}

						if (append && reapplyVarQMods) {
							modifiers.push(mod);
						} else if (append && !mod.isVariableQuantity) {
							modifiers.push(mod);
						}
					}
					this.components = this.components.filter(
						(_, index) => index !== componentIndex
					);
					--componentIndex;
					continue;
				} else {
					this.components = this.components.filter(
						(_, index) => index !== componentIndex
					);
					--componentIndex;
				}
			}
		}

		for (const componentGroup of this.componentGroups) {
			if (!this.item.componentGroupInRecipe(componentGroup)) {
				this.componentGroups = this.componentGroups.filter(
					({ id }) => id !== componentGroup.id
				);
				this.requiredComponentGroups = this.requiredComponentGroups.filter(
					({ id }) => id !== componentGroup.id
				);
			}
		}

		const recipe = this.item.recipe;

		for (const ingredient of recipe) {
			component = ingredient.component;

			if (!component) {
				if (!ingredient.componentGroup) {
					throw new Error(
						'Ingredient is missing component and componentGroup.'
					);
				}

				this.componentGroups.push(ingredient.componentGroup);
				if (Math.round(ingredient.relevance * 10) === 10) {
					this.requiredComponentGroups.push(
						ingredient.componentGroup
					);
				}

				continue;
			}

			if (!this.hasComponent(component)) {
				const transComponent = new TransComponent(
					component,
					ingredient.quantity
				);

				this.addTransComponent(transComponent);
			}
		}

		while (this.modifiers.length !== 0) {
			modifiers.push(this.modifiers.shift()!.modifier);
		}

		this.selectedTransModifiers = [];
		this.applyNameModifiers();
		this.visibleModifiers = [];

		for (const modifier of modifiers) {
			this.modify(modifier);
		}

		this.determineTicketName();
		this.calculatePrice();
		this.calculateTax();
		this.somethingChanged.emit();
	}

	getComponent(componentId: number): TransComponent | undefined {
		// console.log('TransItem: getComponent');

		return this.components.find(
			transComponent => transComponent.component.id === componentId
		);
	}

	getComponentTransComponentIndex(componentId: number): number {
		// console.log('TransItem: getComponentTransComponentIndex');

		return this.components.findIndex(
			transComponent => transComponent.component.id === componentId
		);
	}

	getTransComponentIndexByTransComponentId(transComponentId: string): number {
		// console.log('TransItem: getTransComponentIndexByTransComponentId');
		return this.components.findIndex(
			transComponent => transComponent.id === transComponentId
		);
	}

	removeComponent(
		componentId: number,
		includeOtherVersions: boolean = false
	): ItemErrorCode {
		// console.log('TransItem: removeComponent');

		this.hasChangedSinceLastSent = true;

		if (this.isVoided) {
			return 'ITEM_VOIDED';
		}

		const comp = this.getComponent(componentId);

		if (!comp && !includeOtherVersions) {
			return 'ITEM_NOT_PRESENT';
		}

		// if (includeOtherVersions) {
		// 	const cmp = Component.getComponent(componentId);

		// 	if (!cmp) {
		// 		throw new Error('Failed to get component');
		// 	}

		// 	const component = Component.getComponent(cmp.baseComponentId);

		// 	if (!component) {
		// 		throw new Error('Failed to get component');
		// 	}

		// 	this.components = this.components.filter(
		// 		transComponent =>
		// 			transComponent.component.baseComponentId !== component.id
		// 	);
		// } else {
		// 	this.components = this.components.filter(
		// 		transComponent => transComponent.component.id !== componentId
		// 	);
		// }

		if (includeOtherVersions) {
			const component = Component.getComponent(componentId);

			if (!component) {
				throw new Error('Failed to get component');
			}

			this.components = this.components.filter(
				transComponent =>
					transComponent.component.baseComponentId !==
					component.baseComponentId
			);
		} else {
			this.components = this.components.filter(
				transComponent => transComponent.component.id !== componentId
			);
		}

		const recipe = this.item.recipe;

		for (const { componentGroup, relevance } of recipe) {
			if (
				componentGroup &&
				!this.componentGroups.some(
					componentGroup => componentGroup.id === componentGroup!.id
				) &&
				this.components.some(transComponent =>
					componentGroup!.containsComponent(
						transComponent.component.id
					)
				)
			) {
				this.componentGroups.push(componentGroup);

				if (Math.round(relevance * 10) === 10) {
					this.requiredComponentGroups.push(componentGroup);
				}
			}
		}

		return 'ITEM_OK';
	}

	removeModifier(modifier: Modifier): ItemErrorCode {
		this.hasChangedSinceLastSent = true;

		// console.log('TransItem: removeModifier');
		for (const transModifier of this.modifiers) {
			let remove = false;

			if (modifier.id === transModifier.modifier.id) {
				remove = true;
			}

			const modifierScale = modifier.modifierScale;
			const transModifierScale = transModifier.modifier.modifierScale;

			// We also need to check if the Modifier being checked to be removed
			// is in the same modifier scale as another modifier in the transitem.
			// This could happen if we add Caramel to a Steamer, change the whip
			// level to light whip, then remove the Caramel (which would only
			// remove Whipped Cream and not Light Whip).
			if (
				modifierScale &&
				transModifierScale &&
				modifierScale.id === transModifierScale.id
			) {
				remove = true;
			}

			if (remove) {
				for (let j = 0; j < this.visibleModifiers.length; ++j) {
					if (transModifier.id === this.visibleModifiers[j].mod.id) {
						if (this.visibleModifiers[j].like.length === 0) {
							this.visibleModifiers = this.visibleModifiers.filter(
								(_, index) => index !== j
							);

							break;
						}

						let like: VisibleModifier['like'] = [];

						for (
							let l = 1;
							l < this.visibleModifiers[j].like.length;
							++l
						) {
							like.push(this.visibleModifiers[j].like[l]);
						}

						let v: VisibleModifier = {
							mod: this.visibleModifiers[j].like[0],
							quantity: this.visibleModifiers[j].quantity - 1,
							like,
							id: this.visibleModifiers[j].id
						};

						this.visibleModifiers[j] = v;

						break;
					}

					let isLike = false;

					for (
						let k = 0;
						k < this.visibleModifiers[j].like.length;
						++k
					) {
						if (
							transModifier.id ===
							this.visibleModifiers[j].like[k].id
						) {
							isLike = true;

							let like: VisibleModifier['like'] = [];

							for (
								let l = 1;
								l < this.visibleModifiers[j].like.length;
								++l
							) {
								if (
									this.visibleModifiers[j].like[l].id !==
									this.visibleModifiers[j].like[k].id
								) {
									like.push(this.visibleModifiers[j].like[l]);
								}
							}

							let v: VisibleModifier = {
								mod: this.visibleModifiers[j].mod,
								quantity: this.visibleModifiers[j].quantity - 1,
								like,
								id: this.visibleModifiers[j].id
							};

							this.visibleModifiers[j] = v;

							break;
						}
					}

					if (isLike) {
						break;
					}
				}

				this.modifiers = this.modifiers.filter(
					({ id }) => id !== transModifier.id
				);
				this.selectedTransModifiers = this.selectedTransModifiers.filter(
					({ id }) => id !== transModifier.id
				);

				return 'ITEM_OK';
			}
		}

		return 'ITEM_NOT_PRESENT';
	}

	resetItemToRecipeAndReapplyModifiers(modifier: Modifier): void {
		// console.log('TransItem: resetItemToRecipeAndReapplyModifiers');
		this.hasChangedSinceLastSent = true;
		this.foamStatus = this.item.foamStatus;

		let componentId: number;
		let modifiers: Modifier[] = [];

		// Loop through all existing components and set their quantities to
		// the recipe quantities, or delete them if they are not in the recipe
		for (const transComponent of this.components) {
			componentId = transComponent.component.id;

			if (this.item.inRecipe(transComponent.component, false, false)) {
				transComponent.quantity = this.item.getQuantity(componentId);
			} else {
				if (transComponent.component.static) {
					const associatedModifierId =
						transComponent.component.associatedModifierId;
					const associatedModifier = Modifier.getModifier(
						associatedModifierId
					);

					if (
						associatedModifierId !== 0 &&
						associatedModifierId !== modifier.id &&
						associatedModifier !== undefined &&
						!associatedModifier.isVariableQuantity
					) {
						modifiers.push(associatedModifier);
					}
				}

				this.components = this.components.filter(
					({ id }) => id !== transComponent.id
				);
			}
		}

		for (const componentGroup of this.componentGroups) {
			if (!this.item.componentGroupInRecipe(componentGroup)) {
				this.componentGroups = this.componentGroups.filter(
					({ id }) => id !== componentGroup.id
				);
				this.requiredComponentGroups = this.requiredComponentGroups.filter(
					({ id }) => id !== componentGroup.id
				);
			}
		}

		// Loop through the item's recipe and if the current item does not have
		// any of the components, add them
		for (const ingredient of this.item.recipe) {
			if (!ingredient.component) continue;

			if (!this.hasComponentById(ingredient.component.id, false)) {
				const transComponent = new TransComponent(
					ingredient.component,
					ingredient.quantity
				);

				this.addTransComponent(transComponent);
			}
		}

		for (const transModifier of this.modifiers) {
			transModifier.modifier.apply(this);
		}

		for (const modifier of modifiers) {
			this.modify(modifier);
		}
	}

	resetItemToDefaults(modifier: Modifier): void {
		// console.log('TransItem: resetItemToDefaults');
		this.hasChangedSinceLastSent = true;
		this.foamStatus = this.item.foamStatus;

		for (const transComponent of this.components) {
			const component = transComponent.component;

			if (this.item.inRecipe(component, false, false)) {
				if (!component.isVariableQuantity) {
					transComponent.quantity = this.item.getQuantity(
						component.id
					);
				}
			} else {
				this.components = this.components.filter(
					({ id }) => id !== transComponent.id
				);
			}
		}

		for (const ingredient of this.item.recipe) {
			if (ingredient.componentGroup) {
				if (
					!this.componentGroups.includes(
						new ComponentGroup(ingredient.componentGroup)
					)
				) {
					let needToAddComponentGroup = true;

					for (const transComponent of this.components) {
						if (
							new ComponentGroup(
								ingredient.componentGroup
							).containsComponent(transComponent.component.id)
						) {
							needToAddComponentGroup = false;

							break;
						}
					}

					if (needToAddComponentGroup) {
						this.componentGroups.push(
							new ComponentGroup(ingredient.componentGroup)
						);

						if (Math.round(ingredient.relevance * 10) === 10) {
							this.requiredComponentGroups.push(
								new ComponentGroup(ingredient.componentGroup)
							);
						}
					}
				}
			} else if (ingredient.component) {
				if (!this.hasComponentById(ingredient.component.id, false)) {
					const component = new TransComponent(
						ingredient.component,
						ingredient.quantity
					);

					this.addTransComponent(component);
				}
			} else {
				throw new Error('Invalid ingredient.');
			}
		}

		this.foamStatus = this.item.foamStatus;
	}

	addTransComponent(component: TransComponent): ItemErrorCode {
		// console.log('TransItem: addTransComponent');
		this.components.push(component);
		this.components = this.components.sort(
			(a, b) => a.component.id - b.component.id
		);

		for (const componentGroup of this.componentGroups) {
			if (componentGroup.containsComponent(component.component.id)) {
				this.componentGroups = this.componentGroups.filter(
					({ id }) => id !== componentGroup.id
				);
				this.requiredComponentGroups = this.requiredComponentGroups.filter(
					({ id }) => id !== componentGroup.id
				);
			}
		}

		return 'ITEM_OK';
	}

	sortTransModifiers(
		modifiers: TransModifier[],
		transModifier: TransModifier
	): TransModifier[] {
		// console.log('TransItem: sortTransModifiers');
		modifiers.push(transModifier);

		if (!(modifiers.length > 1)) {
			return modifiers;
		}

		return modifiers.sort((a, b) => {
			if (a.modifier.priority === b.modifier.priority) {
				return a.timeAdded - b.timeAdded;
			}

			return a.modifier.priority - b.modifier.priority;
		});
	}

	hasModifierGroup(modifierGroup: ModifierGroup): boolean {
		// console.log('TransItem: hasModifierGroup');
		for (const transModifier of this.modifiers) {
			if (modifierGroup.hasModifier(transModifier.modifier.id)) {
				return true;
			}
		}

		return false;
	}

	getSyrupCount(syrupComponentGroup: ComponentGroup): number {
		// console.log('TransItem: getSyrupCount');
		let count = 0;

		for (const transComponent of this.components) {
			if (
				syrupComponentGroup.containsComponent(
					transComponent.component.id
				)
			) {
				++count;
			}
		}

		return count;
	}

	hasModifierGroupModifier(modifierGroup: ModifierGroup): boolean {
		// console.log('TransItem: hasModifierGroupModifier');
		return this.hasModifierGroup(modifierGroup);
	}

	hasVersionableComponent(versionId: number): boolean {
		// console.log('TransItem: hasVersionableComponent');
		// FIX
		for (const { component } of this.components) {
			if (component.componentVersion?.id === versionId) {
				return true;
			} else if (component.getOtherVersion(versionId)) {
				return true;
			}
		}

		return false;
	}

	hasModifier(modifier: Modifier) {
		// console.log('TransItem: hasModifier');
		return modifier.hasModifier(this);
	}

	hasModifierById(modifierId: number): boolean {
		// console.log('TransItem: hasModifierById');
		for (const transModifier of this.modifiers) {
			if (transModifier.modifier.id === modifierId) {
				return true;
			}
		}

		return false;
	}

	// hasComponentGroup(componentGroup: ComponentGroup): boolean {
	// 	// console.log('TransItem: hasComponentGroup');

	// 	for (const { id } of this.componentGroups) {
	// 		if (id === componentGroup.id) {
	// 			return true;
	// 		}

	// 		if (componentGroup.containsComponentGroup(id)) {
	// 			return true;
	// 		}
	// 	}

	// 	return this.components.some(({ component: { id } }) =>
	// 		componentGroup.containsComponent(id)
	// 	);
	// }

	hasComponentGroup(inputComponentGroup: ComponentGroup): boolean {
		// console.log('TransItem: hasComponentGroup');
		const componentGroup = new ComponentGroup(inputComponentGroup);
		let hasComponentGroup = false;

		for (const group of this.componentGroups) {
			if (Number(group.id) === componentGroup.id) {
				hasComponentGroup = true;
				break;
			}

			if (
				componentGroup.childGroups.find(
					child => child.id === group.id
				) !== undefined
			) {
				hasComponentGroup = true;
				break;
			}
		}

		for (const component of this.components) {
			if (componentGroup.containsComponent(component.component.id)) {
				hasComponentGroup = true;

				break;
			}
		}

		return hasComponentGroup;
	}

	hasComponent(
		component: Component,
		includeOtherVersions: boolean | undefined = false
	): boolean {
		// console.log('TransItem: hasComponent');
		if (includeOtherVersions) {
			for (const transComponentData of this.components) {
				const transComponent = new TransComponent(
					transComponentData.component,
					transComponentData.quantity,
					transComponentData
				);

				if (
					component.baseComponentId &&
					transComponent.component.baseComponentId
				) {
					const hasComponent =
						component.id === transComponent.component.id;
					// const isNotVoided = !transComponent.isVoided; // TODO: add this condition

					if (hasComponent) return true;
					// if (hasComponent && isNotVoided) return true;
				}
			}
		} else {
			for (const transComponent of this.components) {
				const hasComponent =
					component.id === transComponent.component.id;
				// const isNotVoided = !transComponent.isVoided; // TODO: add this condition

				if (hasComponent) return true;
				// if (hasComponent && isNotVoided) return true;
			}
		}

		return false;
	}

	hasComponentById(
		componentId: number,
		includeOtherVersions: boolean
	): boolean {
		// console.log('TransItem: hasComponentById');
		if (includeOtherVersions) {
			const otherComponent = Component.getComponent(componentId);

			if (otherComponent && otherComponent.linked) {
				for (const transComponent of this.components) {
					if (
						transComponent.component.baseComponentId ===
						otherComponent.baseComponentId
					) {
						return true;
					}
				}
			}
		} else {
			for (const transComponent of this.components) {
				if (componentId === transComponent.component.id) {
					return true;
				}
			}
		}

		return false;
	}

	// addComponent(component: Component, quantity: number): ItemErrorCode {
	// 	// console.log('TransItem: addComponent');

	// 	if (this.isVoided) {
	// 		return 'ITEM_VOIDED';
	// 	}

	// 	const comp = this.getComponent(component.id);

	// 	if (comp) {
	// 		comp.quantity += quantity;

	// 		return 'ITEM_OK';
	// 	}

	// 	this.components.push(new TransComponent(component, quantity));
	// 	this.components = this.components.sort(
	// 		(a, b) => a.component.id - b.component.id
	// 	);

	// 	for (const componentGroup of this.componentGroups) {
	// 		if (componentGroup.containsComponent(component.id)) {
	// 			this.componentGroups = this.componentGroups.filter(
	// 				group => group.id !== componentGroup.id
	// 			);
	// 			this.requiredComponentGroups = this.requiredComponentGroups.filter(
	// 				group => group.id !== componentGroup.id
	// 			);

	// 			break;
	// 		}
	// 	}

	// 	return 'ITEM_OK';
	// }

	addComponent(component: Component, quantity: number): ItemErrorCode {
		// console.log('TransItem: addComponent');

		if (this.isVoided) {
			return 'ITEM_VOIDED';
		}

		const comp = this.getComponent(component.id);

		if (comp) {
			comp.quantity += quantity;

			return 'ITEM_OK';
		}

		this.components.push(new TransComponent(component, quantity));

		for (const componentGroup of this.componentGroups) {
			if (componentGroup.containsComponent(component.id)) {
				const componentGroupToRemoveIndex = this.componentGroups.findIndex(
					({ id }) => id === componentGroup.id
				);

				if (componentGroupToRemoveIndex > -1) {
					this.componentGroups = this.componentGroups.filter(
						(_, index) => index !== componentGroupToRemoveIndex
					);
					this.requiredComponentGroups = this.requiredComponentGroups.filter(
						(_, index) => index !== componentGroupToRemoveIndex
					);
					// this.componentGroups = this.componentGroups.splice(
					// 	componentGroupToRemoveIndex,
					// 	1
					// );
					// this.requiredComponentGroups = this.requiredComponentGroups.splice(
					// 	componentGroupToRemoveIndex,
					// 	1
					// );
				} else {
					throw new Error(
						'Could not find component group after determining that it contains the added component'
					);
				}

				break;
			}
		}

		return 'ITEM_OK';
	}

	determineTicketName(): void {
		// console.error('determineTicketName(): Method not implemented.');
	}

	calculateTax(): void {
		this.taxRate = 0;
		let alwaysTax = false;

		const alwaysTaxComponentGroup = ComponentGroup.getComponentGroup(
			Config.alwaysTax
		);

		for (const transComponent of this.components) {
			if (transComponent.tax > this.taxRate) {
				if (
					alwaysTaxComponentGroup?.containsComponent(
						transComponent.component.id
					)
				) {
					alwaysTax = true;
				}

				this.taxRate = transComponent.tax;
			}
		}

		if (!Config.carryoutTax && !alwaysTax) {
			this.taxRate = 0;
		}
	}

	calculatePrice(): Money {
		// console.log('TransItem: calculatePrice');
		const itemPrice = this.item.price;

		let variableComponentsQuantity = this.components.reduce(
			(acc, { component: { isVariableQuantity }, quantity }) =>
				isVariableQuantity ? acc + quantity : acc,
			0
		);

		if (variableComponentsQuantity > 0) {
			this.price.assign(variableComponentsQuantity * itemPrice);
		} else if (!this.item.isVariablePrice) {
			this.price.assign(itemPrice);
		}

		if (this.isReturn) {
			this.price.multiply(-1);
		}

		this.price
			.assign(
				this.modifiers.reduce(
					(acc, { price }) => Money.add(acc, price),
					this.price
				)
			)
			.round();

		return this.price;
	}

	applyNameModifiers(): void {
		// console.log('TransItem: applyNameModifiers');
		const modifierScales = ModifierScale.getModifierScales(this);

		for (const scale of modifierScales) {
			for (const modifier of scale.modifiers) {
				const affectsComponents = modifier.affectsComponents(this);
				const isItemNameEmpty =
					modifier.itemName === undefined ||
					modifier.itemName === null ||
					modifier.itemName === '';
				const hasModifier = this.hasModifier(modifier);
				// this.modifiers.find(
				// 	({ modifier: { id } }) => id === modifier.id
				// ) !== undefined;

				if (!affectsComponents && !isItemNameEmpty && !hasModifier) {
					this.modifierCount.push([
						modifier.modifierId,
						modifier.modifierScaleIndex!,
						0,
						0,
						v4()
					]);
					const transModifier = new TransModifier(modifier);

					this.modifiers.push(transModifier);

					break;
				}
			}
		}
	}

	initializeComponentsToRecipeDefaults(): void {
		// console.log('TransItem: initializeComponentsToRecipeDefaults');
		for (const ingredient of this.item.recipe) {
			if (ingredient.component) {
				this.addComponent(ingredient.component, ingredient.quantity);
			} else if (ingredient.componentGroup) {
				this.componentGroups.push(ingredient.componentGroup);

				if (Math.round(ingredient.relevance * 10) === 10) {
					this.requiredComponentGroups.push(
						ingredient.componentGroup
					);
				}
			}
		}
	}

	hasCostAppliesComponent(): boolean {
		// for (const transComponent of this.components) {
		// 	if (transComponent.component.componentGroups.length > 0) {
		// 		for (const componentGroup of transComponent.component.componentGroups) {
		// 			if(componentGroup.id === Config.costApplies) {
		// 				return true;
		// 			}
		// 		}
		// 	}
		// }

		return this.components.some(transComponent =>
			transComponent.component.componentGroups.some(
				({ id }) => id === Config.costApplies
			)
		);
	}

	changeSize(size: Size) {
		this.hasChangedSinceLastSent = true;

		const defaultModifierIds = this.getDefaultModifiers().map(
			({ id }) => id
		);

		this.item = this.item.product.getItem(size);

		this.isPrintable = this.item.product.printable;

		this.price.assign(this.item.price);

		this.modifiers = this.modifiers.filter(
			({ modifier: { id } }) => !defaultModifierIds.includes(id)
		);

		this.resetItem();
	}

	getDefaultModifiers(): Modifier[] {
		const output: Modifier[] = [];

		const defaultItem = new TransItem(this.item, false, this.id);

		for (const transModifier of defaultItem.modifiers) {
			output.push(transModifier.modifier);
		}

		return output;
	}

	changeVersion(version: ProductVersion) {
		this.hasChangedSinceLastSent = true;

		const defaultModifierIds = this.getDefaultModifiers().map(
			({ id }) => id
		);

		const newItem = this.item.getOtherVersion(version);

		if (!newItem) {
			return;
		}

		this.item = newItem;

		this.isPrintable = this.item.product.printable;

		this.price.assign(this.item.price);

		this.isPrintable = this.item.product.printable;

		this.modifiers = this.modifiers.filter(
			({ modifier: { id } }) => !defaultModifierIds.includes(id)
		);

		this.resetItem();
	}

	getComponentQuantity(
		componentId: number,
		includeOtherVersions: boolean = false
	): number {
		let quantity = 0;

		if (includeOtherVersions) {
			for (let i = 0; i < this.components.length; i++) {
				if (
					this.components[i].component.baseComponentId ===
					Component.getComponent(componentId)?.baseComponentId
				)
					quantity += this.components[i].quantity;
			}
		} else {
			for (let i = 0; i < this.components.length; i++) {
				if (this.components[i].component.id === componentId)
					quantity += this.components[i].quantity;
			}
		}

		return quantity;
	}

	getDiscountAmount(): Money {
		throw new Error('Method not implemented.');
	}

	serialize(): Serialized.TransItem {
		return {
			id: this.id,
			price: this.price.valueOf(),
			taxRate: this.taxRate,
			item: this.item.serialize(),
			modifiers: this.modifiers.map(modifier => modifier.serialize()),
			components: this.components.map(transComponent =>
				transComponent.serialize()
			)
		};
	}
}
