import { defined } from '../utils';
import { Serialized } from '../types';

import {
	InventoryItem,
	Item,
	Product,
	ProductGroup,
	Component,
	ComponentGroup,
	Unit,
	Size,
	ProductVersion,
	ModifierScale,
	ModifierGroup,
	Modifier,
	Container,
	ComponentGroupRatio,
	ComponentGroupRatioType,
	ComponentQualifier,
	ComponentVersion,
	ItemQualifier,
	ModifierFunction
} from '../lib';

/**
 * Gets an item by id.
 * @deprecated The return value of this function will possibly be undefined in 2.0.0.
 * @param itemId id of the item to get
 */
export const getItem = async (itemId: number): Promise<Serialized.Item> => {
	const item = Item.getItemById(itemId)?.serialize();

	if (!item) {
		throw new Error(`Item ${itemId} does not exist.`);
	}

	return item;
};

export const getProduct = (id: number) => Product.getProduct(id)?.serialize();
export const getProductGroup = (id: number) =>
	ProductGroup.getProductGroup(id)?.serialize();
export const getComponent = (id: number) =>
	Component.getComponent(id)?.serialize();
export const getComponentGroup = (id: number) =>
	ComponentGroup.getComponentGroup(id)?.serialize();
export const getUnit = (id: number) => Unit.getUnit(id)?.serialize();
export const getSize = (id: number) => Size.getSize(id)?.serialize();
export const getProductVersion = (id: number) =>
	ProductVersion.getProductVersion(id)?.serialize();

export const getModifierScale = (id: number) =>
	ModifierScale.getModifierScale(id)?.serialize();

export const getModifierGroup = (id: number) =>
	ModifierGroup.getModifierGroup(id)?.serialize();

export const getModifier = (id: number) =>
	Modifier.getModifier(id)?.serialize();
export const getInventoryItem = (id: number) =>
	InventoryItem.getInventoryItem(id)?.serialize();

export const getContainer = (id: number) =>
	Container.getContainer(id)?.serialize();

export const getComponentGroupRatio = (id: number) =>
	ComponentGroupRatio.getComponentGroupRatio(id)?.serialize();

export const getComponentGroupRatioType = (id: number) =>
	ComponentGroupRatioType.getComponentGroupRatioType(id)?.serialize();

export const getComponentQualifier = (id: number) =>
	ComponentQualifier.getComponentQualifier(id)?.serialize();

export const getComponentVersion = (id: number) =>
	ComponentVersion.getComponentVersion(id)?.serialize();

export const getItemQualifier = (id: number) =>
	ItemQualifier.getItemQualifier(id)?.serialize();

export const getModifierFunction = (id: number) =>
	ModifierFunction.getModifierFunction(id)?.serialize();

export const getItems = () =>
	Object.values(Item.items)
		.filter(defined)
		.filter(Item.isItem)
		.map(x => x.serialize());

export const getProducts = () =>
	Object.values(Product.products)
		.filter(defined)
		.filter(Product.isProduct)
		.map(x => x.serialize());

export const getProductGroups = () =>
	Object.values(ProductGroup.productGroups)
		.filter(defined)
		.filter(ProductGroup.isProductGroup)
		.map(x => x.serialize());

export const getComponents = () =>
	Object.values(Component.components)
		.filter(defined)
		.filter(Component.isComponent)
		.map(x => x.serialize());

export const getComponentGroups = () =>
	Object.values(ComponentGroup.componentGroups)
		.filter(defined)
		.filter(ComponentGroup.isComponentGroup)
		.map(x => x.serialize());

export const getUnits = () =>
	Object.values(Unit.units)
		.filter(defined)
		.filter(Unit.isUnit)
		.map(x => x.serialize());

export const getSizes = () =>
	Object.values(Size.sizes)
		.filter(defined)
		.filter(Size.isSize)
		.map(x => x.serialize());

export const getProductVersions = () =>
	Object.values(ProductVersion.productVersions)
		.filter(defined)
		.filter(ProductVersion.isProductVersion)
		.map(x => x.serialize());

export const getModifierScales = () =>
	Object.values(ModifierScale.modifierScales)
		.filter(defined)
		.filter(ModifierScale.isModifierScale)
		.map(x => x.serialize());

export const getModifierGroups = () =>
	Object.values(ModifierGroup.modifierGroups)
		.filter(defined)
		.filter(ModifierGroup.isModifierGroup)
		.map(x => x.serialize());

export const getModifiers = () =>
	Object.values(Modifier.modifiers)
		.filter(defined)
		.filter(Modifier.isModifier)
		.map(x => x.serialize());

export const getInventoryItems = () =>
	Object.values(InventoryItem.inventoryItems)
		.filter(defined)
		.filter(InventoryItem.isInventoryItem)
		.map(x => x.serialize());

export const getContainers = () =>
	Object.values(Container.containers)
		.filter(defined)
		.filter(Container.isContainer)
		.map(x => x.serialize());

export const getComponentGroupRatios = () =>
	Object.values(ComponentGroupRatio.componentGroupRatios)
		.filter(defined)
		.filter(ComponentGroupRatio.isComponentGroupRatio)
		.map(x => x.serialize());

export const getComponentGroupRatioTypes = () =>
	Object.values(ComponentGroupRatioType.componentGroupRatioTypes)
		.filter(defined)
		.filter(ComponentGroupRatioType.isComponentGroupRatioType)
		.map(x => x.serialize());

export const getComponentQualifiers = () =>
	Object.values(ComponentQualifier.componentQualifiers)
		.filter(defined)
		.filter(ComponentQualifier.isComponentQualifier)
		.map(x => x.serialize());

export const getComponentVersions = () =>
	Object.values(ComponentVersion.componentVersions)
		.filter(defined)
		.filter(ComponentVersion.isComponentVersion)
		.map(x => x.serialize());

export const getItemQualifiers = () =>
	Object.values(ItemQualifier.itemQualifiers)
		.filter(defined)
		.filter(ItemQualifier.isItemQualifier)
		.map(x => x.serialize());

export const getModifierFunctions = () =>
	Object.values(ModifierFunction.modifierFunctions)
		.filter(defined)
		.filter(ModifierFunction.isModifierFunction)
		.map(x => x.serialize());
