import { Defined, NumericDictionary, Serializable } from '../types';

import { defined } from '.';

export const getter = <T, U, V extends Serializable<T>>(
	items: NumericDictionary<V | U | undefined>,
	isFn: (item: Defined<V | U>) => item is Defined<V>
) => async (): Promise<T[]> =>
	Object.values(items)
		.filter(defined)
		.filter(isFn)
		.map(item => item.serialize());

export const getOne = <T>(
	get: (id: number) => Serializable<T> | undefined
): ((id: number) => Promise<T | undefined>) => async id => {
	const t = get(id);

	return t?.serialize();
};
