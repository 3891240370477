import { Serialized, Serializable } from '../types';

const SCALE_UP_FACTOR = 10_000;
const SCALE_DOWN_FACTOR = 1 / SCALE_UP_FACTOR;
const ROUND_FACTOR = 100;

type qreal = number;
type qint32 = number;

export default class Money implements Serializable<Serialized.Money> {
	centHundredths: qint32;
	precision: qint32;

	/**
	 * Perform addition on Money.
	 * Use this where you would otherwise have used "money + value".
	 * @param augend Left-hand side of the additon operation.
	 * @param addend Right-hand side of the addition operation.
	 */
	static add(augend: Money, addend: Money | qreal): Money {
		if (addend instanceof Money) {
			return this.Money(augend.centHundredths + addend.centHundredths);
		}

		return new Money(augend).add(addend);
	}

	/**
	 * Perform subtraction on Money.
	 * Use this where you would otherwise have used "money - value".
	 * @param minuend Left-hand side of the subtraction operation.
	 * @param subtrahend Right-hand side of the subtraction operation.
	 */
	static subtract(minuend: Money, subtrahend: Money | qreal): Money {
		if (subtrahend instanceof Money) {
			return this.Money(
				minuend.centHundredths - subtrahend.centHundredths
			);
		}

		return new Money(minuend).subtract(subtrahend);
	}

	/**
	 * Perform division on Money.
	 * Use this where you would otherwise have used "money / value".
	 * @param dividend Left-hand side of the division operation. Numerator.
	 * @param divisor Right-hand side of the division operation. Denominator.
	 */
	static divide(dividend: Money, divisor: qint32 | qreal): Money {
		return new Money(
			(dividend.centHundredths * SCALE_DOWN_FACTOR) / divisor
		);
	}

	/**
	 * Perform multiplication on Money.
	 * Use this where you would otherwise have used "money * value".
	 * @param multiplicand Left-hand side of the multiplication operation.
	 * @param multiplier Right-hand side of the multiplication operation.
	 */
	static multiply(multiplicand: Money, multiplier: qint32 | qreal): Money {
		return new Money(
			multiplicand.centHundredths * SCALE_DOWN_FACTOR * multiplier
		);
	}

	/**
	 * Check equality for Money. Returns true if money1 and money2 are equal.
	 * Use this where you would otherwise have used "money1 === money2".
	 * @param money1 Money.
	 * @param money2 Money or real number.
	 */
	static equal(money1: Money, money2: Money | qreal | qint32): boolean {
		if (money2 instanceof Money) {
			return (
				Math.round(money1.centHundredths / ROUND_FACTOR) ===
				Math.round(money2.centHundredths / ROUND_FACTOR)
			);
		}

		return (
			Math.round(money1.centHundredths / ROUND_FACTOR) ===
			Math.round(money2 * ROUND_FACTOR)
		);
	}

	/**
	 * Check inequality for Money. Returns true if money1 and money2 are not
	 * equal.
	 * Use this where you would otherwise have used "money1 !== money2".
	 * @param money1 Money.
	 * @param money2 Money or real number.
	 */
	static notEqual(money1: Money, money2: Money | qreal | qint32): boolean {
		if (money2 instanceof Money) {
			return (
				Math.round(money1.centHundredths / ROUND_FACTOR) !==
				Math.round(money2.centHundredths / ROUND_FACTOR)
			);
		}

		return (
			Math.round(money1.centHundredths / ROUND_FACTOR) !==
			Math.round(money2 * ROUND_FACTOR)
		);
	}

	/**
	 * Compare Money. Returns true if money1 is greater than money2.
	 * Use this where you would otherwise have used "money1 > money2".
	 * @param money1 Money.
	 * @param money2 Money or real number.
	 */
	static greaterThan(money1: Money, money2: Money | qreal | qint32): boolean {
		if (money2 instanceof Money) {
			return (
				Math.round(money1.centHundredths / ROUND_FACTOR) >
				Math.round(money2.centHundredths / ROUND_FACTOR)
			);
		}

		return (
			Math.round(money1.centHundredths / ROUND_FACTOR) >
			Math.round(money2 * ROUND_FACTOR)
		);
	}

	/**
	 * Compare Money. Returns ture if money1 is greater than or equal to money2.
	 * Use this where you would otherwise have used "money1 >= money2".
	 * @param money1 Money.
	 * @param money2 Money or real number.
	 */
	static greaterThanOrEqual(
		money1: Money,
		money2: Money | qreal | qint32
	): boolean {
		if (money2 instanceof Money) {
			return (
				Math.round(money1.centHundredths / ROUND_FACTOR) >=
				Math.round(money2.centHundredths / ROUND_FACTOR)
			);
		}

		return (
			Math.round(money1.centHundredths / ROUND_FACTOR) >=
			Math.round(money2 * ROUND_FACTOR)
		);
	}

	/**
	 * Compare Money. Returns true if money1 is less than money2.
	 * Use this where you would otherwise have used "money1 < money2".
	 * @param money1 Money.
	 * @param money2 Money or real number.
	 */
	static lessThan(money1: Money, money2: Money | qreal | qint32): boolean {
		if (money2 instanceof Money) {
			return (
				Math.round(money1.centHundredths / ROUND_FACTOR) <
				Math.round(money2.centHundredths / ROUND_FACTOR)
			);
		}

		return (
			Math.round(money1.centHundredths / ROUND_FACTOR) <
			Math.round(money2 * ROUND_FACTOR)
		);
	}

	/**
	 * Compare Money. Returns true if money1 is less than or equal to money2.
	 * Use this where you would otherwise have used "money1 <= money2".
	 * @param money1 Money.
	 * @param money2 Money or real number.
	 */
	static lessThanOrEqual(
		money1: Money,
		money2: Money | qreal | qint32
	): boolean {
		if (money2 instanceof Money) {
			return (
				Math.round(money1.centHundredths / ROUND_FACTOR) <=
				Math.round(money2.centHundredths / ROUND_FACTOR)
			);
		}

		return (
			Math.round(money1.centHundredths / ROUND_FACTOR) <=
			Math.round(money2 * ROUND_FACTOR)
		);
	}

	private static Money(centHundredths: qint32) {
		const money = new Money();
		money.centHundredths = centHundredths;

		return money;
	}

	constructor(money?: Money | qreal) {
		if (money instanceof Money) {
			this.precision = money.precision;
			this.centHundredths = money.centHundredths;

			return;
		}

		if (money) {
			this.precision = 2;
			this.centHundredths = Math.round(money * SCALE_UP_FACTOR);

			return;
		}

		this.precision = 2;
		this.centHundredths = 0;
	}

	/**
	 * Change the amount of money stored in this Money instance.
	 * Use this where you would otherwise have used "money = value".
	 * @param money the amount of money to add.
	 */
	assign(money: Money | qreal): Money {
		this.centHundredths =
			money instanceof Money
				? money.centHundredths
				: Math.round(money * SCALE_UP_FACTOR);

		return this;
	}

	/**
	 * Increase the amount of money stored in this Money instance.
	 * Use this where you would otherwise have used "money += value".
	 * @param addend the amount of money to add.
	 */
	add(addend: Money | qreal): Money {
		if (addend instanceof Money) {
			this.centHundredths += addend.centHundredths;
		} else {
			this.centHundredths += Math.round(addend * SCALE_UP_FACTOR);
		}

		return this;
	}

	/**
	 * Decrease the amount of money stored in this Money instance.
	 * Use this where you would otherwise have used "money -= value".
	 * @param subtrahend the amount of money to remove.
	 */
	subtract(subtrahend: Money | qreal): Money {
		if (subtrahend instanceof Money) {
			this.centHundredths -= subtrahend.centHundredths;
		} else {
			this.centHundredths -= Math.round(subtrahend * SCALE_UP_FACTOR);
		}

		return this;
	}

	/**
	 * Scale the amount of money stored in this Money instance.
	 * Use this where you would otherwise have used "money *= value".
	 * @param multiplier the factor to scale by.
	 */
	multiply(multiplier: qreal): Money {
		this.centHundredths = Math.round(this.centHundredths * multiplier);

		return this;
	}

	/**
	 * Returns the amount of money stored in this Money instance scaled by the
	 * given factor.
	 * @param percentage Scale factor.
	 */
	multiplyByPercent(percentage: qreal): qreal {
		return Math.round(this.centHundredths * percentage) / SCALE_UP_FACTOR;
	}

	percentOf(money: Money): qreal {
		return this.centHundredths * (1.0 / money.centHundredths);
	}

	/**
	 * Round the value of money stored in this Money instance to the nearest
	 * cent.
	 */
	round(): void {
		this.centHundredths =
			Math.round(this.centHundredths / ROUND_FACTOR) * ROUND_FACTOR;
	}

	toString(includeCurrencySymbol = false): string {
		const value = (this.centHundredths / SCALE_UP_FACTOR).toFixed(
			this.precision
		);

		if (includeCurrencySymbol) {
			return '$' + value;
		}

		return value;
	}

	/**
	 * Convert Money to a real number.
	 */
	valueOf(): qreal {
		return this.centHundredths / SCALE_UP_FACTOR;
	}

	/**
	 * Serialize for sending.
	 */
	serialize(): Serialized.Money {
		return {
			centHundredths: this.centHundredths,
			precision: this.precision
		};
	}
}
