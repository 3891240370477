import { Data } from '../types';

import { loadItem, serializeModels } from '../utils';

import {
	TransItem,
	ModifierGroupModel,
	ModifierScaleModel,
	ProductVersionModel,
	SizeModel
} from '../lib';

export const selectModifier = async (
	modifierGroupIndex: number,
	modifierIndex: number
): Promise<Data> => {
	console.log('selectModifier');
	console.time('selectModifier: time');

	const modifierGroupModel = ModifierGroupModel.modifierGroupModel;

	modifierGroupModel.modifierModels[modifierGroupIndex].selectModifier(
		modifierIndex
	);

	loadItem();

	console.timeEnd('selectModifier: time');

	const data = serializeModels();

	console.log('SizeModel.sizeModel', SizeModel.sizeModel);
	console.log(
		'ProductVersionModel.productVersionModel',
		ProductVersionModel.productVersionModel
	);
	console.log(
		'ModifierGroupModel.modifierGroupModel',
		ModifierGroupModel.modifierGroupModel
	);
	console.log(
		'ModifierScaleModel.modifierScaleModel',
		ModifierScaleModel.modifierScaleModel
	);
	console.log('TransItem.transItem', TransItem.transItem);

	console.log('data', data);

	return data;
};
