import {
	UnlinkedItem as UnlinkedItemInterface,
	UnlinkedIngredient,
	Database,
	LinkedItem,
	Serializable,
	Serialized,
	FoamStatus,
	NumericDictionary
} from '../types';

import {
	Size,
	Product,
	Component,
	ComponentGroup,
	TransItem,
	ComponentQualifier,
	Container,
	Config,
	ProductVersion
} from '.';

export class UnlinkedItem implements UnlinkedItemInterface {
	readonly linked = false;
	isValid = false;

	itemId: number;
	productId: number;
	sizeId?: number;
	isVariablePrice: boolean;
	containerId?: number;
	capacity?: number;

	recipe: UnlinkedIngredient[];
	digitalPrice: number;

	constructor(
		itemFields: Database.MappedItemsRow,
		tables: Database.MappedTables
	) {
		this.itemId = itemFields.itemId;
		this.productId = itemFields.productId;
		this.sizeId = itemFields.sizeId;
		this.isVariablePrice = itemFields.isVariablePrice;
		this.containerId = itemFields.containerId;
		this.capacity = itemFields.capacity;

		this.recipe = tables.itemsComponents
			.filter(({ itemId }) => itemId === this.itemId)
			.sort(
				(a, b) =>
					a.componentId - b.componentId ||
					a.componentGroupId - b.componentGroupId
			);

		this.digitalPrice = 0;

		this.isValid = true;
	}
}

type LinkedIngredient = UnlinkedIngredient & {
	component?: Component;
	componentGroup?: ComponentGroup;
};

export default class Item implements LinkedItem, Serializable<Serialized.Item> {
	readonly linked = true;

	id: number;

	itemId: number;
	productId: number;
	sizeId?: number;
	isVariablePrice: boolean;
	containerId?: number;
	capacity?: number;

	digitalPrice: number;
	recipe: LinkedIngredient[] = [];

	product: Product;
	size?: Size;
	container?: Container;
	liquidBaseComponents: Component[] = [];
	componentQualifiers: ComponentQualifier[] = [];

	static initialized = false;
	static tables: Database.MappedTables;
	static invalidItemIds: number[] = [];
	static itemIds: number[] = [];
	static items: NumericDictionary<Item | UnlinkedItem | undefined> = {};

	static isItem(item: UnlinkedItem | Item): item is Item {
		return item.linked;
	}

	static getItemById(itemId: number): Item | undefined {
		const item = this.items[itemId];

		if (item) {
			if (this.isItem(item)) {
				return item;
			}

			return new Item(item);
		}
	}

	static async initialize(tables: Database.MappedTables) {
		this.initialized = false;
		this.itemIds = [];
		this.items = {};

		Item.tables = tables;

		for (const row of tables.items) {
			const item = new UnlinkedItem(row, tables);

			if (item.isValid) {
				this.itemIds.push(item.itemId);
				this.items[item.itemId] = item;
			}
		}

		if (this.itemIds.length > 0) this.initialized = true;

		return this.initialized;
	}

	static async linkAll() {
		for (const item of Object.values(Item.items)) {
			if (item) {
				new Item(item);
			}
		}

		return true;
	}

	constructor(item: UnlinkedItem | Item) {
		Item.items[item.itemId] = this;

		this.id = item.itemId;

		this.itemId = item.itemId;
		this.productId = item.productId;
		this.sizeId = item.sizeId;
		this.isVariablePrice = item.isVariablePrice;
		this.containerId = item.containerId;
		this.capacity = item.capacity;

		// this.price = item.price;
		this.digitalPrice = item.digitalPrice;

		if (item.linked) {
			this.recipe = item.recipe;

			this.product = item.product;
			this.size = item.size;
			this.container = item.container;
			this.liquidBaseComponents = item.liquidBaseComponents;
			this.componentQualifiers = item.componentQualifiers;
		} else {
			const product = Product.getProduct(this.productId);

			if (!product) {
				throw new Error(
					'Failed to link item to product ' + this.productId
				);
			}

			this.product = product;

			if (this.sizeId) {
				const size = Size.getSize(this.sizeId);

				if (!size) {
					throw new Error('Failed to link item to size');
				}

				this.size = size;
			}

			if (this.containerId) {
				const container = Container.getContainer(this.containerId);

				if (!container) {
					throw new Error('Failed to link item to container');
				}

				this.container = container;
			}

			const recipe: LinkedIngredient[] = [];

			for (const ingredient of item.recipe) {
				let component: Component | undefined;
				let componentGroup: ComponentGroup | undefined;

				if (ingredient.componentId) {
					component = Component.getComponent(ingredient.componentId);

					if (!component) {
						throw new Error(
							'Failed to link ingredient to component'
						);
					}

					if (
						component.componentGroups.some(
							({ id }) => id === Config.liquidBase
						)
					) {
						this.liquidBaseComponents.push(component);
					}
				}

				if (ingredient.componentGroupId) {
					componentGroup = ComponentGroup.getComponentGroup(
						ingredient.componentGroupId
					);

					if (!componentGroup) {
						throw new Error(
							'Failed to link ingredient to component group'
						);
					}
				}

				if (!component && !componentGroup) {
					throw new Error('Failed to link item to ingredient');
				}

				recipe.push({
					...ingredient,
					component,
					componentGroup
				});
			}

			this.recipe = recipe;

			const allCompQuals: ComponentQualifier[] = ComponentQualifier.getAllComponentQualifiers();

			for (const compQual of allCompQuals) {
				if (compQual.oldItem === this.itemId) {
					this.componentQualifiers.push(compQual);
				}
			}
		}
	}

	static getItem(
		transItem: TransItem,
		ingredients: Component[],
		componentGroups: ComponentGroup[],
		size: Size | undefined,
		existingItem: Item
	): Item | undefined {
		// console.log('Item: getItem');

		const existingItemRecipe = existingItem.recipe;
		const existingItemComponentGroups: ComponentGroup[] = [];

		for (const ingredient of existingItemRecipe) {
			if (ingredient.componentGroup) {
				existingItemComponentGroups.push(ingredient.componentGroup);
			}
		}

		console.time('Item: getItem: possibleItems: time');
		const possibleItems = Object.values(Item.items).filter(possibleItem => {
			if (!possibleItem || !possibleItem.linked) return false;

			if (possibleItem.product) {
				if (!possibleItem.product.isVisible) {
					return false;
				}
			}

			if (possibleItem.size !== undefined && size !== undefined) {
				if (possibleItem.size.id !== size.id) {
					return false;
				}
			} else if (
				!(possibleItem.size === undefined && size === undefined)
			) {
				return false;
			}

			if (
				possibleItem.product &&
				possibleItem.product.productVersion &&
				possibleItem.product.productVersions.length === 3
			) {
				return true;
			}

			const recipeList = possibleItem.recipe;
			const liquidBaseComponents = existingItem.liquidBaseComponents;

			let sameLiquidBaseCount = 0;

			for (const liquidBaseComponent of liquidBaseComponents) {
				for (const ingredient of recipeList) {
					if (
						ingredient.component &&
						ingredient.component.id === liquidBaseComponent.id
					) {
						++sameLiquidBaseCount;
					}
				}
			}

			if (
				sameLiquidBaseCount === 0 &&
				liquidBaseComponents.length !== 0
			) {
				return false;
			}

			const existingItemRecipe = existingItem.recipe;
			const existingItemComponentGroups: ComponentGroup[] = [];

			for (const ingredient of existingItemRecipe) {
				if (ingredient.componentGroup) {
					existingItemComponentGroups.push(
						new ComponentGroup(ingredient.componentGroup)
					);
				}
			}

			if (existingItemComponentGroups.length === 0) {
				return true;
			}

			let sameComponentGroupCount = 0;

			for (const componentGroup of existingItemComponentGroups) {
				for (const ingredient of recipeList) {
					if (ingredient.componentGroup) {
						if (
							ingredient.componentGroup.id === componentGroup.id
						) {
							++sameComponentGroupCount;
						}
					}
				}
			}

			if (sameComponentGroupCount === 0) {
				return false;
			}

			return true;
		}) as Item[];

		console.timeEnd('Item: getItem: possibleItems: time');
		let maxCommonness = 0;
		let bestItems: Item[] = [];
		const allItems: [Item, number][] = [];

		console.log(
			'Item: getItem: possibleItems.length',
			possibleItems.length
		);

		console.time('Item: getItem: getCommonnesses: time');
		const commonnesses: [
			number,
			Item
		][] = possibleItems.map(itemToCompare => [
			this.getCommonness(itemToCompare, ingredients, componentGroups),
			itemToCompare
		]);
		console.timeEnd('Item: getItem: getCommonnesses: time');

		for (const [commonness, itemToCompare] of commonnesses) {
			if (commonness > 0) allItems.push([itemToCompare, commonness]);

			if (commonness === maxCommonness) {
				bestItems.push(itemToCompare);
			} else if (commonness > maxCommonness) {
				maxCommonness = commonness;
				bestItems = [itemToCompare];
			}
		}

		if (maxCommonness === 0) {
			bestItems = [];
		}

		// Find any static ingredients in current Item
		const staticIngredients = ingredients.filter(
			ingredient => ingredient.static
		);

		// Eliminate items that have static Components that are not in ingredients
		// OR don't have static components that are in ingredients
		bestItems = bestItems
			.filter(({ recipe: bestIngredients }) => {
				let match = false;

				const bestStaticIngredients = bestIngredients
					.filter(
						ingredient =>
							ingredient.component && ingredient.component.static
					)
					.map(ingredient => ingredient.component!);

				const isStaticIngredientsSmaller =
					staticIngredients.length < 1 &&
					bestStaticIngredients.length > 1;
				const isStaticIngredientsBigger =
					staticIngredients.length > 1 &&
					bestStaticIngredients.length < 1;

				if (isStaticIngredientsSmaller || isStaticIngredientsBigger) {
					return false;
				} else {
					for (const bestStaticIngredient of bestStaticIngredients) {
						match = false;

						for (const staticIngredient of staticIngredients) {
							const sameComponent =
								bestStaticIngredient.id === staticIngredient.id;
							const sameBaseComponent =
								bestStaticIngredient.baseComponentId ===
								staticIngredient.baseComponentId;

							if (sameComponent || sameBaseComponent) {
								match = true;

								break;
							}
						}

						if (!match) {
							break;
						}
					}

					if (
						!match &&
						bestStaticIngredients.length > 0 &&
						staticIngredients.length > 0
					) {
						return false;
					}
				}

				return true;
			})
			.filter(bestItem => {
				const existingCQs = existingItem.componentQualifiers;

				for (const componentQualifier of existingCQs) {
					if (
						bestItem.id === componentQualifier.newItem &&
						!componentQualifier.qualifies(transItem)
					) {
						return false;
					}
				}

				return true;
			});

		if (
			bestItems.length < 1 ||
			bestItems.find(item => item.id === existingItem.id) !== undefined
		) {
			return undefined;
		}

		return bestItems[0];
	}

	static getCommonness(
		item: Item,
		ingredients: Component[],
		componentGroups: ComponentGroup[]
	): number {
		let weightedCommonIngredients = 0;
		let weightedRecipeSize = 0;

		let { recipe } = item;

		// Loop through all existing ingredients
		for (const { baseComponentId } of ingredients) {
			// Compare each ingredient with the recipe's ingredients
			const ingredientComparison = <T>(
				ingredient: LinkedIngredient,
				cb: (result: boolean) => T
			): T => {
				if (
					ingredient.componentGroup &&
					ingredient.componentGroup.containsComponent(baseComponentId)
				) {
					return cb(false);
				} else if (
					ingredient.component &&
					ingredient.component.baseComponentId === baseComponentId
				) {
					return cb(false);
				}

				return cb(true);
			};

			weightedCommonIngredients = recipe.reduce((acc, ingredient) => {
				return (
					acc +
					ingredientComparison<number>(ingredient, result => {
						return result ? 0 : ingredient.relevance;
					})
				);
			}, weightedCommonIngredients);
			recipe = recipe.filter(ingredient =>
				ingredientComparison<boolean>(ingredient, result => result)
			);
		}

		for (const componentGroup of componentGroups) {
			const componentGroupComparison = <T>(
				ingredient: LinkedIngredient,
				cb: (result: boolean) => T
			): T => {
				if (ingredient.componentGroup?.id === componentGroup.id) {
					return cb(false);
				}

				return cb(true);
			};

			weightedCommonIngredients = recipe.reduce(
				(acc, ingredient) =>
					acc +
					componentGroupComparison<number>(ingredient, result => {
						return result ? 0 : ingredient.relevance;
					}),
				weightedCommonIngredients
			);
			recipe = recipe.filter(ingredient =>
				componentGroupComparison<boolean>(ingredient, result => result)
			);
		}

		if (recipe.length > 0) {
			for (const { component } of recipe) {
				if (component && component.static) {
					return 0;
				}
			}
		}

		weightedRecipeSize = item.recipe.reduce(
			(acc, { relevance }) => acc + relevance,
			weightedRecipeSize
		);

		const ratioSquared =
			weightedRecipeSize === 0
				? Infinity
				: (weightedCommonIngredients / weightedRecipeSize) ** 2;

		const result =
			weightedCommonIngredients === 0
				? 0
				: ratioSquared * weightedCommonIngredients;

		return result;
	}

	get foamStatus(): FoamStatus | undefined {
		let foamStatus: FoamStatus | undefined;

		const foam = ComponentGroup.getComponentGroup(Config.milkFoam);

		if (!foam) {
			throw new Error('Could not find foam component group');
		}

		for (const ingredient of this.recipe) {
			if (foam.containsComponent(ingredient.componentId)) {
				if (this.productId === Config.cappuccinoProductId) {
					foamStatus = 'CAPPUCCINO';
				} else {
					foamStatus = 'LATTE';
				}
			}
		}

		return foamStatus;
	}

	get price() {
		const price = Item.tables.itemPrices
			.filter(
				itemPrice =>
					itemPrice.itemId === this.itemId &&
					(itemPrice.storeId === 0 ||
						itemPrice.storeId === Config.storeId) &&
					(itemPrice.tierId === 0 ||
						itemPrice.tierId === Config.tierId)
			)
			.sort((a, b) => b.storeId - a.storeId || b.tierId - a.tierId)[0];

		if (!price) {
			throw new Error('Failed to get price for item ' + this.id);
		}

		return price.price;
	}

	inRecipe(
		component: Component,
		allowOtherVersions: boolean,
		allowComponentGroups: boolean
	): boolean {
		// console.log('Item: inRecipe');
		if (allowOtherVersions) {
			for (const ingredient of this.recipe) {
				if (ingredient.componentGroup && allowComponentGroups) {
					const thisComponentGroup = ingredient.componentGroup;

					if (thisComponentGroup.containsComponent(component.id)) {
						return true;
					}
				} else if (ingredient.component) {
					const thisComponent = Component.getComponent(
						ingredient.component.baseComponentId
					);

					if (thisComponent?.id === component.id) {
						return true;
					}
				}
			}
		} else {
			for (const ingredient of this.recipe) {
				if (ingredient.componentGroup && allowComponentGroups) {
					const thisComponentGroup = new ComponentGroup(
						ingredient.componentGroup
					);

					if (thisComponentGroup.containsComponent(component.id)) {
						return true;
					}
				} else if (ingredient.component) {
					if (ingredient.component.id === component.id) {
						return true;
					}
				}
			}
		}

		return false;
	}

	componentGroupInRecipe(componentGroup: ComponentGroup): boolean {
		// console.log('Item: componentGroupInRecipe');
		return this.recipe.some(
			({ componentGroup: group }) =>
				group !== undefined && group.id === componentGroup.id
		);
	}

	getQuantity(componentId: number): number {
		// console.log('Item: getQuantity');
		for (const ingredient of this.recipe) {
			if (ingredient.componentGroup) {
				if (ingredient.componentGroup.containsComponent(componentId)) {
					return ingredient.quantity;
				}
			} else if (ingredient.component) {
				if (ingredient.component.id === componentId) {
					return ingredient.quantity;
				}
			}
		}

		return 0;
	}

	getOtherVersion(productVersion: ProductVersion): Item | undefined {
		let newItem: Item;

		const otherVersion = this.product.getOtherVersion(productVersion);

		if (!otherVersion) {
			return;
		}

		if (!this.size) {
			newItem = otherVersion.getItem();

			if (newItem) {
				return newItem;
			} else {
				return otherVersion.getItem(otherVersion.sizes[0]);
			}
		} else {
			const { sizes } = otherVersion;

			if (sizes.length === 0) {
				return otherVersion.getItem();
			} else {
				let [newSize] = sizes;

				for (let sizeIndex = 1; sizeIndex < sizes.length; ++sizeIndex) {
					if (this.size.priority >= sizes[sizeIndex].priority) {
						newSize = sizes[sizeIndex];
					}
				}

				return otherVersion.getItem(newSize);
			}
		}
	}

	serialize(): Serialized.Item {
		return {
			id: this.id,
			itemId: this.itemId,
			productId: this.productId,
			containerId: this.containerId,
			sizeId: this.sizeId,
			isVariablePrice: this.isVariablePrice,
			capacity: this.capacity,
			digitalPrice: this.digitalPrice,
			container: this.container?.serialize(),
			product: this.product.serialize(),
			size: this.size?.serialize(),
			componentQualifiers: this.componentQualifiers.map(
				componentQualifier => componentQualifier.serialize()
			),
			liquidBaseComponents: this.liquidBaseComponents.map(component =>
				component.serialize()
			),
			recipe: this.recipe.map(ingredient => ({
				quantity: ingredient.quantity,
				relevance: ingredient.relevance,
				component: ingredient.component?.serialize(),
				componentGroup: ingredient.componentGroup?.serialize()
			}))
		};
	}
}
