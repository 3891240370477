import {
	LinkedModifierGroup,
	NumericDictionary,
	Database,
	Serializable,
	Serialized,
	UnlinkedModifierGroup as UnlinkedModifierGroupInterface
} from '../types';

import { sortAlphabetical } from '../utils';

import { TransItem, Modifier, ModifierModel, ItemQualifier } from '.';

export class UnlinkedModifierGroup implements UnlinkedModifierGroupInterface {
	readonly linked = false;
	isValid = false;

	modifierGroupId: number;
	name: string;
	priority: number;
	min: number;
	max: number;
	isVisible: boolean;

	itemQualifierIds: number[];
	itemQualifiersIsOr: boolean[];
	modifierIds: number[];
	numPriorityModifiers: number;

	constructor(
		modifierGroupFields: Database.MappedModifierGroupsRow,
		tables: Database.MappedTables
	) {
		this.modifierGroupId = modifierGroupFields.modifierGroupId;
		this.name = modifierGroupFields.name;
		this.priority = modifierGroupFields.priority;
		this.min = modifierGroupFields.min;
		this.max = modifierGroupFields.max;
		this.isVisible = modifierGroupFields.isVisible;

		const thisFilter = ({ modifierGroupId }: { modifierGroupId: number }) =>
			modifierGroupId === this.modifierGroupId;

		const [
			itemQualifierIds,
			itemQualifiersIsOr
		] = tables.modifierGroupsItemQualifiers
			.filter(thisFilter)
			.reduce<[number[], boolean[]]>(
				(
					[itemQualifierIds, itemQualifiersIsOr],
					{ itemQualifierId, isOr }
				) => [
					[...itemQualifierIds, itemQualifierId],
					[...itemQualifiersIsOr, isOr]
				],
				[[], []]
			);

		this.itemQualifierIds = itemQualifierIds;
		this.itemQualifiersIsOr = itemQualifiersIsOr;

		this.modifierIds = tables.modifierGroupsModifiers
			.filter(
				modifierGroupModifier =>
					thisFilter(modifierGroupModifier) &&
					modifierGroupModifier.priority &&
					modifierGroupModifier.priority > 0
			)
			.sort((a, b) => a.priority! - b.priority!)
			.map(({ modifierId }) => modifierId);

		this.numPriorityModifiers = this.modifierIds.length + 0;

		const nonPriorityModifierIds = tables.modifierGroupsModifiers
			.filter(
				modifierGroupModifier =>
					thisFilter(modifierGroupModifier) &&
					(modifierGroupModifier.priority === undefined ||
						modifierGroupModifier.priority === null ||
						modifierGroupModifier.priority === 0)
			)
			.map(({ modifierId }) => {
				const modifier = tables.modifiers.find(
					modifier => modifier.modifierId === modifierId
				);

				if (!modifier) {
					throw new Error(
						'Failed to join modifiergroups_modifier to modifier'
					);
				}

				return {
					id: modifierId,
					name: modifier.name
				};
			})
			.sort((a, b) => sortAlphabetical(a.name, b.name))
			.map(({ id }) => id);

		this.modifierIds = [...this.modifierIds, ...nonPriorityModifierIds];

		this.isValid = true;
	}
}

export default class ModifierGroup
	implements LinkedModifierGroup, Serializable<Serialized.ModifierGroup> {
	readonly linked = true;

	id: number;

	modifierGroupId: number;
	name: string;
	priority: number;
	min: number;
	max: number;
	isVisible: boolean;

	itemQualifierIds: number[];
	itemQualifiersIsOr: boolean[];
	modifierIds: number[];
	numPriorityModifiers: number;

	itemQualifiers: ItemQualifier[] = [];
	modifiers: Modifier[] = [];
	visisbleModifiers: Modifier[] = [];

	static initialized = false;
	static modifierGroupIds: number[] = [];
	static modifierGroupIdsByPriority: number[] = [];
	static modifierGroups: NumericDictionary<
		ModifierGroup | UnlinkedModifierGroup | undefined
	> = {};

	static isModifierGroup(
		modifierGroup: UnlinkedModifierGroup | ModifierGroup
	): modifierGroup is ModifierGroup {
		return modifierGroup.linked;
	}

	static getModifierGroup(
		modifierGroupId: number
	): ModifierGroup | undefined {
		const modifierGroup = this.modifierGroups[modifierGroupId];

		if (modifierGroup) {
			if (this.isModifierGroup(modifierGroup)) {
				return modifierGroup;
			}

			return new ModifierGroup(modifierGroup);
		}
	}

	static async initialize(tables: Database.MappedTables) {
		this.initialized = false;
		this.modifierGroupIds = [];
		this.modifierGroups = {};

		for (const row of tables.modifierGroups) {
			const modifierGroup = new UnlinkedModifierGroup(row, tables);

			if (modifierGroup.isValid) {
				this.modifierGroupIds.push(modifierGroup.modifierGroupId);
				this.modifierGroups[
					modifierGroup.modifierGroupId
				] = modifierGroup;
			}
		}

		if (this.modifierGroupIds.length > 0) this.initialized = true;

		return this.initialized;
	}

	static async linkAll() {
		for (const modifierGroup of Object.values(
			ModifierGroup.modifierGroups
		)) {
			if (modifierGroup) {
				new ModifierGroup(modifierGroup);
			}
		}

		return true;
	}

	constructor(modifierGroup: ModifierGroup | UnlinkedModifierGroup) {
		ModifierGroup.modifierGroups[modifierGroup.modifierGroupId] = this;

		this.id = modifierGroup.modifierGroupId;

		this.modifierGroupId = modifierGroup.modifierGroupId;
		this.name = modifierGroup.name;
		this.priority = modifierGroup.priority;
		this.min = modifierGroup.min;
		this.max = modifierGroup.max;
		this.isVisible = modifierGroup.isVisible;

		this.itemQualifierIds = modifierGroup.itemQualifierIds;
		this.itemQualifiersIsOr = modifierGroup.itemQualifiersIsOr;
		this.modifierIds = modifierGroup.modifierIds;
		this.numPriorityModifiers = modifierGroup.numPriorityModifiers;

		if (modifierGroup.linked) {
			this.itemQualifiers = modifierGroup.itemQualifiers;
			this.modifiers = modifierGroup.modifiers;
			this.visisbleModifiers = modifierGroup.visisbleModifiers;
		} else {
			// link item qualifiers
			for (const id of this.itemQualifierIds) {
				const itemQualifier = ItemQualifier.getItemQualifier(id);

				if (!itemQualifier) {
					throw new Error(
						'Failed to link modifier to item qualifier'
					);
				}

				this.itemQualifiers.push(itemQualifier);
			}

			// link modifiers
			for (const id of this.modifierIds) {
				const modifier = Modifier.getModifier(id);

				if (!modifier) {
					throw new Error(
						'Failed to link modifier group ' +
							this.id +
							' to modifier ' +
							id
					);
				}

				this.modifiers.push(modifier);

				if (modifier.isVisible) {
					this.visisbleModifiers.push(modifier);
				}
			}
		}
	}

	static getModifierGroups(transItem: TransItem): ModifierGroup[] {
		const output: ModifierGroup[] = [];

		for (const group of Object.values(this.modifierGroups)) {
			if (
				group &&
				group.linked &&
				group.isVisible &&
				group.qualifies(transItem)
			) {
				output.push(group);
			}
		}

		return output;
	}

	qualifies(transItem: TransItem): boolean {
		if (this.itemQualifiers.length < 1) {
			return true;
		}

		let orQualifies: boolean = false;
		let orExists: boolean = false;

		for (const index in this.itemQualifiers) {
			const qualifies: boolean = this.itemQualifiers[index].qualifies(
				transItem
			);

			if (this.itemQualifiersIsOr[index]) {
				orExists = true;

				if (qualifies) orQualifies = true;
			} else if (!qualifies) return false;
		}

		if (!orQualifies && orExists) return false;

		return true;
	}

	hasModifier(modifierId: number): boolean {
		return this.modifiers.some(({ id }) => id === modifierId);
	}

	serialize(): Serialized.ModifierGroup {
		return {
			id: this.id,
			name: this.name,
			priority: this.priority
		};
	}
}

export class ModifierGroupModel
	implements Serializable<Serialized.ModifierGroupModel> {
	transItem: TransItem;
	modifierModels: ModifierModel[] = [];
	modifierGroups: ModifierGroup[] = [];

	static modifierGroupModel: ModifierGroupModel;

	constructor(transItem: TransItem) {
		ModifierGroupModel.modifierGroupModel = this;

		this.transItem = transItem;

		this.setTransItem(transItem);
	}

	setTransItem(transItem: TransItem): void {
		this.modifierModels = [];

		const newModifierGroups = ModifierGroup.getModifierGroups(transItem);
		const modGroups: ModifierGroup[] = [];

		for (const modifierGroup of newModifierGroups) {
			const model = new ModifierModel(
				transItem,
				modifierGroup.visisbleModifiers
			);

			if (!model) continue;

			if (model.rowCount > 0) {
				this.modifierModels.push(model);
				modGroups.push(modifierGroup);
			} else {
				// alert('Group ' + modifierGroup.name + ' has now rows');
			}
		}

		this.modifierGroups = modGroups;
	}

	serialize() {
		return {
			modifierModels: this.modifierModels.map(modifierModel =>
				modifierModel.serialize()
			),
			modifierGroups: this.modifierGroups.map(modifierGroup =>
				modifierGroup.serialize()
			)
		};
	}
}
