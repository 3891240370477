import { Data } from '../types';

import { loadItem, serializeModels } from '../utils';

import {
	TransItem,
	ModifierGroupModel,
	ModifierScaleModel,
	ProductVersionModel,
	SizeModel
} from '../lib';

export const changeSize = async (index: number): Promise<Data> => {
	console.log('changeSize');
	console.time('changeSize: time');

	// const oldItemId = transItem.item.id;

	// const modifierIds: number[] = transItem.modifiers.map(
	// 	transModifier => transModifier.modifier.id
	// );

	// const oldSize = transItem.item.size;

	const sizeModel = SizeModel.sizeModel;
	const size = sizeModel.sizes[index];

	sizeModel.selectSize(size);

	console.log(
		'TransItem.transItem.item.id before size change' +
			TransItem.transItem.item.id
	);

	TransItem.transItem.changeSize(size);

	loadItem();

	console.log(
		'TransItem.transItem.item.id after size change' +
			TransItem.transItem.item.id
	);

	// TODO: implement
	// Display Liquid Base Message if capacity is reached and revert item
	// if(transItem_->getLiquidBaseMessageStatus())
	// {
	// 	transItem_->resetLiquidBaseMessageStatus();
	// 	ui_->popupMessage("Ingredients don't fit into the selected size.");
	// 	transItem_->changeSize(oldSize);
	// 	transItem_->resetItemAndClearModifiers();
	// 	for(qint32 i = 0; i < modifierIds.size(); i++)
	// 	{
	// 			transItem_->modify(Modifier::getModifier(modifierIds.at(i)));
	// 	}
	// 	loadItem();
	// }

	// const newItemId = transItem.item.itemId;

	console.timeEnd('changeSize: time');

	const data = serializeModels();

	console.log('SizeModel.sizeModel', SizeModel.sizeModel);
	console.log(
		'ProductVersionModel.productVersionModel',
		ProductVersionModel.productVersionModel
	);
	console.log(
		'ModifierGroupModel.modifierGroupModel',
		ModifierGroupModel.modifierGroupModel
	);
	console.log(
		'ModifierScaleModel.modifierScaleModel',
		ModifierScaleModel.modifierScaleModel
	);
	console.log('TransItem.transItem', TransItem.transItem);

	console.log('data', data);

	return data;
};
