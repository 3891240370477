import { Data } from '../types';

import { serializeModels } from '../utils';

import {
	Product,
	TransItem,
	ModifierGroupModel,
	ModifierScaleModel,
	ProductVersionModel,
	SizeModel,
	Transaction,
	TransItemModel
} from '../lib';

export const selectProduct = async (productId: number): Promise<Data> => {
	console.log('selectProduct');
	console.time('selectProduct: time');

	const sizes = new SizeModel();
	const productVersions = new ProductVersionModel();

	const product = Product.getProductOrThrow(productId);
	// ProductScreenHdlr::orderProduct(product)

	const itemToOrder = product.getItem(sizes.selectedSize);

	// OrderScreenHdlr::orderItem(itemToOrder)
	const transItem = new TransItem(itemToOrder);

	Transaction.transactions.getCurrentTransaction()?.addTransItem(transItem);
	TransItemModel.transItemModel.addItem(transItem);

	// ModifyScreenHdlr::setItem(transItem);
	new ModifierGroupModel(TransItem.transItem);
	new ModifierScaleModel(TransItem.transItem);

	if (
		TransItem.transItem.item.product.sizes.length > 0 &&
		TransItem.transItem.item.size
	) {
		sizes.sizes = TransItem.transItem.item.product.sizes;
		sizes.selectSize(TransItem.transItem.item.size);
	}

	if (TransItem.transItem.item.product.productVersion) {
		productVersions.productVersions = [
			...TransItem.transItem.item.product.productVersions,
			TransItem.transItem.item.product.productVersion
		];
		productVersions.selectProductVersion(
			TransItem.transItem.item.product.productVersion
		);
	}

	console.timeEnd('selectProduct: time');

	const data = serializeModels();

	console.log('SizeModel.sizeModel', SizeModel.sizeModel);
	console.log(
		'ProductVersionModel.productVersionModel',
		ProductVersionModel.productVersionModel
	);
	console.log(
		'ModifierGroupModel.modifierGroupModel',
		ModifierGroupModel.modifierGroupModel
	);
	console.log(
		'ModifierScaleModel.modifierScaleModel',
		ModifierScaleModel.modifierScaleModel
	);
	console.log('TransItem.transItem', TransItem.transItem);

	console.log('data', data);

	return data;
};
