import { v4 } from 'uuid';

import {
	Serializable,
	Serialized,
	TransModifier as TransModifierInterface
} from '../types';

import { Modifier } from '.';

import Money from './Money';

export default class TransModifier
	implements TransModifierInterface, Serializable<Serialized.TransModifier> {
	id: string;
	timeAdded: number;
	price: Money;
	quantity: number = 1;
	modifier: Modifier;

	constructor(modifier: Modifier, price: Money = new Money()) {
		this.id = v4();
		this.timeAdded = Date.now();
		this.price = price;
		this.modifier = modifier;
	}

	serialize = () => ({
		id: this.id,
		price: this.price.valueOf(),
		quantity: this.quantity,
		modifier: this.modifier.serialize()
	});
}
