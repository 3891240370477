import {
	LinkedComponentGroupRatioType,
	NumericDictionary,
	Database,
	UnlinkedComponentGroupRatioType as UnlinkedComponentGroupRatioTypeInterface,
	Serializable,
	Serialized
} from '../types';

import {
	ComponentGroup,
	ComponentGroupRatio,
	ItemQualifier,
	TransItem
} from '.';

export class UnlinkedComponentGroupRatioType
	implements UnlinkedComponentGroupRatioTypeInterface {
	readonly linked = false;
	isValid = false;

	componentGroupRatioTypeId: number;
	name: string;

	itemQualifierIds: number[];
	itemQualifiersIsOr: boolean[];
	componentGroupRatioIds: number[];
	constructor(
		componentGroupRatioTypeFields: Database.MappedComponentGroupRatioTypeRow,
		tables: Database.MappedTables
	) {
		this.componentGroupRatioTypeId =
			componentGroupRatioTypeFields.componentGroupRatioTypeId;
		this.name = componentGroupRatioTypeFields.name;

		const [
			itemQualifierIds,
			itemQualifiersIsOr
		] = tables.componentGroupRatioTypeItemQualifiers
			.filter(
				({ componentGroupRatioTypeId }) =>
					componentGroupRatioTypeId === this.componentGroupRatioTypeId
			)
			.reduce<[number[], boolean[]]>(
				(
					[itemQualifierIds, itemQualifiersIsOr],
					{ itemQualifierId, isOr }
				) => [
					[...itemQualifierIds, itemQualifierId],
					[...itemQualifiersIsOr, isOr]
				],
				[[], []]
			);

		this.itemQualifierIds = itemQualifierIds;
		this.itemQualifiersIsOr = itemQualifiersIsOr;

		this.componentGroupRatioIds = tables.componentGroupRatios
			.filter(
				({ componentGroupRatioTypeId }) =>
					componentGroupRatioTypeId === this.componentGroupRatioTypeId
			)
			.map(({ componentGroupRatioId }) => componentGroupRatioId);

		this.isValid = true;
	}
}
export default class ComponentGroupRatioType
	implements
		LinkedComponentGroupRatioType,
		Serializable<Serialized.ComponentGroupRatioType> {
	readonly linked = true;

	id: number;

	componentGroupRatioTypeId: number;
	name: string;

	itemQualifierIds: number[];
	itemQualifiersIsOr: boolean[];
	componentGroupRatioIds: number[];

	itemQualifiers: ItemQualifier[] = [];
	componentGroupRatios: ComponentGroupRatio[] = [];

	static initialized = false;
	static componentGroupRatioTypeIds: number[] = [];
	static componentGroupRatioTypes: NumericDictionary<
		ComponentGroupRatioType | UnlinkedComponentGroupRatioType | undefined
	> = {};

	static isComponentGroupRatioType(
		componentGroupRatioType:
			| UnlinkedComponentGroupRatioType
			| ComponentGroupRatioType
	): componentGroupRatioType is ComponentGroupRatioType {
		return componentGroupRatioType.linked;
	}

	static getComponentGroupRatioType(
		componentGroupRatioTypeId: number
	): ComponentGroupRatioType | undefined {
		let componentGroupRatioType = this.componentGroupRatioTypes[
			componentGroupRatioTypeId
		];

		if (componentGroupRatioType) {
			if (this.isComponentGroupRatioType(componentGroupRatioType)) {
				return componentGroupRatioType;
			}

			return new ComponentGroupRatioType(componentGroupRatioType);
		}
	}

	static async initialize(tables: Database.MappedTables) {
		this.initialized = false;
		this.componentGroupRatioTypeIds = [];
		this.componentGroupRatioTypes = {};

		for (const row of tables.componentGroupRatioType) {
			const componentGroupRatioType = new UnlinkedComponentGroupRatioType(
				row,
				tables
			);

			if (componentGroupRatioType.isValid) {
				this.componentGroupRatioTypeIds.push(
					componentGroupRatioType.componentGroupRatioTypeId
				);
				this.componentGroupRatioTypes[
					componentGroupRatioType.componentGroupRatioTypeId
				] = componentGroupRatioType;
			}
		}

		if (this.componentGroupRatioTypeIds.length > 0) this.initialized = true;

		return this.initialized;
	}

	static async linkAll() {
		for (const componentGroupRatioType of Object.values(
			ComponentGroupRatioType.componentGroupRatioTypes
		)) {
			if (componentGroupRatioType) {
				// try {
				new ComponentGroupRatioType(componentGroupRatioType);
				// } catch (err) {
				// 	// console.error(err);
				// }
			}
		}

		return true;
	}

	constructor(
		componentGroupRatioType:
			| ComponentGroupRatioType
			| UnlinkedComponentGroupRatioType
	) {
		ComponentGroupRatioType.componentGroupRatioTypes[
			componentGroupRatioType.componentGroupRatioTypeId
		] = this;

		this.id = componentGroupRatioType.componentGroupRatioTypeId;

		this.componentGroupRatioTypeId =
			componentGroupRatioType.componentGroupRatioTypeId;
		this.name = componentGroupRatioType.name;

		this.itemQualifierIds = componentGroupRatioType.itemQualifierIds;
		this.itemQualifiersIsOr = componentGroupRatioType.itemQualifiersIsOr;
		this.componentGroupRatioIds =
			componentGroupRatioType.componentGroupRatioIds;

		if (componentGroupRatioType.linked) {
			this.itemQualifiers = componentGroupRatioType.itemQualifiers;
			this.componentGroupRatios =
				componentGroupRatioType.componentGroupRatios;
		} else {
			for (const id of this.itemQualifierIds) {
				const itemQualifier = ItemQualifier.getItemQualifier(id);

				if (!itemQualifier) {
					throw new Error(
						'Failed to link component group ratio type to item qualifier'
					);
				}

				this.itemQualifiers.push(itemQualifier);
			}

			for (const id of this.componentGroupRatioIds) {
				const componentGroupRatio = ComponentGroupRatio.getComponentGroupRatio(
					id
				);

				if (!componentGroupRatio) {
					throw new Error(
						'Failed to link component group ratio type to component group ratio'
					);
				}

				this.componentGroupRatios.push(componentGroupRatio);
			}
		}
	}

	getComponentGroupRatios(
		componentGroups: ComponentGroup[]
	): ComponentGroupRatio[] {
		const componentGroupRatios: ComponentGroupRatio[] = [];

		for (const componentGroupRatio of (Object.values(
			ComponentGroupRatio.componentGroupRatios
		).filter(
			componentGroupRatio =>
				componentGroupRatio && componentGroupRatio.linked
		) as ComponentGroupRatio[]).filter(
			({ componentGroupRatioTypeId }) =>
				this.componentGroupRatioTypeId === componentGroupRatioTypeId
		)) {
			for (const componentGroup of componentGroups) {
				if (componentGroupRatio.hasComponentGroup(componentGroup.id)) {
					componentGroupRatios.push(componentGroupRatio);

					break;
				}
			}
		}

		return componentGroupRatios;
	}

	qualifies(transItem: TransItem): boolean {
		let orQualifies = false;
		let orExists = false;

		for (const qualifier in this.itemQualifiers) {
			const qualifies = this.itemQualifiers[qualifier].qualifies(
				transItem
			);

			if (this.itemQualifiersIsOr[qualifier]) {
				orExists = true;

				if (qualifies) orQualifies = true;
			} else if (!qualifies) return false;
		}

		if (!orQualifies && orExists) return false;

		return true;
	}

	serialize(): Serialized.ComponentGroupRatioType {
		return {
			id: this.id,

			componentGroupRatioTypeId: this.componentGroupRatioTypeId,
			name: this.name,

			itemQualifierIds: this.itemQualifierIds,
			itemQualifiersIsOr: this.itemQualifiersIsOr,
			componentGroupRatioIds: this.componentGroupRatioIds
		};
	}
}
