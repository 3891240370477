import {
	LinkedModifier,
	NumericDictionary,
	Database,
	Serializable,
	Serialized,
	UnlinkedModifier as UnlinkedModifierInterface
} from '../types';

import {
	Config,
	TransItem,
	ModifierFunction,
	ItemQualifier,
	ModifierScale,
	ModifierGroup
} from '.';

export class UnlinkedModifier implements UnlinkedModifierInterface {
	readonly linked = false;
	isValid = false;

	modifierId: number;
	name: string;
	ticketName: string;
	itemName?: string;
	itemNamePriority?: number;
	isVariableQuantity: boolean;
	inverseId: number;
	freeModId: number;
	isPrintable: boolean;
	priority: number;

	price: number;
	digitalPrice: number;
	modifierFunctionIds: number[];
	modifierScaleId?: number | undefined;
	modifierGroupIds: number[];
	modifierScaleIndex?: number | undefined;
	itemQualifierIds: number[];
	itemQualifiersIsOr: boolean[];
	isVisible: boolean;

	constructor(
		modifierFields: Database.MappedModifiersRow,
		tables: Database.MappedTables
	) {
		this.modifierId = modifierFields.modifierId;
		this.name = modifierFields.name;
		this.ticketName = modifierFields.ticketName;
		this.itemName = modifierFields.itemName;
		this.itemNamePriority = modifierFields.itemNamePriority;
		this.isVariableQuantity = modifierFields.isVariableQuantity;
		this.inverseId = modifierFields.inverseId;
		this.freeModId = modifierFields.freeModId;
		this.isPrintable = modifierFields.isPrintable;
		this.priority = modifierFields.priority;

		const thisFilter = ({ modifierId }: { modifierId: number }) =>
			modifierId === this.modifierId;

		const price = tables.modifierPrices
			.filter(
				modifierPrice =>
					modifierPrice.modifierId === this.modifierId &&
					(modifierPrice.storeId === 0 ||
						modifierPrice.storeId === Config.storeId) &&
					(modifierPrice.tierId === 0 ||
						modifierPrice.tierId === Config.tierId)
			)
			.sort((a, b) => a.storeId - b.storeId || a.tierId - b.tierId)[0];

		if (!price) {
			throw new Error('Failed to find modifier price');
		}

		this.price = price.price;

		this.digitalPrice =
			tables.modifierPrices
				.filter(
					modifierPrice =>
						modifierPrice.modifierId === this.modifierId &&
						modifierPrice.storeId === 0 &&
						modifierPrice.tierId === Config.digitalOrderPriceTier
				)
				.sort((a, b) => a.storeId - b.storeId || a.tierId - b.tierId)[0]
				?.price || this.price;

		this.modifierFunctionIds = tables.modifierFunctions
			.filter(thisFilter)
			.sort((a, b) => a.priority - b.priority)
			.map(({ modifierFunctionId }) => modifierFunctionId);

		const modifierScalesModifier = tables.modifierScalesModifiers.find(
			thisFilter
		);

		if (modifierScalesModifier) {
			this.modifierScaleId = modifierScalesModifier.modifierScaleId;
			this.modifierScaleIndex = modifierScalesModifier.priority - 1;
		}

		this.modifierGroupIds = tables.modifierGroupsModifiers
			.filter(thisFilter)
			.map(({ modifierGroupId }) => modifierGroupId);

		const visibilityRows = tables.modifierVisibility
			.filter(thisFilter)
			.sort((a, b) => a.storeId - b.storeId || a.regionId - b.regionId);

		this.isVisible = true;

		if (visibilityRows.length > 0) {
			const [{ storeId, regionId, isVisible: visible }] = visibilityRows;
			if (storeId === 0 && regionId === 0) {
				this.isVisible = visible;
			}

			for (const {
				storeId,
				regionId,
				isVisible: visible
			} of visibilityRows) {
				if (regionId === Config.regionId && storeId === 0) {
					this.isVisible = visible;
					break;
				}
			}

			for (const {
				storeId,
				regionId,
				isVisible: visible
			} of visibilityRows) {
				if (storeId === Config.storeId && regionId === 0) {
					this.isVisible = visible;
					break;
				}
			}
		}

		const [
			itemQualifierIds,
			itemQualifiersIsOr
		] = tables.modifiersItemQualifiers
			.filter(thisFilter)
			.reduce<[number[], boolean[]]>(
				(
					[itemQualifierIds, itemQualifiersIsOr],
					{ itemQualifierId, isOr }
				) => [
					[...itemQualifierIds, itemQualifierId],
					[...itemQualifiersIsOr, isOr]
				],
				[[], []]
			);

		this.itemQualifierIds = itemQualifierIds;
		this.itemQualifiersIsOr = itemQualifiersIsOr;

		this.isValid = true;
	}
}

export default class Modifier
	implements LinkedModifier, Serializable<Serialized.Modifier> {
	readonly linked = true;
	id: number;

	modifierId: number;
	name: string;
	ticketName: string;
	itemName?: string;
	itemNamePriority?: number;
	isVariableQuantity: boolean;
	inverseId: number;
	freeModId: number;
	isPrintable: boolean;
	priority: number;

	price: number;
	digitalPrice: number;
	modifierFunctionIds: number[];
	modifierScaleId?: number | undefined;
	modifierScaleIndex?: number;
	modifierGroupIds: number[];
	itemQualifierIds: number[];
	itemQualifiersIsOr: boolean[];
	isVisible: boolean;

	modifierFunctions: ModifierFunction[] = [];
	modifierScale?: ModifierScale | undefined;
	modifierGroups: ModifierGroup[] = [];
	inverseModifier?: Modifier | undefined;
	freeModifier?: Modifier | undefined;
	itemQualifiers: ItemQualifier[] = [];

	isPending: boolean = false;
	hasComponentGroupRatio: boolean = false;

	static initialized = false;
	static modifierIds: number[] = [];
	static modifiers: NumericDictionary<
		Modifier | UnlinkedModifier | undefined
	> = {};

	static isModifier(
		modifier: UnlinkedModifier | Modifier
	): modifier is Modifier {
		return modifier.linked;
	}

	static getModifier(modifierId: number): Modifier | undefined {
		const modifier = this.modifiers[modifierId];

		if (modifier) {
			if (this.isModifier(modifier)) {
				return modifier;
			}

			return new Modifier(modifier);
		}
	}

	static async initialize(tables: Database.MappedTables) {
		this.initialized = false;
		this.modifierIds = [];
		this.modifiers = {};

		for (const row of tables.modifiers) {
			const modifier = new UnlinkedModifier(row, tables);

			if (modifier.isValid) {
				this.modifierIds.push(modifier.modifierId);
				this.modifiers[modifier.modifierId] = modifier;
			}
		}

		if (this.modifierIds.length > 0) this.initialized = true;

		return this.initialized;
	}

	static async linkAll() {
		for (const modifier of Object.values(Modifier.modifiers)) {
			if (modifier) {
				// try {
				new Modifier(modifier);
				// } catch (err) {
				// 	// console.error(err);
				// }
			}
		}

		return true;
	}

	constructor(modifier: Modifier | UnlinkedModifier) {
		Modifier.modifiers[modifier.modifierId] = this;

		this.id = modifier.modifierId;

		this.modifierId = modifier.modifierId;
		this.name = modifier.name;
		this.ticketName = modifier.ticketName;
		this.itemName = modifier.itemName;
		this.itemNamePriority = modifier.itemNamePriority;
		this.isVariableQuantity = modifier.isVariableQuantity;
		this.inverseId = modifier.inverseId;
		this.freeModId = modifier.freeModId;
		this.isPrintable = modifier.isPrintable;
		this.priority = modifier.priority;

		this.price = modifier.price;
		this.digitalPrice = modifier.digitalPrice;
		this.modifierFunctionIds = modifier.modifierFunctionIds;
		this.modifierScaleId = modifier.modifierScaleId;
		this.modifierScaleIndex = modifier.modifierScaleIndex;
		this.modifierGroupIds = modifier.modifierGroupIds;
		this.itemQualifierIds = modifier.itemQualifierIds;
		this.itemQualifiersIsOr = modifier.itemQualifiersIsOr;
		this.isVisible = modifier.isVisible;

		if (modifier.linked) {
			this.modifierFunctions = modifier.modifierFunctions;
			this.modifierScale = modifier.modifierScale;
			this.modifierGroups = modifier.modifierGroups;
			this.inverseModifier = modifier.inverseModifier;
			this.freeModifier = modifier.freeModifier;
			this.itemQualifiers = modifier.itemQualifiers;
		} else {
			// link modifier functions
			for (const id of this.modifierFunctionIds) {
				const modifierFunction = ModifierFunction.getModifierFunction(
					id
				);

				if (!modifierFunction) {
					// console.log('Modifier.modifiers', Modifier.modifiers);
					console.log(
						'ModifierFunction.modifierFunctions',
						ModifierFunction.modifierFunctions
					);
					throw new Error(
						'Failed to link modifier ' +
							this.id +
							' to modifier function ' +
							id
					);
				}

				this.modifierFunctions.push(modifierFunction);

				if (
					!this.hasComponentGroupRatio &&
					modifierFunction.type ===
						'ADD_COMPONENT_GROUP_RATIO_COMPONENT'
				) {
					this.hasComponentGroupRatio = true;
				}
			}

			// link modifier scale
			if (this.modifierScaleId) {
				const modifierScale:
					| ModifierScale
					| undefined = ModifierScale.getModifierScale(
					this.modifierScaleId
				);

				if (!modifierScale) {
					throw new Error(
						'Failed to link modifier to modifier scale'
					);
				}

				this.modifierScale = modifierScale;
			}

			// link modifier groups
			for (const id of this.modifierGroupIds) {
				const modifierGroup = ModifierGroup.getModifierGroup(id);

				if (!modifierGroup) {
					throw new Error(
						'Failed to link modifier to modifier group'
					);
				}

				this.modifierGroups.push(modifierGroup);
			}

			// link inverse modifier
			if (this.inverseId) {
				const inverseModifier = Modifier.getModifier(this.inverseId);

				if (!inverseModifier) {
					throw new Error(
						'Failed to link modifier to inverse modifier'
					);
				}

				this.inverseModifier = inverseModifier;
			}

			// link free modifier
			if (this.freeModId) {
				const freeMod = Modifier.getModifier(this.freeModId);

				if (!freeMod) {
					throw new Error('Failed to link modifier to free mod');
				}

				this.freeModifier = freeMod;
			}

			// link item qualifiers
			for (const id of this.itemQualifierIds) {
				const itemQualifier = ItemQualifier.getItemQualifier(id);

				if (!itemQualifier) {
					// console.log('ItemQualifier.itemQualifiers');
					console.log(ItemQualifier.itemQualifiers);
					// console.log('Modifier.modifiers');
					console.log(Modifier.modifiers);

					throw new Error(
						'Failed to link modifier ' +
							this.id +
							' to item qualifier ' +
							id
					);
				}

				this.itemQualifiers.push(itemQualifier);
			}
		}
	}

	inModifierGroup(modifierGroup: ModifierGroup): boolean {
		return this.modifierGroups.some(group => group.id === modifierGroup.id);
	}

	doesCostApply(transItem: TransItem): boolean {
		const modifierFunctions = [...this.modifierFunctions];

		return this.checkDoesCostApply(transItem, modifierFunctions);
	}

	checkDoesCostApply(
		transItem: TransItem,
		modifierFunctions: ModifierFunction[]
	): boolean {
		for (const modifierFunction of modifierFunctions) {
			if (modifierFunction.qualifies(transItem)) {
				if (modifierFunction.hasEffect(transItem)) {
					if (modifierFunction.doesCostApply(transItem)) {
						return true;
					} else if (modifierFunction.stopOnSuccess) {
						return false;
					}
				} else if (modifierFunction.stopOnFailure) {
					return false;
				}
			} else if (modifierFunction.stopOnFailure) {
				return false;
			}
		}

		return true;
	}

	qualifies(transItem: TransItem): boolean {
		if (this.itemQualifiers.length < 1) {
			return true;
		}

		let orQualifies = false;
		let orExists = false;

		for (const index in this.itemQualifiers) {
			const qualifies = this.itemQualifiers[index].qualifies(transItem);

			if (this.itemQualifiersIsOr[index]) {
				orExists = true;

				if (qualifies) orQualifies = true;
			} else if (!qualifies) return false;
		}

		if (!orQualifies && orExists) return false;

		return true;
	}

	unapply(transItem: TransItem): boolean {
		if (this.inverseModifier && this.inverseModifier.qualifies(transItem)) {
			return transItem.modify(this.inverseModifier) === 'ITEM_OK';
		}

		let unapplied = false;

		const modifierFunctions = this.modifierFunctions;

		unapplied = this.unapplyModifierFunctions(transItem, modifierFunctions);

		return unapplied;
	}

	unapplyModifierFunctions(
		transItem: TransItem,
		modifierFunctions: ModifierFunction[]
	): boolean {
		let unapplied = false;

		for (const modifierFunction of modifierFunctions) {
			if (modifierFunction.unapply(transItem)) {
				unapplied = true;

				if (modifierFunction.stopOnSuccess) {
					return unapplied;
				}
			} else if (modifierFunction.stopOnFailure) {
				return unapplied;
			}
		}

		return unapplied;
	}

	apply(transItem: TransItem): boolean {
		let hadEffect = false;

		if (
			this.modifierFunctions.length === 0 &&
			this.ticketName &&
			this.ticketName !== ''
		) {
			hadEffect = true;
		}

		if (this.itemName && this.itemName !== '') {
			hadEffect = true;
		}

		hadEffect = this.applyModifierFunctions(
			transItem,
			this.modifierFunctions,
			hadEffect
		);

		if (this.itemName && this.itemName !== '') {
			transItem.determineTicketName();
		}

		return hadEffect;
	}

	applyModifierFunctions(
		transItem: TransItem,
		modifierFunctions: ModifierFunction[],
		hadEffect: boolean
	): boolean {
		let effect = hadEffect;

		for (const modifierFunction of modifierFunctions) {
			if (modifierFunction.hasEffect(transItem)) {
				if (!modifierFunction.qualifies(transItem)) {
					continue;
				} else if (!modifierFunction.apply(transItem)) {
					if (modifierFunction.stopOnFailure) return effect;
				} else {
					effect = true;

					if (modifierFunction.stopOnSuccess) {
						break;
					}
				}
			}
		}

		return effect;
	}

	hasModifier(transItem: TransItem): boolean {
		for (const transModifier of transItem.modifiers) {
			if (transModifier.modifier.id === this.id) {
				return true;
			}
		}

		return this.checkHasModifierFunction(transItem, this.modifierFunctions);
	}

	checkHasModifierFunction(
		transItem: TransItem,
		modifierFunctions: ModifierFunction[]
	): boolean {
		let hasModifierFunction = false;

		for (const modifierFunction of modifierFunctions) {
			if (modifierFunction.hasModifierFunction(transItem)) {
				if (modifierFunction.stopOnSuccess) {
					hasModifierFunction = true;
					break;
				}
			}
		}

		return hasModifierFunction;
	}

	hasEffect(transItem: TransItem): boolean {
		// console.log('Modifier: hasEffect');
		if (transItem.hasModifier(this) && !this.isVariableQuantity) {
			return false;
		}

		if (this.itemName !== '' && this.itemName) {
			return true;
		}

		if (
			this.modifierFunctions.length === 0 &&
			this.ticketName !== '' &&
			this.ticketName
		) {
			return true;
		}

		return this.affectsComponents(transItem);
	}

	affectsComponents(transItem: TransItem): boolean {
		// console.log('Modifier: affectsComponents');

		return this.checkHasEffect(transItem, this.modifierFunctions);
	}

	checkHasEffect(
		transItem: TransItem,
		modifierFunctions: ModifierFunction[]
	): boolean {
		// console.log('Modifier: checkHasEffect');
		for (const modifierFunction of modifierFunctions) {
			if (modifierFunction.qualifies(transItem)) {
				if (modifierFunction.hasEffect(transItem)) {
					return true;
				}
			} else if (modifierFunction.stopOnFailure) {
				return false;
			}
		}

		return false;
	}

	serialize(): Serialized.Modifier {
		return {
			id: this.id,
			name: this.name,
			priority: this.priority
		};
	}
}

export class ModifierModel implements Serializable<Serialized.ModifierModel> {
	transItem: TransItem;
	modifiers: Modifier[] = [];
	modifiersAreSelected: boolean[] = [];

	constructor(transItem: TransItem, modifiers: Modifier[] = []) {
		this.transItem = transItem;

		for (const modifier of modifiers) {
			if (modifier.qualifies(transItem)) {
				this.modifiers.push(modifier);
			}
		}

		for (const modifier of this.modifiers) {
			const isSelected =
				!modifier.hasEffect(this.transItem) ||
				this.transItem.hasModifier(modifier);

			this.modifiersAreSelected.push(isSelected);
		}
	}

	get rowCount(): number {
		return this.modifiers.length;
	}

	selectModifier(index: number) {
		// console.log('ModifierModel: selectModifier');
		// console.log('ModifierModel: selectModifier: index', index);

		this.transItem.modify(this.modifiers[index]);

		this.queryModifiers();
	}

	deselectModifier(index: number) {
		// console.log('ModifierModel: deselectModifier');
		// console.log('ModifierModel: deselectModifier: index', index);

		this.transItem.unModify(this.modifiers[index]);

		this.queryModifiers();
	}

	queryModifiers() {
		this.modifiersAreSelected = [];

		for (const modifier of this.modifiers) {
			const isSelected =
				!modifier.hasEffect(this.transItem) ||
				this.transItem.hasModifier(modifier);

			this.modifiersAreSelected.push(isSelected);
		}
	}

	serialize() {
		return {
			modifiers: this.modifiers.map(modifier => modifier.serialize()),
			modifiersAreSelected: this.modifiersAreSelected
		};
	}
}
