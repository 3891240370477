import {
	ComponentQualifierType,
	CompQOpr,
	LinkedComponentQualifier,
	NumericDictionary,
	Database,
	UnlinkedComponentQualifier as UnlinkedComponentQualifierInterface,
	Serializable,
	Serialized
} from '../types';

import { TransItem } from '.';

export class UnlinkedComponentQualifier
	implements UnlinkedComponentQualifierInterface {
	readonly linked = false;
	isValid = false;

	componentQualifierId: number;
	oldItem: number;
	newItem: number;
	componentId: number;
	value: number;

	operator: CompQOpr;
	qualifierType: ComponentQualifierType;

	constructor(
		componentQualifierFields: Database.MappedComponentQualifiersRow,
		tables: Database.MappedTables
	) {
		this.componentQualifierId =
			componentQualifierFields.componentQualifierId;
		this.oldItem = componentQualifierFields.oldItem;
		this.newItem = componentQualifierFields.newItem;
		this.componentId = componentQualifierFields.componentId;
		this.value = componentQualifierFields.value;

		switch (componentQualifierFields.operator) {
			case 0:
				this.operator = 'EQUAL';

				break;
			case 1:
				this.operator = 'LESS';

				break;
			case 2:
				this.operator = 'GTR';

				break;
			case 3:
				this.operator = 'NOT';

				break;
			default:
				throw new Error('Invalid component qualifier operator');
		}

		switch (componentQualifierFields.qualifierType) {
			case 0:
				this.qualifierType = 'QUALIFIER_TYPE_NONE';

				break;
			case 1:
				this.qualifierType = 'COMPONENT_QTY_CONTAINED';

				break;
			default:
				this.qualifierType = 'QUALIFIER_TYPE_MAX';
		}

		this.isValid = true;
	}
}

export default class ComponentQualifier
	implements
		LinkedComponentQualifier,
		Serializable<Serialized.ComponentQualifier> {
	readonly linked = true;

	id: number;

	componentQualifierId: number;
	oldItem: number;
	newItem: number;
	componentId: number;
	value: number;

	operator: CompQOpr;
	qualifierType: ComponentQualifierType;

	static initialized = false;
	static componentQualifierIds: number[] = [];
	static componentQualifiers: NumericDictionary<
		ComponentQualifier | UnlinkedComponentQualifier | undefined
	> = {};

	static isComponentQualifier(
		componentQualifier: UnlinkedComponentQualifier | ComponentQualifier
	): componentQualifier is ComponentQualifier {
		return componentQualifier.linked;
	}

	static getComponentQualifier(
		componentQualifierId: number
	): ComponentQualifier | undefined {
		const componentQualifier = this.componentQualifiers[
			componentQualifierId
		];

		if (componentQualifier) {
			if (this.isComponentQualifier(componentQualifier)) {
				return componentQualifier;
			}

			return new ComponentQualifier(componentQualifier);
		}
	}

	static async initialize(tables: Database.MappedTables) {
		this.initialized = false;
		this.componentQualifierIds = [];
		this.componentQualifiers = {};

		for (const row of tables.componentQualifiers) {
			const componentQualifier = new UnlinkedComponentQualifier(
				row,
				tables
			);

			if (componentQualifier.isValid) {
				this.componentQualifierIds.push(
					componentQualifier.componentQualifierId
				);
				this.componentQualifiers[
					componentQualifier.componentQualifierId
				] = componentQualifier;
			}
		}

		if (this.componentQualifierIds.length > 0) this.initialized = true;

		return this.initialized;
	}

	static async linkAll() {
		for (const componentQualifier of Object.values(
			ComponentQualifier.componentQualifiers
		)) {
			if (componentQualifier) {
				switch (componentQualifier.qualifierType) {
					case 'COMPONENT_QTY_CONTAINED':
						new ComponentQualifierQty(
							componentQualifier,
							componentQualifier.value
						);
						break;
					default:
						new ComponentQualifier(componentQualifier);
				}
			}
		}

		return true;
	}

	constructor(
		componentQualifier: ComponentQualifier | UnlinkedComponentQualifier
	) {
		ComponentQualifier.componentQualifiers[
			componentQualifier.componentQualifierId
		] = this;

		this.id = componentQualifier.componentQualifierId;
		this.componentQualifierId = componentQualifier.componentQualifierId;
		this.oldItem = componentQualifier.oldItem;
		this.newItem = componentQualifier.newItem;
		this.componentId = componentQualifier.componentId;
		this.operator = componentQualifier.operator;
		this.qualifierType = componentQualifier.qualifierType;
		this.value = componentQualifier.value;

		// TODO: check if the associated items and component exist, error if not
	}

	static getAllComponentQualifiers(): ComponentQualifier[] {
		const componentQualifiers: ComponentQualifier[] = [];

		for (const [id, componentQualifier] of Object.entries(
			this.componentQualifiers
		)) {
			if (componentQualifier) {
				if (componentQualifier.linked) {
					componentQualifiers.push(componentQualifier);
				}

				componentQualifiers.push(
					new ComponentQualifier(componentQualifier)
				);
			}
		}

		return componentQualifiers;
	}

	qualifies(transItem: TransItem): boolean {
		return false;
	}

	serialize(): Serialized.ComponentQualifier {
		return {
			id: this.id,
			componentQualifierId: this.componentQualifierId,
			componentId: this.componentId,
			newItem: this.newItem,
			oldItem: this.oldItem,
			operator: this.operator,
			qualifierType: this.qualifierType,
			value: this.value
		};
	}
}

class ComponentQualifierQty extends ComponentQualifier {
	count: number;

	constructor(
		componentQualifier: ComponentQualifier | UnlinkedComponentQualifier,
		count: number
	) {
		super(componentQualifier);

		this.count = count;
	}

	qualifies(transItem: TransItem): boolean {
		throw new Error('Method not implemented.');
	}
}
