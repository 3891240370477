import { Database } from '../types';

export const mapTables = (tables: Database.Tables): Database.MappedTables => ({
	componentGroupRatioQuantities: tables.componentGroupRatioQuantities.map(
		([
			componentGroupRatioId,
			componentGroupId,
			componentGroupCount,
			componentGroupQuantity
		]) => ({
			componentGroupRatioId,
			componentGroupId,
			componentGroupCount,
			componentGroupQuantity
		})
	),
	componentGroupRatios: tables.componentGroupRatios.map(
		([componentGroupRatioId, sizeId, componentGroupRatioTypeId]) => ({
			componentGroupRatioId,
			sizeId: sizeId === null ? undefined : sizeId,
			componentGroupRatioTypeId
		})
	),
	componentGroupRatiosItemQualifiers: tables.componentGroupRatiosItemQualifiers.map(
		([componentGroupRatioId, itemQualifierId, isOr]) => ({
			componentGroupRatioId,
			itemQualifierId,
			isOr
		})
	),
	componentGroupRatioType: tables.componentGroupRatioType.map(
		([componentGroupRatioTypeId, name]) => ({
			componentGroupRatioTypeId,
			name
		})
	),
	componentGroupRatioTypeItemQualifiers: tables.componentGroupRatioTypeItemQualifiers.map(
		([componentGroupRatioTypeId, itemQualifierId, isOr]) => ({
			componentGroupRatioTypeId,
			itemQualifierId,
			isOr
		})
	),
	componentGroups: tables.componentGroups.map(
		([
			componentGroupId,
			name,
			parentGroupId,
			suppressNameFromRecipe,
			unitId
		]) => ({
			componentGroupId,
			name,
			parentGroupId: parentGroupId === null ? undefined : parentGroupId,
			suppressNameFromRecipe,
			unitId
		})
	),
	componentQualifiers: tables.componentQualifiers.map(
		([
			componentQualifierId,
			oldItem,
			newItem,
			componentId,
			operator,
			qualifierType,
			value
		]) => ({
			componentQualifierId,
			oldItem,
			newItem,
			componentId,
			operator,
			qualifierType,
			value
		})
	),
	components: tables.components.map(
		([
			componentId,
			name,
			suppressNameFromRecipe,
			ticketName,
			unitId,
			inventoryItemId,
			servings,
			componentVersionId,
			baseComponentId,
			isVariableQuantity,
			isStatic
		]) => ({
			componentId,
			name,
			suppressNameFromRecipe,
			ticketName: ticketName === null ? undefined : ticketName,
			unitId,
			inventoryItemId,
			servings,
			componentVersionId:
				componentVersionId === null ? undefined : componentVersionId,
			baseComponentId:
				baseComponentId === null ? undefined : baseComponentId,
			isVariableQuantity,
			static: isStatic
		})
	),
	componentsComponentGroups: tables.componentsComponentGroups.map(
		([componentId, componentGroupId]) => ({
			componentId,
			componentGroupId
		})
	),
	componentsModifiers: tables.componentsModifiers.map(
		([componentId, modifierId]) => ({
			componentId,
			modifierId
		})
	),
	componentUnitRatios: tables.componentUnitRatios.map(
		([componentId, unitId, conversionRatio]) => ({
			componentId,
			unitId,
			conversionRatio
		})
	),
	componentVersions: tables.componentVersions.map(
		([componentVersionId, name]) => ({
			componentVersionId,
			name
		})
	),
	config: tables.config.map(([storeId, termId, setting, value]) => ({
		storeId,
		termId,
		setting,
		value
	})),
	containers: tables.containers.map(([containerId, name]) => ({
		containerId,
		name
	})),
	containerVolumes: tables.containerVolumes.map(
		([containerId, deductionInches, volumeFlOz]) => ({
			containerId,
			deductionInches,
			volumeFlOz
		})
	),
	inventoryItems: tables.inventoryItems.map(
		([
			inventoryItemId,
			name,
			containerName,
			containerNamePlural,
			units,
			unitsPlural,
			containerSize,
			calories,
			caloriesFromFat,
			fat,
			saturatedFat,
			transFat,
			cholesterol,
			sodium,
			carbohydrates,
			dietaryFiber,
			sugar,
			protein,
			caffeine
		]) => ({
			inventoryItemId,
			name,
			containerName,
			containerNamePlural:
				containerNamePlural === null ? undefined : containerNamePlural,
			units,
			unitsPlural: unitsPlural === null ? undefined : unitsPlural,
			containerSize,
			calories,
			caloriesFromFat,
			fat,
			saturatedFat,
			transFat,
			cholesterol,
			sodium,
			carbohydrates,
			dietaryFiber,
			sugar,
			protein,
			caffeine
		})
	),
	itemPrices: tables.itemPrices.map(([itemId, tierId, storeId, price]) => ({
		itemId,
		tierId,
		storeId,
		price
	})),
	itemQualifiers: tables.itemQualifiers.map(
		([itemQualifierId, itemQualifierType, name, isNot, qualifyingId]) => ({
			itemQualifierId,
			itemQualifierType,
			name,
			isNot,
			qualifyingId
		})
	),
	items: tables.items.map(
		([
			itemId,
			productId,
			sizeId,
			isVariablePrice,
			containerId,
			capacity
		]) => ({
			itemId,
			productId,
			sizeId: sizeId === null ? undefined : sizeId,
			isVariablePrice,
			containerId: containerId === null ? undefined : containerId,
			capacity: capacity === null ? undefined : capacity
		})
	),
	itemsComponents: tables.itemsComponents.map(
		([itemId, componentId, componentGroupId, quantity, relevance]) => ({
			itemId,
			componentId,
			componentGroupId,
			quantity,
			relevance
		})
	),
	modifierFunctions: tables.modifierFunctions.map(
		([
			modifierFunctionId,
			modifierFunctionType,
			modifierId,
			priority,
			stopOnFailure,
			stopOnSuccess,
			fromId,
			fromValue,
			toId,
			toValue
		]) => ({
			modifierFunctionId,
			modifierFunctionType,
			modifierId,
			priority,
			stopOnFailure,
			stopOnSuccess,
			fromId,
			fromValue: fromValue === null ? undefined : fromValue,
			toId: toId === null ? undefined : toId,
			toValue: toValue === null ? undefined : toValue
		})
	),
	modifierFunctionsItemQualifiers: tables.modifierFunctionsItemQualifiers.map(
		([modifierFunctionId, itemQualifierId, isOr]) => ({
			modifierFunctionId,
			itemQualifierId,
			isOr
		})
	),
	modifierGroups: tables.modifierGroups.map(
		([modifierGroupId, name, priority, min, max, isVisible]) => ({
			modifierGroupId,
			name,
			priority,
			min,
			max,
			isVisible
		})
	),
	modifierGroupsItemQualifiers: tables.modifierGroupsItemQualifiers.map(
		([modifierGroupId, itemQualifierId, isOr]) => ({
			modifierGroupId,
			itemQualifierId,
			isOr
		})
	),
	modifierGroupsModifiers: tables.modifierGroupsModifiers.map(
		([modifierGroupId, modifierId, priority]) => ({
			modifierGroupId,
			modifierId,
			priority: priority === null ? undefined : priority
		})
	),
	modifierPrices: tables.modifierPrices.map(
		([modifierId, tierId, storeId, price]) => ({
			modifierId,
			tierId,
			storeId,
			price
		})
	),
	modifiers: tables.modifiers.map(
		([
			modifierId,
			name,
			ticketName,
			itemName,
			itemNamePriority,
			isVariableQuantity,
			inverseId,
			freeModId,
			isPrintable,
			priority
		]) => ({
			modifierId,
			name,
			ticketName,
			itemName: itemName === null ? undefined : itemName,
			itemNamePriority:
				itemNamePriority === null ? undefined : itemNamePriority,
			isVariableQuantity,
			inverseId,
			freeModId,
			isPrintable,
			priority
		})
	),
	modifiersItemQualifiers: tables.modifiersItemQualifiers.map(
		([modifierId, itemQualifierId, isOr]) => ({
			modifierId,
			itemQualifierId,
			isOr
		})
	),
	modifierScales: tables.modifierScales.map(
		([
			modifierScaleId,
			name,
			priority,
			isPriceIncremental,
			freeModId,
			isPriceDecremental
		]) => ({
			modifierScaleId,
			name,
			priority,
			isPriceIncremental,
			freeModId,
			isPriceDecremental
		})
	),
	modifierScalesItemQualifiers: tables.modifierScalesItemQualifiers.map(
		([modifierScaleId, itemQualifierId, isOr]) => ({
			modifierScaleId,
			itemQualifierId,
			isOr
		})
	),
	modifierScalesModifiers: tables.modifierScalesModifiers.map(
		([modifierScaleId, modifierId, priority]) => ({
			modifierScaleId,
			modifierId,
			priority
		})
	),
	modifierVisibility: tables.modifierVisibility.map(
		([modifierId, storeId, regionId, isVisible]) => ({
			modifierId,
			storeId,
			regionId,
			isVisible
		})
	),
	productGroups: tables.productGroups.map(
		([productGroupId, name, parentId, priority, isVisible]) => ({
			productGroupId,
			name,
			parentId: parentId === null ? undefined : parentId,
			priority: priority === null ? undefined : priority,
			isVisible
		})
	),
	productGroupsProducts: tables.productGroupsProducts.map(
		([productGroupId, productId, priority]) => ({
			productGroupId,
			productId,
			priority: priority === null ? undefined : priority
		})
	),
	products: tables.products.map(
		([
			productId,
			name,
			ticketName,
			otherNames,
			printable,
			productVersionId,
			tokenProductId,
			defaultSizeId,
			autoModify,
			includeInSales
		]) => ({
			productId,
			name,
			ticketName: ticketName === null ? undefined : ticketName,
			otherNames: otherNames === null ? undefined : otherNames,
			printable,
			productVersionId:
				productVersionId === null ? undefined : productVersionId,
			tokenProductId:
				tokenProductId === null ? undefined : tokenProductId,
			defaultSizeId: defaultSizeId === null ? undefined : defaultSizeId,
			autoModify,
			includeInSales
		})
	),
	productVersions: tables.productVersions.map(
		([productVersionId, name, priority]) => ({
			productVersionId,
			name,
			priority
		})
	),
	productVisibility: tables.productVisibility.map(
		([productId, storeId, regionId, isVisible]) => ({
			productId,
			storeId,
			regionId,
			isVisible
		})
	),
	sizes: tables.sizes.map(
		([sizeId, name, itemName, itemNamePriority, priority]) => ({
			sizeId,
			name,
			itemName,
			itemNamePriority,
			priority
		})
	),
	stateLocale: tables.stateLocale.map(([stateId, localeId]) => ({
		stateId,
		localeId
	})),
	taxLocal: tables.taxLocal.map(([localeId, componentGroupId, tax]) => ({
		localeId,
		componentGroupId,
		tax
	})),
	taxState: tables.taxState.map(([stateId, componentGroupId, tax]) => ({
		stateId,
		componentGroupId,
		tax
	})),
	units: tables.units.map(([unitId, unitName, unitNamePlural]) => ({
		unitId,
		unitName,
		unitNamePlural: unitNamePlural === null ? undefined : unitNamePlural
	}))
});
