import { v4 } from 'uuid';

import { Serializable, Serialized } from '../types';

import Money from './Money';

export default class Payment implements Serializable<Serialized.Payment> {
	id: string = v4();
	isVoided: boolean = false;
	amount: Money = new Money();
	overageAmount: Money = new Money();

	constructor(id?: string) {
		this.id = id || this.id;
	}

	serialize(): Serialized.Payment {
		return {
			id: this.id
		};
	}
}
