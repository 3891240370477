import {
	FoamStatus,
	LinkedModifierFunction,
	ModifierFunctionType,
	NumericDictionary,
	Database,
	UnlinkedModifierFunction as UnlinkedModifierFunctionInterface,
	Serialized,
	Serializable
} from '../types';

import {
	Config,
	TransItem,
	ItemQualifier,
	Component,
	ComponentGroup,
	Modifier,
	ModifierGroup,
	TransComponent
} from '.';

import Money from './Money';

const MODIFIER_FUNCTION_TYPES: (ModifierFunctionType | undefined)[] = [
	'MODIFIER_FUNCTION_TYPE_NONE',
	'REMOVE',
	'REMOVE_GROUP',
	'SET',
	'SET_REPLACE_GROUP',
	'ADD_RECIPE_COMPONENT',
	'ADD_GROUP',
	'SET_COMPONENT_VERSION',
	'REPLACE_GROUP_WITH_COMPONENT',
	'REPLACE_COMPONENT',
	'SET_GROUP_REPLACE_GROUP',
	'SPLIT_AMONG_GROUP',
	'ADD_COMPONENT',
	'SET_GROUP',
	'ADD_MODIFIER',
	'SET_GROUP_RATIO_REPLACE_GROUP',
	'SET_GROUP_RATIO_REPLACE_COMPONENT',
	'SET_GROUP_COMPONENT_VERSION',
	'MULTIPLY_GROUP',
	'ADD_RECIPE_COMPONENT_UPCHARGE',
	undefined,
	'ADD_COMPONENT_GROUP_RATIO_COMPONENT',
	'SET_PRICE',
	'SET_FOAM',
	'SET_GROUP_HAS_EFFECT',
	'ADD_INVENTORY_COMPONENTS',
	'REMOVE_MODIFIER_GROUP',
	'REMOVE_MODIFIER'
];

const throwIfUndef = <T>(x: T | undefined): T => {
	if (x === undefined) {
		throw new Error('Failed to link modifier function to ' + typeof x);
	}

	return x;
};

export class UnlinkedModifierFunction
	implements UnlinkedModifierFunctionInterface {
	readonly linked = false;
	isValid = false;

	modifierFunctionId: number;
	modifierFunctionType: number;
	modifierId: number;
	priority: number;
	stopOnFailure: boolean;
	stopOnSuccess: boolean;
	fromId: number;
	fromValue?: number;
	toId?: number;
	toValue?: number;

	type: ModifierFunctionType;
	itemQualifierIds: number[];
	itemQualifiersIsOr: boolean[];

	constructor(
		modifierFunctionFields: Database.MappedModifierFunctionsRow,
		tables: Database.MappedTables
	) {
		this.modifierFunctionId = modifierFunctionFields.modifierFunctionId;
		this.modifierFunctionType = modifierFunctionFields.modifierFunctionType;
		this.modifierId = modifierFunctionFields.modifierId;
		this.priority = modifierFunctionFields.priority;
		this.stopOnFailure = modifierFunctionFields.stopOnFailure;
		this.stopOnSuccess = modifierFunctionFields.stopOnSuccess;
		this.fromId = modifierFunctionFields.fromId;
		this.fromValue = modifierFunctionFields.fromValue;
		this.toId = modifierFunctionFields.toId;
		this.toValue = modifierFunctionFields.toValue;

		const type = MODIFIER_FUNCTION_TYPES[this.modifierFunctionType];

		if (!type) {
			throw new Error(
				'Invalid modifier function type ' + this.modifierFunctionType
			);
		}

		this.type = type;

		const [
			itemQualifierIds,
			itemQualifiersIsOr
		] = tables.modifierFunctionsItemQualifiers
			.filter(
				({ modifierFunctionId }) =>
					modifierFunctionId === this.modifierFunctionId
			)
			.reduce<[number[], boolean[]]>(
				(
					[itemQualifierIds, itemQualifiersIsOr],
					{ itemQualifierId, isOr }
				) => [
					[...itemQualifierIds, itemQualifierId],
					[...itemQualifiersIsOr, isOr]
				],
				[[], []]
			);

		this.itemQualifierIds = itemQualifierIds;
		this.itemQualifiersIsOr = itemQualifiersIsOr;

		this.isValid = true;
	}
}

export default abstract class ModifierFunction
	implements
		LinkedModifierFunction,
		Serializable<Serialized.ModifierFunction> {
	readonly linked = true;

	id: number;

	modifierFunctionId: number;
	modifierFunctionType: number;
	modifierId: number;
	priority: number;
	stopOnFailure: boolean;
	stopOnSuccess: boolean;
	fromId: number;
	fromValue?: number;
	toId?: number;
	toValue?: number;

	type: ModifierFunctionType;
	itemQualifierIds: number[];
	itemQualifiersIsOr: boolean[];

	itemQualifiers: ItemQualifier[] = [];

	static initialized = false;
	static modifierFunctionIds: number[] = [];
	static modifierFunctions: NumericDictionary<
		ModifierFunction | UnlinkedModifierFunction | undefined
	> = {};

	static isModifierFunction(
		modifierFunction: UnlinkedModifierFunction | ModifierFunction
	): modifierFunction is ModifierFunction {
		return modifierFunction.linked;
	}

	static getModifierFunction(
		modifierFunctionId: number
	): ModifierFunction | undefined {
		const modifierFunction = this.modifierFunctions[modifierFunctionId];

		if (modifierFunction) {
			if (this.isModifierFunction(modifierFunction)) {
				return modifierFunction;
			}

			return ModifierFunction.newModifierFunction(modifierFunction);
		}
	}

	static async initialize(tables: Database.MappedTables) {
		this.initialized = false;
		this.modifierFunctionIds = [];
		this.modifierFunctions = {};

		for (const row of tables.modifierFunctions) {
			const modifierFunction = new UnlinkedModifierFunction(row, tables);

			if (modifierFunction.isValid) {
				this.modifierFunctionIds.push(
					modifierFunction.modifierFunctionId
				);
				this.modifierFunctions[
					modifierFunction.modifierFunctionId
				] = modifierFunction;
			}
		}

		if (this.modifierFunctionIds.length > 0) this.initialized = true;

		return this.initialized;
	}

	static async linkAll() {
		for (const modifierFunction of Object.values(
			ModifierFunction.modifierFunctions
		)) {
			if (modifierFunction) {
				ModifierFunction.newModifierFunction(modifierFunction);
			}
		}

		return true;
	}

	constructor(modifierFunction: ModifierFunction | UnlinkedModifierFunction) {
		ModifierFunction.modifierFunctions[
			modifierFunction.modifierFunctionId
		] = this;

		this.id = modifierFunction.modifierFunctionId;

		this.modifierFunctionId = modifierFunction.modifierFunctionId;
		this.modifierFunctionType = modifierFunction.modifierFunctionType;
		this.modifierId = modifierFunction.modifierId;
		this.priority = modifierFunction.priority;
		this.stopOnFailure = modifierFunction.stopOnFailure;
		this.stopOnSuccess = modifierFunction.stopOnSuccess;
		this.fromId = modifierFunction.fromId;
		this.fromValue = modifierFunction.fromValue;
		this.toId = modifierFunction.toId;
		this.toValue = modifierFunction.toValue;

		this.type = modifierFunction.type;
		this.itemQualifierIds = modifierFunction.itemQualifierIds;
		this.itemQualifiersIsOr = modifierFunction.itemQualifiersIsOr;

		if (modifierFunction.linked) {
			this.itemQualifiers = modifierFunction.itemQualifiers;
		} else {
			for (const id of this.itemQualifierIds) {
				const itemQualifier = ItemQualifier.getItemQualifier(id);

				if (!itemQualifier) {
					throw new Error(
						'Failed to link modifier function to item qualifier'
					);
				}

				this.itemQualifiers.push(itemQualifier);
			}
		}
	}

	abstract hasEffect(transItem: TransItem): boolean;
	abstract apply(transItem: TransItem): boolean;

	static newModifierFunction(
		modifierFunction: ModifierFunction | UnlinkedModifierFunction
	): ModifierFunction {
		switch (modifierFunction.type) {
			case 'REMOVE':
				return new MF_Remove(modifierFunction);
			case 'REMOVE_GROUP':
				return new MF_RemoveGroup(modifierFunction);
			case 'SET':
				return new MF_Set(modifierFunction);
			case 'SET_REPLACE_GROUP':
				return new MF_SetReplaceGroup(modifierFunction);
			case 'ADD_RECIPE_COMPONENT':
				return new MF_AddRecipeComponent(modifierFunction);
			case 'ADD_GROUP':
				return new MF_AddGroup(modifierFunction);
			case 'SET_COMPONENT_VERSION':
				return new MF_SetComponentVersion(modifierFunction);
			case 'REPLACE_GROUP_WITH_COMPONENT':
				return new MF_ReplaceGroupWithComponent(modifierFunction);
			case 'REPLACE_COMPONENT':
				return new MF_ReplaceComponent(modifierFunction);
			case 'SET_GROUP_REPLACE_GROUP':
				return new MF_SetGroupReplaceGroup(modifierFunction);
			case 'SPLIT_AMONG_GROUP':
				return new MF_SplitAmongGroup(modifierFunction);
			case 'ADD_COMPONENT':
				return new MF_AddComponent(modifierFunction);
			case 'SET_GROUP':
				return new MF_SetGroup(modifierFunction);
			case 'ADD_MODIFIER':
				return new MF_AddModifier(modifierFunction);
			case 'SET_GROUP_RATIO_REPLACE_GROUP':
				return new MF_SetGroupRatioReplaceGroup(modifierFunction);
			case 'SET_GROUP_RATIO_REPLACE_COMPONENT':
				return new MF_SetGroupRatioReplaceComponent(modifierFunction);
			case 'SET_GROUP_COMPONENT_VERSION':
				return new MF_SetGroupComponentVersion(modifierFunction);
			case 'MULTIPLY_GROUP':
				return new MF_MultiplyGroup(modifierFunction);
			case 'ADD_RECIPE_COMPONENT_UPCHARGE':
				return new MF_AddRecipeComponentUpcharge(modifierFunction);
			case 'ADD_COMPONENT_GROUP_RATIO_COMPONENT':
				return new MF_AddComponentGroupRatioComponent(modifierFunction);
			case 'SET_PRICE':
				return new MF_SetPrice(modifierFunction);
			case 'SET_FOAM':
				return new MF_SetFoam(modifierFunction);
			case 'SET_GROUP_HAS_EFFECT':
				return new MF_SetGroupHasEffect(modifierFunction);
			case 'ADD_INVENTORY_COMPONENTS':
				return new MF_AddInventoryComponents(modifierFunction);
			case 'REMOVE_MODIFIER_GROUP':
				return new MF_RemoveModifierGroup(modifierFunction);
			case 'REMOVE_MODIFIER':
				return new MF_RemoveModifier(modifierFunction);
			default:
				throw new Error(
					'Invalid modifier function type: ' + modifierFunction.type
				);
		}
	}

	hasModifierFunction(transItem: TransItem): boolean {
		return false;
	}

	qualifies(transItem: TransItem): boolean {
		if (this.itemQualifiers.length < 1) {
			return true;
		}

		let orQualifies: boolean = false;
		let orExists: boolean = false;

		for (const index in this.itemQualifiers) {
			const qualifies: boolean = this.itemQualifiers[index].qualifies(
				transItem
			);

			if (this.itemQualifiersIsOr[index]) {
				orExists = true;

				if (qualifies) orQualifies = true;
			} else if (!qualifies) return false;
		}

		if (!orQualifies && orExists) return false;

		return true;
	}

	doesCostApply(transItem: TransItem): boolean {
		return true;
	}

	unapply(transItem: TransItem): boolean {
		return false;
	}

	serialize(): Serialized.ModifierFunction {
		return {
			id: this.id,

			modifierFunctionId: this.modifierFunctionId,
			modifierFunctionType: this.modifierFunctionType,
			modifierId: this.modifierId,
			priority: this.priority,
			stopOnFailure: this.stopOnFailure,
			stopOnSuccess: this.stopOnSuccess,
			fromId: this.fromId,
			fromValue: this.fromValue,
			toId: this.toId,
			toValue: this.toValue,

			type: this.type,
			itemQualifierIds: this.itemQualifierIds,
			itemQualifiersIsOr: this.itemQualifiersIsOr
		};
	}
}

class MF_Remove extends ModifierFunction {
	componentId: number;
	amount: number;

	component: Component;

	constructor(modifierFunction: ModifierFunction | UnlinkedModifierFunction) {
		super(modifierFunction);

		this.componentId = this.fromId;
		this.amount = this.fromValue || 0;

		const component = Component.getComponent(this.componentId);

		if (!component) {
			throw new Error('Failed to link modifier function to component');
		}

		this.component = component;
	}

	hasEffect(transItem: TransItem): boolean {
		return transItem.hasComponent(this.component, true);
	}

	apply(transItem: TransItem): boolean {
		// console.log('MF_Remove: apply');

		return transItem.removeComponent(this.componentId, true) === 'ITEM_OK';
	}
}

class MF_RemoveGroup extends ModifierFunction {
	componentGroupId: number;
	amount: number;

	componentGroup: ComponentGroup;

	constructor(modifierFunction: ModifierFunction | UnlinkedModifierFunction) {
		super(modifierFunction);

		this.componentGroupId = this.fromId;
		this.amount = this.fromValue || 0;

		const componentGroup = ComponentGroup.getComponentGroup(
			this.componentGroupId
		);

		if (!componentGroup) {
			throw new Error('Modifier data not found for MF_RemoveGroup');
		}

		this.componentGroup = componentGroup;
	}

	hasEffect(transItem: TransItem): boolean {
		return transItem.hasComponentGroup(this.componentGroup);
	}

	apply(transItem: TransItem): boolean {
		// console.log('MF_RemoveGroup: apply');

		const components = transItem.components.filter(component =>
			this.componentGroup.containsComponent(component.component.id)
		);

		let totalQuantity = components.reduce(
			(acc, component) => acc + component.quantity,
			0
		);

		for (const component of components) {
			let quantityToRemove: number;

			if (this.amount === 0) {
				quantityToRemove = component.quantity;
			} else {
				quantityToRemove =
					((component.quantity * 1.0) / totalQuantity) * this.amount;
			}

			if (component.quantity <= quantityToRemove) {
				transItem.removeComponent(component.component.id);
			} else {
				transItem.components[
					transItem.components.findIndex(
						({ id }) => id === component.id
					)
				].quantity = component.quantity - quantityToRemove;
			}
		}

		return true;
	}
}

class MF_Set extends ModifierFunction {
	componentId: number;
	quantity: number;

	component: Component;

	constructor(modifierFunction: ModifierFunction | UnlinkedModifierFunction) {
		super(modifierFunction);

		this.componentId = this.fromId;

		if (this.fromValue === null || this.fromValue === undefined) {
			throw new Error('No fromValue for MF_Set');
		}

		const component = Component.getComponent(this.componentId);

		if (!component) {
			throw new Error('Failed to link modifier function to component');
		}

		this.component = component;

		this.quantity = this.fromValue;
	}

	hasEffect(transItem: TransItem): boolean {
		const transComponent = transItem.getComponent(this.componentId);

		if (transComponent) {
			if (transComponent.quantity !== this.quantity) {
				return true;
			} else {
				return false;
			}
		}

		return true;
	}

	apply(transItem: TransItem): boolean {
		// console.log('MF_Set: apply');

		const component = transItem.getComponent(this.componentId);

		if (!component) {
			transItem.addComponent(this.component, this.quantity);
		} else {
			transItem.components[
				transItem.components.findIndex(({ id }) => id === component.id)
			].quantity = this.quantity;
		}

		return true;
	}
}

class MF_SetReplaceGroup extends ModifierFunction {
	componentToSetId: number;
	componentGroupToFillId: number;
	amount: number;

	componentToSet: Component;
	componentGroupToFill: ComponentGroup;

	constructor(modifierFunction: ModifierFunction | UnlinkedModifierFunction) {
		super(modifierFunction);

		this.componentToSetId = this.fromId;
		this.amount = this.fromValue || 0;

		if (this.toId === undefined) {
			throw new Error('Missing toId for MF_SetReplaceGroup');
		}

		this.componentGroupToFillId = this.toId;

		this.componentToSet = throwIfUndef(
			Component.getComponent(this.componentToSetId)
		);

		this.componentGroupToFill = throwIfUndef(
			ComponentGroup.getComponentGroup(this.componentGroupToFillId)
		);
	}

	hasEffect(transItem: TransItem): boolean {
		let componentAmount = 0;

		for (const transComponent of transItem.components) {
			if (
				this.componentToSet.baseComponentId ===
				transComponent.component.baseComponentId
			) {
				componentAmount += transComponent.quantity || 0;
			}
		}

		if (componentAmount !== this.amount) {
			return true;
		}

		return false;
	}

	apply(transItem: TransItem): boolean {
		// console.log('MF_SetReplaceGroup: apply');

		let componentsToSet: TransComponent[] = [];
		let componentsToFill: TransComponent[] = [];

		let originalAmount = 0;

		for (const transComponent of transItem.components) {
			if (
				this.componentToSet.baseComponentId ===
				transComponent.component.baseComponentId
			) {
				componentsToSet.push(transComponent);
				originalAmount += transComponent.quantity;
			}

			if (
				this.componentGroupToFill.containsComponent(
					transComponent.component.id
				)
			) {
				componentsToFill.push(transComponent);
			}
		}

		const amountToSet = this.amount / componentsToSet.length;
		let amountToFill = originalAmount - this.amount;
		amountToFill /= componentsToFill.length;

		for (const transComponent of componentsToSet) {
			transItem.components[
				transItem.components.findIndex(
					({ id }) => id === transComponent.id
				)
			].quantity = amountToSet;
		}

		for (const transComponent of componentsToFill) {
			let quantity = transComponent.quantity + amountToFill;

			if (quantity <= 0) {
				transItem.removeComponent(transComponent.component.id);
			} else {
				transItem.components[
					transItem.components.findIndex(
						({ id }) => id === transComponent.id
					)
				].quantity = quantity;
			}
		}

		return true;
	}
}

class MF_AddRecipeComponent extends ModifierFunction {
	componentId: number;
	doesCostApply_: boolean;

	component: Component;

	constructor(modifierFunction: ModifierFunction | UnlinkedModifierFunction) {
		super(modifierFunction);

		this.componentId = this.fromId;
		this.doesCostApply_ = Boolean(this.fromValue);

		this.component = throwIfUndef(Component.getComponent(this.componentId));
	}

	hasEffect(transItem: TransItem): boolean {
		if (transItem.hasComponent(this.component, true)) {
			return false;
		}

		return true;
	}

	hasModifierFunction(transItem: TransItem): boolean {
		if (transItem.hasComponent(this.component)) {
			return true;
		}

		return false;
	}

	apply(transItem: TransItem): boolean {
		// console.log('MF_AddRecipeComponent: apply');

		if (transItem.addRecipeComponent(this.componentId) !== 'ITEM_OK') {
			return false;
		}

		return true;
	}

	unapply(transItem: TransItem): boolean {
		if (transItem.item.inRecipe(this.component, false, false)) {
			if (
				transItem.removeComponent(this.componentId, true) !== 'ITEM_OK'
			) {
				return false;
			}
		} else {
			return false;
		}

		return true;
	}

	doesCostApply(transItem: TransItem): boolean {
		if (this.doesCostApply_) {
			return true;
		}

		/*
		 * What we do here is check if the component being added is in the Cost
		 * Applies component group. The cost applies component group is for components
		 * that you want to always have cost applies as set equal to true. After this,
		 *  we check if the trans item has a component that is already in this group.
		 *   If not, we return true.  If it does, we check the cost applies component
		 * group for any child groups.  If there is a specific child group that is
		 * both associated with the component we are adding and also is in the transItem,
		 * we will then return false.  This was set up so things such as LTO syrups
		 * will only apply their cost once in circumstances where more than 1
		 * LTO syrup is added to a beverage.
		 */

		if (this.component.hasCostAppliesComponentGroup()) {
			if (transItem.hasCostAppliesComponent()) {
				const componentGroup = throwIfUndef(
					ComponentGroup.getComponentGroup(Config.costApplies)
				);

				if (componentGroup.childGroups.length === 0) {
					return false;
				}

				const componentComponentGroups = this.component.componentGroups;

				for (const childGroup of componentGroup.childGroups) {
					for (const componentComponentGroup of componentComponentGroups) {
						if (
							componentComponentGroup.id === childGroup.id &&
							transItem.hasComponentGroup(childGroup)
						) {
							return false;
						}
					}
				}
			}

			return true;
		}

		if (
			this.component.componentGroups.some(componentGroup =>
				transItem.hasComponentGroup(componentGroup)
			)
		) {
			return false;
		}

		/*
		 * If the drink that we are adding the component to already has the
		 * component in the recipe, there should not be an upcharge for re-adding
		 * it (such as the Reduced Cal mod removing a garnish that normally costs
		 * $0.50, if the customer wants the garnish still on the drink, it should
		 * not cost $0.50 to add it again)
		 */

		if (
			transItem.item.recipe.some(
				ingredient =>
					ingredient.component &&
					ingredient.component.id === this.component.id &&
					!transItem.hasComponentById(this.component.id, false)
			)
		) {
			return false;
		}

		return true;
	}
}

class MF_AddGroup extends ModifierFunction {
	componentGroupId: number;
	quantity: number;

	componentGroup: ComponentGroup;

	constructor(modifierFunction: ModifierFunction | UnlinkedModifierFunction) {
		super(modifierFunction);

		this.componentGroupId = this.fromId;
		this.quantity = this.fromValue || 0;

		this.componentGroup = throwIfUndef(
			ComponentGroup.getComponentGroup(this.componentGroupId)
		);
	}

	hasEffect(transItem: TransItem): boolean {
		return transItem.hasComponentGroup(this.componentGroup);
	}

	apply(transItem: TransItem): boolean {
		// console.log('MF_AddGroup: apply');

		const numComponentsToAdd = this.componentGroup.components.reduce(
			(acc, component) =>
				transItem.components.some(
					transComponent =>
						component.id === transComponent.component.id
				)
					? acc + 1
					: acc,
			0
		);

		transItem.components = transItem.components.map(transComponent => {
			const componentGroupComponent = this.componentGroup.components.find(
				component => component.id === transComponent.component.id
			);

			if (componentGroupComponent !== undefined) {
				transComponent.quantity += this.quantity / numComponentsToAdd;
			}

			return transComponent;
		});

		return true;
	}
}

class MF_SetComponentVersion extends ModifierFunction {
	componentVersionId: number;
	percent: number;

	constructor(modifierFunction: ModifierFunction | UnlinkedModifierFunction) {
		super(modifierFunction);

		this.componentVersionId = this.fromId;
		this.percent = this.fromValue || 1;
	}

	hasEffect(transItem: TransItem): boolean {
		for (const transComponent of transItem.components) {
			const otherComponent = transComponent.component.getOtherVersion(
				this.componentVersionId
			);

			if (otherComponent) {
				if (this.percent >= 1) {
					return true;
				} else {
					if (transItem.hasComponent(otherComponent)) {
						return false;
					} else {
						return true;
					}
				}
			}
		}

		return false;
	}

	apply(transItem: TransItem): boolean {
		// console.log('MF_SetComponentVersion: apply');

		for (const transComponent of transItem.components) {
			const otherComponent = transComponent.component.getOtherVersion(
				this.componentVersionId
			);

			if (otherComponent) {
				if (this.percent >= 1) {
					transComponent.changeVerson(this.componentVersionId);
				} else {
					const quantity = transComponent.quantity;

					transComponent.quantity = quantity * this.percent;
					transItem.addComponent(
						otherComponent,
						quantity * (1 - this.percent)
					);
				}
			}
		}

		return true;
	}

	unapply(transItem: TransItem): boolean {
		for (const transComponent of transItem.components) {
			const { component } = transComponent;
			let baseComponent: Component | undefined;

			if (component.componentVersionId === this.componentVersionId) {
				const base = Component.getComponent(component.baseComponentId);

				if (base && base.linked) {
					baseComponent = base;
				}
			}

			if (
				baseComponent &&
				baseComponent.componentVersion &&
				baseComponent.componentVersion.id !== this.componentVersionId
			) {
				if (this.percent >= 1) {
					transComponent.changeVerson(
						baseComponent.componentVersion.id
					);
				} else {
					const quantity = transComponent.quantity;

					transComponent.quantity = quantity * this.percent;
					transItem.addComponent(
						baseComponent,
						quantity * (1 - this.percent)
					);
				}
			}
		}

		return true;
	}
}

class MF_ReplaceGroupWithComponent extends ModifierFunction {
	componentGroupId: number;
	componentId: number;

	componentGroup: ComponentGroup;
	component: Component;

	constructor(modifierFunction: ModifierFunction | UnlinkedModifierFunction) {
		super(modifierFunction);

		this.componentGroupId = this.fromId;

		if (this.toId === undefined) {
			throw new Error('Missing toId for MF_ReplaceGroupWithComponent');
		}

		this.componentId = this.toId;

		this.componentGroup = throwIfUndef(
			ComponentGroup.getComponentGroup(this.componentGroupId)
		);
		this.component = throwIfUndef(Component.getComponent(this.componentId));
	}

	hasEffect(transItem: TransItem): boolean {
		for (const transComponent of transItem.components) {
			if (
				this.componentGroup.containsComponent(
					transComponent.component.id
				)
			) {
				if (transComponent.component.id !== this.componentId) {
					return true;
				}
			}
		}

		return false;
	}

	apply(transItem: TransItem): boolean {
		// console.log('MF_ReplaceGroupWithComponent: apply');

		const quantity = transItem.components.reduce(
			(acc, transComponent) =>
				this.componentGroup.containsComponent(
					transComponent.component.id
				)
					? acc + transComponent.quantity
					: acc,
			0
		);

		transItem.components = transItem.components.filter(
			transComponent =>
				!this.componentGroup.containsComponent(
					transComponent.component.id
				)
		);

		transItem.addComponent(this.component, quantity);

		return true;
	}
}

class MF_ReplaceComponent extends ModifierFunction {
	componentToReplaceId: number;
	componentId: number;
	percent: number;

	componentToReplace: Component;
	component: Component;

	constructor(modifierFunction: ModifierFunction | UnlinkedModifierFunction) {
		super(modifierFunction);

		this.componentToReplaceId = this.fromId;
		this.percent = this.fromValue || 1;

		if (this.toId === undefined) {
			throw new Error('Missing toId for MF_ReplaceComponent');
		}

		this.componentId = this.toId;

		this.componentToReplace = throwIfUndef(
			Component.getComponent(this.componentToReplaceId)
		);
		this.component = throwIfUndef(Component.getComponent(this.componentId));
	}

	hasEffect(transItem: TransItem): boolean {
		return transItem.hasComponent(this.componentToReplace);
	}

	apply(transItem: TransItem): boolean {
		// console.log('MF_ReplaceComponent: apply');

		const component = transItem.getComponent(this.componentToReplaceId)!;
		const transComponentIndex = transItem.getComponentTransComponentIndex(
			this.componentToReplaceId
		);

		let quantityToAdd: number;

		if (this.percent === 1) {
			quantityToAdd = component.quantity;
			transItem.removeComponent(this.componentToReplaceId);
		} else {
			const quantity = component.quantity;

			quantityToAdd = quantity * this.percent;
			transItem.components[transComponentIndex].quantity =
				quantity - quantityToAdd;
		}

		transItem.addComponent(this.component, quantityToAdd);

		return true;
	}
}

class MF_SetGroupReplaceGroup extends ModifierFunction {
	componentGroupToSetId: number;
	componentGroupToFillId: number;
	amount: number;

	componentGroupToSet: ComponentGroup;
	componentGroupToFill: ComponentGroup;

	constructor(modifierFunction: ModifierFunction | UnlinkedModifierFunction) {
		super(modifierFunction);

		this.componentGroupToSetId = this.fromId;
		this.amount = this.fromValue || 0;

		if (this.toId === undefined) {
			throw new Error('Missing toId for MF_SetGroupReplaceGroup');
		}

		this.componentGroupToFillId = this.toId;

		this.componentGroupToSet = throwIfUndef(
			ComponentGroup.getComponentGroup(this.componentGroupToSetId)
		);
		this.componentGroupToFill = throwIfUndef(
			ComponentGroup.getComponentGroup(this.componentGroupToFillId)
		);
	}

	hasEffect(transItem: TransItem): boolean {
		let componentAmount = 0;

		for (const transComponent of transItem.components) {
			if (
				this.componentGroupToSet.containsComponent(
					transComponent.component.id
				)
			) {
				componentAmount += transComponent.quantity || 0;
			}
		}

		if (componentAmount !== this.amount) {
			return true;
		}

		return false;
	}

	apply(transItem: TransItem): boolean {
		// console.log('MF_SetGroupReplaceGroup: apply');

		const {
			originalAmount,
			componentsToSet,
			componentsToFill
		} = transItem.components.reduce<{
			originalAmount: number;
			componentsToSet: { [transComponentId: string]: TransComponent };
			componentsToFill: { [transComponentId: string]: TransComponent };
		}>(
			(acc, transComponent) => {
				if (
					this.componentGroupToSet.containsComponent(
						transComponent.component.id
					)
				) {
					acc.componentsToSet[transComponent.id] = transComponent;
					acc.originalAmount += transComponent.quantity;
				}

				if (
					this.componentGroupToFill.containsComponent(
						transComponent.component.id
					)
				) {
					acc.componentsToFill[transComponent.id] = transComponent;
				}

				return acc;
			},
			{ originalAmount: 0, componentsToSet: {}, componentsToFill: {} }
		);

		const amountToSet = this.amount / Object.keys(componentsToSet).length;
		const amountToFill =
			(originalAmount - this.amount) /
			Object.keys(componentsToFill).length;

		for (const transComponent of transItem.components) {
			if (componentsToSet[transComponent.id]) {
				transComponent.quantity = amountToSet;
			}

			if (componentsToFill[transComponent.id]) {
				const quantity = transComponent.quantity + amountToFill;

				if (quantity <= 0) {
					transItem.removeComponent(transComponent.component.id);
				} else {
					transComponent.quantity = quantity;
				}
			}
		}

		return true;
	}
}

class MF_SplitAmongGroup extends ModifierFunction {
	componentGroupId: number;
	componentId: number;
	amount: number;
	weight: number;

	componentGroup: ComponentGroup;
	component: Component;

	constructor(modifierFunction: ModifierFunction | UnlinkedModifierFunction) {
		super(modifierFunction);

		this.componentGroupId = this.fromId;
		this.amount = this.fromValue || 0;
		this.weight = this.toValue || 0;

		if (this.toId === undefined) {
			throw new Error('Missing toId for MF_SplitAmongGroup');
		}

		this.componentId = this.toId;

		this.componentGroup = throwIfUndef(
			ComponentGroup.getComponentGroup(this.componentGroupId)
		);
		this.component = throwIfUndef(Component.getComponent(this.componentId));
	}

	hasEffect(transItem: TransItem): boolean {
		return !transItem.hasComponent(this.component, true);
	}

	apply(transItem: TransItem): boolean {
		// console.log('MF_SplitAmongGroup: apply');

		const components: { [transComponentId: string]: TransComponent } = {};
		const quantities: { [transComponentId: string]: number } = {};
		const weights: { [transComponentId: string]: number } = {};

		let maxQuantity = 0;

		for (const transComponent of transItem.components) {
			if (
				this.componentGroup.containsComponent(
					transComponent.component.id
				)
			) {
				components[transComponent.id] = transComponent;
				quantities[transComponent.id] = transComponent.quantity;

				if (transComponent.quantity > maxQuantity) {
					maxQuantity = transComponent.quantity;
				}
			}
		}

		let totalWeight = this.weight;

		for (const transComponent of Object.values(components)) {
			const weight = transComponent.quantity / maxQuantity;
			weights[transComponent.id] = weight;
			totalWeight += weight;
		}

		if (
			transItem.addComponent(
				this.component,
				(this.weight / totalWeight) * this.amount
			) !== 'ITEM_OK'
		) {
			return false;
		}

		for (const transComponentId of Object.keys(components)) {
			transItem.components[
				transItem.getTransComponentIndexByTransComponentId(
					transComponentId
				)
			].quantity =
				(weights[transComponentId] / totalWeight) * this.amount;
		}

		return true;
	}

	unapply(transItem: TransItem): boolean {
		return transItem.removeComponent(this.componentId) === 'ITEM_OK';
	}
}

class MF_AddComponent extends ModifierFunction {
	componentId: number;
	quantity: number;

	component: Component;

	constructor(modifierFunction: ModifierFunction | UnlinkedModifierFunction) {
		super(modifierFunction);

		this.componentId = this.fromId;

		if (this.fromValue === undefined) {
			throw new Error('Missing fromValue for MF_AddComponent');
		}

		this.quantity = this.fromValue;

		this.component = throwIfUndef(Component.getComponent(this.componentId));
	}

	hasEffect(transItem: TransItem): boolean {
		return true;
	}

	apply(transItem: TransItem): boolean {
		// console.log('MF_AddComponent: apply');

		transItem.addComponent(this.component, this.quantity);

		return true;
	}

	unapply(transItem: TransItem): boolean {
		transItem.removeComponent(this.componentId);

		return true;
	}
}

class MF_SetGroup extends ModifierFunction {
	componentGroupToSetId: number;
	amount: number;

	componentGroupToSet: ComponentGroup;

	constructor(modifierFunction: ModifierFunction | UnlinkedModifierFunction) {
		super(modifierFunction);

		this.componentGroupToSetId = this.fromId;

		if (this.fromValue === undefined) {
			throw new Error('Missing fromValue for MF_SetGroup');
		}

		this.amount = this.fromValue;

		this.componentGroupToSet = throwIfUndef(
			ComponentGroup.getComponentGroup(this.componentGroupToSetId)
		);
	}

	hasEffect(transItem: TransItem): boolean {
		let componentAmount = 0;

		for (const transComponent of transItem.components) {
			if (
				this.componentGroupToSet.containsComponent(
					transComponent.component.id
				)
			) {
				componentAmount += transComponent.quantity || 0;
			}
		}

		if (componentAmount !== this.amount) {
			return true;
		}

		return false;
	}

	apply(transItem: TransItem): boolean {
		// console.log('MF_SetGroup: apply');

		const componentsToSet: number[] = transItem.components
			.filter(transComponent =>
				this.componentGroupToSet.containsComponent(
					transComponent.component.id
				)
			)
			.map(transComponent =>
				transItem.getTransComponentIndexByTransComponentId(
					transComponent.id
				)
			);

		const amountToSet = this.amount / componentsToSet.length;

		for (const transComponentIndex of componentsToSet) {
			transItem.components[transComponentIndex].quantity = amountToSet;
		}

		return true;
	}
}

class MF_AddModifier extends ModifierFunction {
	modifierId: number;

	modifier: Modifier;

	constructor(modifierFunction: ModifierFunction | UnlinkedModifierFunction) {
		super(modifierFunction);

		this.modifierId = this.fromId;
		this.modifier = throwIfUndef(Modifier.getModifier(this.modifierId));
	}

	hasEffect(transItem: TransItem): boolean {
		return this.modifier.hasEffect(transItem);
	}

	apply(transItem: TransItem): boolean {
		// console.log('MF_AddModifier: apply');

		return transItem.modify(this.modifier) === 'ITEM_OK';
	}

	unapply(transItem: TransItem): boolean {
		// console.log('MF_AddModifier: unapply');

		return transItem.unModify(this.modifier) === 'ITEM_OK';
	}
}

class MF_SetGroupRatioReplaceGroup extends ModifierFunction {
	componentGroupToSetId: number;
	componentGroupToFillId: number;
	ratio: number;

	componentGroupToFill: ComponentGroup;
	componentGroupToSet: ComponentGroup;

	constructor(modifierFunction: ModifierFunction | UnlinkedModifierFunction) {
		super(modifierFunction);

		this.componentGroupToSetId = this.fromId;
		this.ratio = this.fromValue || 0;

		if (this.toId === undefined) {
			throw new Error('Missing toId for MF_SetGroupRatioReplaceGroup');
		}

		this.componentGroupToFillId = this.toId;

		this.componentGroupToFill = throwIfUndef(
			ComponentGroup.getComponentGroup(this.componentGroupToFillId)
		);
		this.componentGroupToSet = throwIfUndef(
			ComponentGroup.getComponentGroup(this.componentGroupToSetId)
		);
	}

	hasEffect(transItem: TransItem): boolean {
		if (transItem.hasComponentGroup(this.componentGroupToSet)) {
			return true;
		} else {
			return false;
		}
	}

	apply(transItem: TransItem): boolean {
		// console.log('MF_SetGroupRatioReplaceGroup: apply');

		const componentsToFill: TransComponent[] = [];
		let amountToFill = 0;

		for (const transComponent of transItem.components) {
			if (
				this.componentGroupToSet.containsComponent(
					transComponent.component.id
				)
			) {
				const originalAmount = transComponent.quantity;
				const amountToSet = originalAmount * this.ratio;

				transComponent.quantity = amountToSet;

				amountToFill += originalAmount - amountToSet;
			}

			if (
				this.componentGroupToFill.containsComponent(
					transComponent.component.id
				)
			) {
				componentsToFill.push(transComponent);
			}
		}

		amountToFill /= componentsToFill.length;

		for (const transComponent of componentsToFill) {
			const quantity = transComponent.quantity + amountToFill;

			if (quantity <= 0) {
				transItem.removeComponent(transComponent.component.id);
			} else {
				transItem.components[
					transItem.getTransComponentIndexByTransComponentId(
						transComponent.id
					)
				].quantity = quantity;
			}
		}

		return true;
	}
}

class MF_SetGroupRatioReplaceComponent extends ModifierFunction {
	componentGroupToSetId: number;
	componentToFillId: number;
	ratio: number;

	componentGroupToSet: ComponentGroup;
	componentToFill: Component;

	constructor(modifierFunction: ModifierFunction | UnlinkedModifierFunction) {
		super(modifierFunction);

		this.componentGroupToSetId = this.fromId;
		this.ratio = this.fromValue || 0;

		if (!this.toId) {
			throw new Error(
				'Missing toId for MF_SetGroupRatioReplaceComponent'
			);
		}

		this.componentToFillId = this.toId;

		this.componentGroupToSet = throwIfUndef(
			ComponentGroup.getComponentGroup(this.componentGroupToSetId)
		);
		this.componentToFill = throwIfUndef(
			Component.getComponent(this.componentToFillId)
		);
	}

	hasEffect(transItem: TransItem): boolean {
		if (transItem.hasComponentGroup(this.componentGroupToSet)) {
			return true;
		} else {
			return false;
		}
	}

	apply(transItem: TransItem): boolean {
		// console.log('MF_SetGroupRatioReplaceComponent: apply');

		let componentToFill: TransComponent | undefined;
		let amountToFill = 0;

		for (const transComponent of transItem.components) {
			if (
				this.componentGroupToSet.containsComponent(
					transComponent.component.id
				)
			) {
				const originalAmount = transComponent.quantity;
				const amountToSet = originalAmount - this.ratio;

				transComponent.quantity = amountToSet;

				amountToFill += originalAmount - amountToSet;
			}

			if (this.componentToFillId === transComponent.component.id) {
				componentToFill = transComponent;
			}
		}

		if (componentToFill) {
			transItem.components[
				transItem.getTransComponentIndexByTransComponentId(
					componentToFill.id
				)
			].quantity += amountToFill;
		} else {
			componentToFill = new TransComponent(
				this.componentToFill,
				amountToFill
			);

			transItem.addTransComponent(componentToFill);
		}

		return true;
	}
}

class MF_SetGroupComponentVersion extends ModifierFunction {
	componentGroupId: number;
	componentVersionId: number;
	percent: number;

	componentGroup: ComponentGroup;

	constructor(modifierFunction: ModifierFunction | UnlinkedModifierFunction) {
		super(modifierFunction);

		this.componentGroupId = this.fromId;
		this.percent = this.fromValue || 1;

		if (this.toId === undefined) {
			throw new Error('Missing toId for MF_SetGroupComponentVersion');
		}

		this.componentVersionId = this.toId;

		this.componentGroup = throwIfUndef(
			ComponentGroup.getComponentGroup(this.componentGroupId)
		);
	}

	hasEffect(transItem: TransItem): boolean {
		for (const transComponent of transItem.components) {
			if (
				this.componentGroup.containsComponent(
					transComponent.component.id
				)
			) {
				continue;
			}

			const otherComponent = transComponent.component.getOtherVersion(
				this.componentVersionId
			);

			if (otherComponent) {
				if (this.percent >= 1) {
					return true;
				} else {
					if (transItem.hasComponent(otherComponent)) {
						return false;
					} else {
						return true;
					}
				}
			}
		}

		return false;
	}

	apply(transItem: TransItem): boolean {
		// console.log('MF_SetGroupComponentVersion: apply');

		for (const transComponent of transItem.components) {
			const { component } = transComponent;

			if (!this.componentGroup.containsComponent(component.id)) {
				continue;
			}

			const otherComponent = component.getOtherVersion(
				this.componentVersionId
			);

			if (otherComponent) {
				if (this.percent >= 1) {
					transComponent.changeVerson(this.componentVersionId);
				} else {
					const { quantity } = transComponent;

					transComponent.quantity = quantity * this.percent;
					transItem.addComponent(
						otherComponent,
						quantity * (1 - this.percent)
					);
				}
			}
		}

		return true;
	}
}

class MF_MultiplyGroup extends ModifierFunction {
	componentGroupId: number;
	multiplier: number;

	componentGroup: ComponentGroup;

	constructor(modifierFunction: ModifierFunction | UnlinkedModifierFunction) {
		super(modifierFunction);

		this.componentGroupId = this.fromId;

		if (this.fromValue === undefined) {
			throw new Error('Missing fromValue for MF_MultiplyGroup');
		}

		this.multiplier = this.fromValue;

		this.componentGroup = throwIfUndef(
			ComponentGroup.getComponentGroup(this.componentGroupId)
		);
	}

	hasEffect(transItem: TransItem): boolean {
		return transItem.hasComponentGroup(this.componentGroup);
	}

	apply(transItem: TransItem): boolean {
		// console.log('MF_MultiplyGroup: apply');

		transItem.components = transItem.components.map(component => {
			if (this.componentGroup.containsComponent(component.component.id)) {
				component.quantity *= this.multiplier;
			}

			return component;
		});

		return true;
	}
}

class MF_AddRecipeComponentUpcharge extends ModifierFunction {
	componentId: number;
	doesCostApply_: boolean;

	component: Component;

	currentComponentsInGroup: number = 0;

	constructor(modifierFunction: ModifierFunction | UnlinkedModifierFunction) {
		super(modifierFunction);

		this.componentId = this.fromId;
		this.doesCostApply_ = Boolean(this.fromValue);

		this.component = throwIfUndef(Component.getComponent(this.componentId));
	}

	hasEffect(transItem: TransItem): boolean {
		if (transItem.hasComponent(this.component, true)) {
			return false;
		}

		return true;
	}

	hasModifierFunction(transItem: TransItem): boolean {
		if (transItem.hasComponent(this.component)) {
			return true;
		}

		return false;
	}

	doesCostApply(transItem: TransItem): boolean {
		if (this.doesCostApply_) {
			return true;
		}

		for (const componentGroup of this.component.componentGroups) {
			// If true, we need to find out how many Components from this ComponentGroup
			// can be added to the recipe before upcharge
			if (transItem.hasComponentGroup(componentGroup)) {
				// Get the quantity of Components in the ComponentGroup allowed in the recipe
				const componentGroupQuantity = transItem.item.getQuantity(
					this.componentId
				);

				// Counts number of Components in this ComponentGroup in the TransItem
				let componentCount = 0;

				// Loop through all the Components in the ComponentGroup, and check if the
				// TransItem currently has the Component applied.  If so, add 1 to
				// componentCount
				for (const component of componentGroup.components) {
					componentCount += transItem.getComponentQuantity(
						component.id
					);
				}

				// If the componentCount is at or above the componentGroupQuantity,
				// adding the next Component should be upcharged.
				if (componentCount >= componentGroupQuantity) {
					return true;
				}

				return false;
			}
		}

		return true;
	}

	apply(transItem: TransItem): boolean {
		// console.log('MF_AddRecipeComponentUpcharge: apply');

		return transItem.addRecipeComponent(this.componentId) === 'ITEM_OK';
	}

	unapply(transItem: TransItem): boolean {
		if (transItem.item.inRecipe(this.component, false, false)) {
			if (transItem.removeComponent(this.componentId) !== 'ITEM_OK') {
				return false;
			}
		} else {
			return false;
		}

		return true;
	}
}

class MF_AddComponentGroupRatioComponent extends ModifierFunction {
	componentId: number;

	component: Component;

	constructor(modifierFunction: ModifierFunction | UnlinkedModifierFunction) {
		super(modifierFunction);

		this.componentId = this.fromId;

		this.component = throwIfUndef(Component.getComponent(this.componentId));
	}

	hasEffect(transItem: TransItem): boolean {
		const syrupCount = transItem.getSyrupCount(
			throwIfUndef(ComponentGroup.getComponentGroup(Config.syrup))
		);

		if (syrupCount === Config.maxSyrup) {
			return false;
		}

		return true;
	}

	apply(transItem: TransItem): boolean {
		// console.log('MF_AddComponentGroupRatioComponent: apply');

		transItem.addComponent(this.component, 1);

		return true;
	}

	unapply(transItem: TransItem): boolean {
		// console.log('MF_AddComponentGroupRatioComponent: unapply');

		transItem.removeComponent(this.componentId);

		return true;
	}
}

class MF_SetPrice extends ModifierFunction {
	modifierId: number;
	price: number;

	constructor(modifierFunction: ModifierFunction | UnlinkedModifierFunction) {
		super(modifierFunction);

		this.modifierId = this.fromId;

		if (this.fromValue === undefined) {
			throw new Error('Missing fromValue for MF_SetPrice');
		}

		this.price = this.fromValue;
	}

	hasEffect(transItem: TransItem): boolean {
		const transModifierIds = transItem.selectedTransModifiers.map(
			({ modifier }) => modifier.id
		);

		let isTransModifier = false;
		for (const modifierId of transModifierIds) {
			if (modifierId === this.modifierId) {
				isTransModifier = true;
			}
		}

		if (!isTransModifier) {
			return false;
		}

		return true;
	}

	apply(transItem: TransItem): boolean {
		// console.log('MF_SetPrice: apply');

		let applied = false;

		for (const selectedTransModifier of transItem.selectedTransModifiers) {
			let amount = 0;

			if (selectedTransModifier.modifier.id === this.modifierId) {
				if (Money.notEqual(selectedTransModifier.price, this.price)) {
					amount = this.price - selectedTransModifier.price.valueOf();
				}

				selectedTransModifier.price.add(amount);
				applied = true;

				break;
			}
		}

		return applied;
	}
}

class MF_SetFoam extends ModifierFunction {
	foamStatus: FoamStatus;

	constructor(modifierFunction: ModifierFunction | UnlinkedModifierFunction) {
		super(modifierFunction);

		switch (this.fromId) {
			case 0:
				this.foamStatus = 'NO_FOAM' as FoamStatus;
				break;
			case 1:
				this.foamStatus = 'LATTE' as FoamStatus;
				break;
			case 2:
				this.foamStatus = 'LATTICCINO' as FoamStatus;
				break;
			case 3:
				this.foamStatus = 'CAPPUCCINO' as FoamStatus;
				break;
			case 4:
				this.foamStatus = 'DRY_CAPPUCCINO' as FoamStatus;
				break;
			case 5:
				this.foamStatus = 'LIGHT_FOAM' as FoamStatus;
				break;
			default:
				throw new Error('Invalid fromId for MF_SetFoam');
		}
	}

	hasEffect(transItem: TransItem): boolean {
		if (this.foamStatus === transItem.foamStatus) {
			return false;
		}

		return true;
	}

	apply(transItem: TransItem): boolean {
		// console.log('MF_SetFoam: apply');

		transItem.setFoamStatus(this.foamStatus);

		return true;
	}
}

class MF_SetGroupHasEffect extends ModifierFunction {
	componentGroupToSetId: number;
	amount: number;

	componentGroupToSet: ComponentGroup;

	constructor(modifierFunction: ModifierFunction | UnlinkedModifierFunction) {
		super(modifierFunction);

		this.componentGroupToSetId = this.fromId;

		if (this.fromValue === undefined) {
			throw new Error('Missing fromValue for MF_SetGroupHasEffect');
		}

		this.amount = this.fromValue;

		this.componentGroupToSet = throwIfUndef(
			ComponentGroup.getComponentGroup(this.componentGroupToSetId)
		);
	}

	hasEffect(transItem: TransItem): boolean {
		return true;
	}

	apply(transItem: TransItem): boolean {
		// console.log('MF_SetGroupHasEffect: apply');

		const componentsToSet: number[] = [];

		for (let i = 0; i < transItem.components.length; ++i) {
			if (
				this.componentGroupToSet.containsComponent(
					transItem.components[i].component.id
				)
			) {
				componentsToSet.push(i);
			}
		}

		const amountToSet = this.amount / componentsToSet.length;

		for (const transComponentIndex of componentsToSet) {
			transItem.components[transComponentIndex].quantity += amountToSet;
		}

		return true;
	}
}

class MF_AddInventoryComponents extends ModifierFunction {
	componentGroupId: number;
	amount: number;

	componentGroup: ComponentGroup;
	componentsToAdd: TransComponent[] = [];

	constructor(modifierFunction: ModifierFunction | UnlinkedModifierFunction) {
		super(modifierFunction);

		this.componentGroupId = this.fromId;
		this.amount = this.fromValue || 0;

		this.componentGroup = throwIfUndef(
			ComponentGroup.getComponentGroup(this.componentGroupId)
		);
	}

	hasEffect(transItem: TransItem): boolean {
		const recipe = transItem.item.recipe
			.map(ingredient => ingredient.component)
			.filter(c => c !== undefined);

		const filteredComponentGroupComponents = this.componentGroup.components.filter(
			({ id }) =>
				recipe.find(component => component!.id === id) !== undefined
		);

		for (const componentGroupComponent of filteredComponentGroupComponents) {
			for (const transComponent of transItem.components) {
				if (
					componentGroupComponent.inventoryItem &&
					transComponent.component.inventoryItem
				) {
					if (
						Number(componentGroupComponent.inventoryItem.id) ===
							Number(transComponent.component.inventoryItem.id) &&
						!transItem.hasComponent(componentGroupComponent) &&
						(transComponent.quantity || 0) > 0
					) {
						this.componentsToAdd.push(transComponent);
					}
				}
			}
		}

		if (this.componentsToAdd.length > 0) {
			return true;
		}

		return false;
	}

	apply(transItem: TransItem): boolean {
		// console.log('MF_AddInventoryComponents: apply');

		for (const transComponent of this.componentsToAdd) {
			transItem.addTransComponent(transComponent);
		}

		return true;
	}
}

class MF_RemoveModifierGroup extends ModifierFunction {
	modifierGroupId: number;

	modifierGroup: ModifierGroup;

	constructor(modifierFunction: ModifierFunction | UnlinkedModifierFunction) {
		super(modifierFunction);

		this.modifierGroupId = this.fromId;

		this.modifierGroup = throwIfUndef(
			ModifierGroup.getModifierGroup(this.modifierGroupId)
		);
	}

	hasEffect(transItem: TransItem): boolean {
		if (transItem.hasModifierGroup(this.modifierGroup)) {
			return true;
		}

		return false;
	}

	apply(transItem: TransItem): boolean {
		// console.log('MF_RemoveModifierGroup: apply');

		for (const { modifier } of transItem.modifiers) {
			if (this.modifierGroup.hasModifier(modifier.id)) {
				transItem.removeModifier(modifier);
			}
		}

		return true;
	}
}

class MF_RemoveModifier extends ModifierFunction {
	modifierId: number;

	constructor(modifierFunction: ModifierFunction | UnlinkedModifierFunction) {
		super(modifierFunction);

		this.modifierId = this.fromId;
	}

	hasEffect(transItem: TransItem): boolean {
		if (transItem.hasModifierById(this.modifierId)) {
			return true;
		}

		return false;
	}

	apply(transItem: TransItem): boolean {
		// console.log('MF_RemoveModifier: apply');

		for (const { modifier } of transItem.modifiers) {
			if (modifier.id === this.modifierId) {
				transItem.removeModifier(modifier);
			}
		}

		return true;
	}
}
