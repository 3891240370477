import {
	TransItem,
	ModifierGroupModel,
	ModifierScaleModel,
	ProductVersionModel,
	SizeModel
} from '../lib';

export const loadItem = () => {
	console.log('loadItem');

	SizeModel.sizeModel.sizes = TransItem.transItem.item.product.sizes;
	if (TransItem.transItem.item.size) {
		SizeModel.sizeModel.selectSize(TransItem.transItem.item.size);
	}

	new ModifierScaleModel(TransItem.transItem);
	new ModifierGroupModel(TransItem.transItem);

	ProductVersionModel.productVersionModel.productVersions =
		TransItem.transItem.item.product.productVersions;
	if (TransItem.transItem.item.product.productVersion) {
		ProductVersionModel.productVersionModel.selectProductVersion(
			TransItem.transItem.item.product.productVersion
		);
	}
};
