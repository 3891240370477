import {
	Database,
	LinkedUnit,
	NumericDictionary,
	UnlinkedUnit as UnlinkedUnitInterface,
	Serializable,
	Serialized
} from '../types';

export class UnlinkedUnit implements UnlinkedUnitInterface {
	readonly linked = false;

	unitId: number;
	unitName: string;
	unitNamePlural?: string;

	isValid = false;

	constructor(
		unitFields: Database.MappedUnitsRow,
		tables: Database.MappedTables
	) {
		this.unitId = unitFields.unitId;
		this.unitName = unitFields.unitName;
		this.unitNamePlural =
			unitFields.unitNamePlural || `${unitFields.unitName}s`;

		this.isValid = true;
	}
}

export default class Unit implements LinkedUnit, Serializable<Serialized.Unit> {
	readonly linked = true;

	id: number;
	unitId: number;
	unitName: string;
	unitNamePlural: string;

	static initialized = false;
	static unitIds: number[] = [];
	static units: NumericDictionary<Unit | UnlinkedUnit | undefined> = {};

	static isUnit(unit: UnlinkedUnit | Unit): unit is Unit {
		return unit.linked;
	}

	static getUnit(unitId: number): Unit | undefined {
		let unit = this.units[unitId];

		if (unit) {
			if (this.isUnit(unit)) {
				return unit;
			}

			return new Unit(unit);
		}
	}

	static async initialize(tables: Database.MappedTables) {
		this.initialized = false;
		this.unitIds = [];
		this.units = {};

		for (const row of tables.units) {
			const unit = new UnlinkedUnit(row, tables);

			if (unit.isValid) {
				this.unitIds.push(unit.unitId);
				this.units[unit.unitId] = unit;
			}
		}

		if (this.unitIds.length > 0) this.initialized = true;

		return this.initialized;
	}

	static async linkAll() {
		for (const unit of Object.values(Unit.units)) {
			if (unit) {
				// try {
				new Unit(unit);
				// } catch (err) {
				// 	// console.error(err);
				// }
			}
		}

		return true;
	}

	constructor(unit: UnlinkedUnit | Unit) {
		Unit.units[unit.unitId] = this;

		this.id = unit.unitId;
		this.unitId = unit.unitId;
		this.unitName = unit.unitName;

		if (unit.linked) {
			this.unitNamePlural = unit.unitNamePlural;
		}
		this.unitNamePlural = unit.unitNamePlural || `${unit.unitName}s`;
	}

	serialize(): Serialized.Unit {
		return {
			id: this.id,
			unitName: this.unitName,
			unitNamePlural: this.unitNamePlural
		};
	}
}
