import {
	Database,
	LinkedProductGroup,
	NumericDictionary,
	Serialized,
	Serializable,
	UnlinkedProductGroup as UnlinkedProductGroupInterface
} from '../types';

import { sortAlphabetical, serialize } from '../utils';

import Product from './Product';
import Size from './Size';

export class UnlinkedProductGroup implements UnlinkedProductGroupInterface {
	readonly linked = false;
	isValid = false;

	productGroupId: number;
	name: string;
	parentId?: number;
	priority?: number;
	isVisible: boolean;

	childProductGroupIds: number[];
	visibleChildProductGroupIds: number[];
	sizeIds: number[];
	productIds: number[];
	priorityProductIds: number[];
	nonPriorityProductIds: number[];

	constructor(
		productGroupFields: Database.MappedProductGroupsRow,
		tables: Database.MappedTables
	) {
		this.productGroupId = productGroupFields.productGroupId;
		this.name = productGroupFields.name;
		this.parentId = productGroupFields.parentId;
		this.priority = productGroupFields.priority;
		this.isVisible = productGroupFields.isVisible;

		const thisFilter = ({ productGroupId }: { productGroupId: number }) =>
			productGroupId === this.productGroupId;

		const priorityChildProductGroups = tables.productGroups
			.filter(
				({ parentId, priority }) =>
					priority &&
					parentId &&
					priority > 0 &&
					parentId === this.productGroupId
			)
			.sort(
				(a, b) =>
					(a.priority || -1) - (b.priority || -1) ||
					sortAlphabetical(a.name, b.name)
			);

		this.childProductGroupIds = priorityChildProductGroups.map(
			({ productGroupId }) => productGroupId
		);

		this.visibleChildProductGroupIds = priorityChildProductGroups
			.filter(({ isVisible }) => isVisible)
			.map(({ productGroupId }) => productGroupId);

		const nonPriorityProductGroups = tables.productGroups
			.filter(
				({ parentId, priority }) =>
					parentId &&
					parentId === this.productGroupId &&
					(priority === undefined ||
						priority === null ||
						priority === 0)
			)
			.sort((a, b) => sortAlphabetical(a.name, b.name));

		this.childProductGroupIds = [
			...this.childProductGroupIds,
			...nonPriorityProductGroups.map(
				({ productGroupId }) => productGroupId
			)
		];

		this.visibleChildProductGroupIds = [
			...this.visibleChildProductGroupIds,
			...nonPriorityProductGroups
				.filter(({ isVisible }) => isVisible)
				.map(({ productGroupId }) => productGroupId)
		];

		this.productIds = tables.productGroupsProducts
			.filter(thisFilter)
			.map(({ productId }) => productId);

		this.priorityProductIds = tables.productGroupsProducts
			.filter(
				productGroupProduct =>
					thisFilter(productGroupProduct) &&
					productGroupProduct.priority &&
					productGroupProduct.priority > 0
			)
			.map(({ productId }) => productId);

		this.nonPriorityProductIds = tables.productGroupsProducts
			.filter(
				productGroupProduct =>
					thisFilter(productGroupProduct) &&
					(productGroupProduct.priority === undefined ||
						productGroupProduct.priority === null ||
						productGroupProduct.priority === 0)
			)

			.map(({ productId }) => productId);

		this.sizeIds = tables.items
			.filter(
				item =>
					item.sizeId !== undefined &&
					item.sizeId !== 0 &&
					this.productIds.includes(item.productId)
			)
			.map(item => {
				const size = tables.sizes.find(
					size => size.sizeId === item.sizeId
				);

				if (!size) {
					throw new Error(
						'Filed to find size ' +
							item.sizeId +
							' for item' +
							item.itemId
					);
				}

				return {
					sizeId: item.sizeId!,
					priority: size.priority
				};
			})
			.sort((a, b) => a.priority - b.priority)
			.map(({ sizeId }) => sizeId);

		this.isValid = true;
	}
}

export default class ProductGroup
	implements LinkedProductGroup, Serializable<Serialized.ProductGroup> {
	readonly linked = true;

	id: number;

	productGroupId: number;
	name: string;
	parentId?: number;
	priority?: number;
	isVisible: boolean;

	childProductGroupIds: number[];
	visibleChildProductGroupIds: number[];
	sizeIds: number[];
	productIds: number[];
	priorityProductIds: number[];
	nonPriorityProductIds: number[];

	parent?: ProductGroup;
	childProductGroups: ProductGroup[] = [];
	visibleChildProductGroups: ProductGroup[] = [];
	sizes: Size[] = [];
	products: Product[] = [];
	visibleProducts: Product[] = [];
	priorityProducts: Product[] = [];
	visiblePriorityProducts: Product[] = [];
	nonPriorityProducts: Product[] = [];
	visibleNonPriorityProducts: Product[] = [];

	static initialized = false;
	static productGroupIds: number[] = [];
	static productGroups: NumericDictionary<
		ProductGroup | UnlinkedProductGroup | undefined
	> = {};
	static visibleProductGroups: NumericDictionary<
		ProductGroup | UnlinkedProductGroup | undefined
	> = {};

	static isProductGroup(
		productGroup: UnlinkedProductGroup | ProductGroup
	): productGroup is ProductGroup {
		return productGroup.linked;
	}

	static getProductGroup(productGroupId: number): ProductGroup | undefined {
		const productGroup = this.productGroups[productGroupId];

		if (productGroup) {
			if (this.isProductGroup(productGroup)) {
				return productGroup;
			}

			// try {
			return new ProductGroup(productGroup);
			// } catch (err) {
			// 	console.error(err);
			// }
		}
	}

	static getProductGroupOrThrow(productGroupId: number): ProductGroup {
		const productGroup = this.productGroups[productGroupId];

		if (productGroup) {
			if (productGroup.linked) {
				return productGroup;
			}

			return new ProductGroup(productGroup);
		}

		throw new Error('Failed to get productGroup id ' + productGroupId);
	}

	static async initialize(tables: Database.MappedTables) {
		this.initialized = false;
		this.productGroupIds = [];
		this.productGroups = {};

		for (const row of tables.productGroups) {
			// try {
			const productGroup = new UnlinkedProductGroup(row, tables);

			if (productGroup.isValid) {
				this.productGroupIds.push(productGroup.productGroupId);
				this.productGroups[productGroup.productGroupId] = productGroup;

				if (productGroup.isVisible) {
					this.visibleProductGroups[
						productGroup.productGroupId
					] = productGroup;
				}
			}
			// } catch (err) {
			// 	// console.error(err);
			// 	continue;
			// }
		}

		this.initialized = this.productGroupIds.length > 0;

		return this.initialized;
	}

	static async linkAll() {
		for (const productGroup of Object.values(ProductGroup.productGroups)) {
			if (productGroup) {
				// try {
				new ProductGroup(productGroup);
				// } catch (err) {
				// 	console.error(err);
				// }
			}
		}

		return true;
	}

	constructor(productGroup: ProductGroup | UnlinkedProductGroup) {
		ProductGroup.productGroups[productGroup.productGroupId] = this;

		this.id = productGroup.productGroupId;

		this.productGroupId = productGroup.productGroupId;
		this.name = productGroup.name;
		this.parentId = productGroup.parentId;
		this.priority = productGroup.priority;
		this.isVisible = productGroup.isVisible;

		this.childProductGroupIds = productGroup.childProductGroupIds;
		this.visibleChildProductGroupIds =
			productGroup.visibleChildProductGroupIds;
		this.sizeIds = productGroup.sizeIds;
		this.productIds = productGroup.productIds;
		this.priorityProductIds = productGroup.priorityProductIds;
		this.nonPriorityProductIds = productGroup.nonPriorityProductIds;

		if (productGroup.linked) {
			this.parent = productGroup.parent;
			this.childProductGroups = productGroup.childProductGroups;
			this.visibleChildProductGroups =
				productGroup.visibleChildProductGroups;
			this.sizes = productGroup.sizes;
			this.products = productGroup.products;
			this.visibleProducts = productGroup.visibleProducts;
			this.priorityProducts = productGroup.priorityProducts;
			this.visiblePriorityProducts = productGroup.visiblePriorityProducts;
			this.nonPriorityProducts = productGroup.nonPriorityProducts;
			this.visibleNonPriorityProducts =
				productGroup.visibleNonPriorityProducts;
		} else {
			// link parent
			if (this.parentId) {
				if (this.parentId === this.id) {
					this.parent = this;
				} else {
					const parent = ProductGroup.getProductGroup(this.parentId);

					if (!parent) {
						throw new Error(
							'Failed to link product group to parent'
						);
					}

					this.parent = parent;
				}
			}

			// link child product groups
			for (const id of this.childProductGroupIds) {
				if (id === this.id) {
					this.childProductGroups.push(this);
				} else {
					const childProductGroup = ProductGroup.getProductGroup(id);

					if (!childProductGroup) {
						console.log(
							'ProductGroup.productGroups',
							ProductGroup.productGroups
						);
						throw new Error(
							'Failed to link product group ' +
								this.id +
								' to child product group ' +
								id
						);
					}

					this.childProductGroups.push(childProductGroup);
				}
			}

			// link visible child product groups
			for (const id of this.visibleChildProductGroupIds) {
				if (id === this.id) {
					this.visibleChildProductGroups.push(this);
				} else {
					const visibleChildProductGroup = ProductGroup.getProductGroup(
						id
					);

					if (!visibleChildProductGroup) {
						throw new Error(
							'Failed to link product group to visible child product groups'
						);
					} else {
						this.visibleChildProductGroups.push(
							visibleChildProductGroup
						);
					}
				}
			}

			// link products and visible products
			for (const id of this.productIds) {
				const product = Product.getProduct(id);

				if (!product) {
					throw new Error('Failed to link product group to products');
				} else {
					this.products.push(product);

					if (product.isVisible) {
						this.visibleProducts.push(product);
					}
				}
			}

			// link priority products and visible priority products
			for (const id of this.priorityProductIds) {
				const priorityProduct = Product.getProduct(id);

				if (!priorityProduct) {
					throw new Error(
						'Failed to link priorityProduct group to priority products'
					);
				} else {
					this.priorityProducts.push(priorityProduct);

					if (priorityProduct.isVisible) {
						this.visiblePriorityProducts.push(priorityProduct);
					}
				}
			}

			// link non priorirty products and visible non priority products
			for (const id of this.nonPriorityProductIds) {
				const nonPriorityProduct = Product.getProduct(id);

				if (!nonPriorityProduct) {
					throw new Error(
						'Failed to link nonPriorityProduct group to non priority products'
					);
				} else {
					this.nonPriorityProducts.push(nonPriorityProduct);

					if (nonPriorityProduct.isVisible) {
						this.visibleNonPriorityProducts.push(
							nonPriorityProduct
						);
					}
				}
			}

			// link sizes
		}
	}

	containsProduct(product: Product): boolean {
		if (!product) return false;

		if (this.products.some(({ id }) => product.id === id)) return true;

		return this.childProductGroups.some(child =>
			child.containsProduct(product)
		);
	}

	serialize(): Serialized.ProductGroup {
		return {
			id: this.id,
			name: this.name,
			isVisible: this.isVisible,
			priority: this.priority,
			parentId: this.parentId,
			childProductGroupIds: this.childProductGroupIds,
			visibleChildProductGroupIds: this.visibleChildProductGroupIds,
			sizes: serialize(this.sizes),
			products: serialize(this.products),
			visibleProducts: serialize(this.visibleProducts),
			priorityProducts: serialize(this.priorityProducts),
			visiblePriorityProducts: serialize(this.visiblePriorityProducts),
			nonPriorityProducts: serialize(this.nonPriorityProducts),
			visibleNonPriorityProducts: serialize(
				this.visibleNonPriorityProducts
			)
		};
	}
}
