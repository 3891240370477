import { OmitTypename, Database } from '../types';

type ConfigRows = OmitTypename<Database.MappedConfigRow>[];

type Settings =
	| 'AlwaysTax'
	| 'CappuccinoProductId'
	| 'Catering'
	| 'CoffeeRefill'
	| 'CostApplies'
	| 'CustomerMug'
	| 'DigitalOrderPriceTier'
	| 'DryCappuccino'
	| 'EnableOnlineOrders'
	| 'EnableOrderAhead'
	| 'EnableTips'
	| 'InAMug'
	| 'IsOnlineOrdering'
	| 'Latticcino'
	| 'LightFoam'
	| 'LiquidBase'
	| 'LiquidBaseAdjust'
	| 'LiquidBaseAdjustable'
	| 'LiquidBaseAdjustment'
	| 'Locale'
	| 'MaxSyrup'
	| 'MilkFoam'
	| 'MilkFoamLiquidPercent'
	| 'PriceTier'
	| 'Region'
	| 'State'
	| 'Syrup'
	| 'Whip'
	| 'WithRoom';

export default class Config {
	static configRows: ConfigRows;

	static alwaysTax: number;
	static cappuccinoProductId: number;
	static catering: number;
	static carryoutTax: boolean = true;
	static coffeeRefill: number;
	static costApplies: number;
	static customerMug: number;
	static digitalOrderPriceTier: number;
	static dryCappuccino: number;
	static enableOnlineOrders: number;
	static enableOrderAhead: number;
	static enableTips: number;
	static inAMug: number;
	static isOnlineOrdering: boolean;
	static latticcino: number;
	static lightFoam: number;
	static liquidBase: number;
	static liquidBaseAdjust: number;
	static liquidBaseAdjustable: number;
	static liquidBaseAdjustment: number;
	static locale: number;
	static maxSyrup: number;
	static milkFoam: number;
	static milkFoamLiquidPercent: number;
	static regionId: number;
	static storeId: number;
	static stateId: number;
	static syrup: number;
	static tierId: number;
	static whip: number;
	static withRoom: number;

	static get(setting: Settings, fallback?: string): string | undefined {
		return (
			this.configRows.find(row => row.setting === setting)?.value ??
			fallback
		);
	}

	static getBool(setting: Settings): boolean {
		const value = this.get(setting);

		if (value) {
			return value === '1';
		}

		return false;
	}

	static getNum(setting: Settings, fallback: number = 0): number {
		const value = this.get(setting);

		if (value) {
			const num = Number(value);

			if (!isNaN(num)) {
				return Number(value);
			}
		}

		return fallback;
	}

	static async initialize(tables: Database.MappedTables, storeId: number) {
		this.configRows = [...tables.config].sort(
			(a, b) => b.storeId - a.storeId
		);

		this.storeId = storeId;

		this.alwaysTax = this.getNum('AlwaysTax');
		this.cappuccinoProductId = this.getNum('CappuccinoProductId');
		this.catering = this.getNum('Catering');
		this.coffeeRefill = this.getNum('CoffeeRefill');
		this.costApplies = this.getNum('CostApplies');
		this.customerMug = this.getNum('CustomerMug');
		this.digitalOrderPriceTier = this.getNum('DigitalOrderPriceTier');
		this.dryCappuccino = this.getNum('DryCappuccino');
		this.enableOnlineOrders = this.getNum('EnableOnlineOrders');
		this.enableOrderAhead = this.getNum('EnableOrderAhead');
		this.enableTips = this.getNum('EnableTips');
		this.inAMug = this.getNum('InAMug');
		this.isOnlineOrdering = this.getBool('IsOnlineOrdering');
		this.latticcino = this.getNum('Latticcino');
		this.lightFoam = this.getNum('LightFoam');
		this.liquidBase = this.getNum('LiquidBase');
		this.liquidBaseAdjust = this.getNum('LiquidBaseAdjust');
		this.liquidBaseAdjustable = this.getNum('LiquidBaseAdjustable');
		this.liquidBaseAdjustment = this.getNum('LiquidBaseAdjustment');
		this.locale = this.getNum('Locale');
		this.maxSyrup = this.getNum('MaxSyrup');
		this.milkFoam = this.getNum('MilkFoam');
		this.milkFoamLiquidPercent = this.getNum('MilkFoamLiquidPercent');
		this.tierId = this.getNum('PriceTier');
		this.regionId = this.getNum('Region');
		this.stateId = this.getNum('State');
		this.syrup = this.getNum('Syrup');
		this.whip = this.getNum('Whip');
		this.withRoom = this.getNum('WithRoom');
	}
}
