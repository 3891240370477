import { Serialized } from '../types';

import { Transaction, TransItemModel } from '../lib';

export const initTransactions = async (
	transactions?: Serialized.Transaction[]
) => {
	console.log('initTransactions');

	new TransItemModel([]);

	Transaction.initialize(transactions);

	const currentTransaction = Transaction.transactions.getCurrentTransaction();

	if (currentTransaction === undefined || !currentTransaction.isOpen) {
		Transaction.transactions.pickNewestOpenTransactionOrCreate();
	}
};
