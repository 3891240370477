import {
	LinkedProduct,
	NumericDictionary,
	Serializable,
	Serialized,
	Database,
	UnlinkedProduct as UnlinkedProductInterface
} from '../types';

import Config from './Config';
import Item from './Item';
import ProductGroup from './ProductGroup';
import ProductVersion from './ProductVersion';
import Size from './Size';

export class UnlinkedProduct implements UnlinkedProductInterface {
	readonly linked = false;
	isValid = false;

	productId: number;
	name: string;
	ticketName?: string;
	otherNames?: string;
	printable: boolean;
	productVersionId?: number;
	tokenProductId?: number;
	defaultSizeId?: number;
	autoModify: boolean;
	includeInSales: boolean;

	itemId?: number;
	productVersionIds: number[] = [];
	otherProductIds: number[] = [];
	sizeIds: number[] = [];
	itemIds: number[] = [];
	productGroupIds: number[];
	isVisible: boolean;

	constructor(
		productFields: Database.MappedProductsRow,
		tables: Database.MappedTables
	) {
		this.productId = productFields.productId;
		this.name = productFields.name;
		this.ticketName = productFields.ticketName;
		this.otherNames = productFields.otherNames;
		this.printable = productFields.printable;
		this.productVersionId = productFields.productVersionId;
		this.tokenProductId = productFields.tokenProductId;
		this.defaultSizeId = productFields.defaultSizeId;
		this.autoModify = productFields.autoModify;
		this.includeInSales = productFields.includeInSales;

		const thisFilter = ({ productId }: { productId: number }) =>
			productId === this.productId;

		// init other product ids and product version ids
		if (this.tokenProductId) {
			const [otherProductIds, productVersionIds] = tables.products
				.filter(
					product =>
						product.tokenProductId &&
						product.tokenProductId === this.tokenProductId &&
						!thisFilter(product)
				)
				.map(product => {
					const productVersion = tables.productVersions.find(
						productVersion =>
							productVersion.productVersionId ===
							product.productVersionId
					);

					if (!productVersion) {
						throw new Error(
							'Failed to find product version with id' +
								product.productVersionId
						);
					}

					return [
						product.productId,
						productVersion.productVersionId,
						productVersion.priority
					];
				})
				.sort(([, , a], [, , b]) => a - b)
				.reduce<[number[], number[]]>(
					(
						[otherProductIds, productVersionIds],
						[otherProductId, productVersionId]
					) => [
						[...otherProductIds, otherProductId],
						[...productVersionIds, productVersionId]
					],
					[[], []]
				);

			this.otherProductIds = otherProductIds;
			this.productVersionIds = productVersionIds;
		}

		const itemsSizes = tables.items
			.filter(thisFilter)
			.map(item => ({
				...item,
				size: tables.sizes.find(
					size => size.sizeId === item.sizeId
				) || { priority: -1 }
			}))
			.sort((a, b) => a.size.priority - b.size.priority);

		for (const { itemId, sizeId } of itemsSizes) {
			if (!sizeId) {
				if (this.itemId) {
					continue;
				}

				this.itemId = itemId;
				continue;
			}

			this.sizeIds.push(sizeId);
			this.itemIds.push(itemId);
		}

		if (this.itemIds.length === 0 && !this.itemId) {
			throw new Error('Product has no items associated with it.');
		}

		this.productGroupIds = tables.productGroupsProducts
			.filter(thisFilter)
			.map(({ productGroupId }) => productGroupId);

		if (!this.itemId && !this.defaultSizeId) {
			this.defaultSizeId = this.sizeIds[0];
		}

		const visibilityRows = tables.productVisibility
			.filter(thisFilter)
			.sort((a, b) => a.storeId - b.storeId || a.regionId - b.regionId);

		this.isVisible = true;

		if (visibilityRows.length > 0) {
			const [{ storeId, regionId, isVisible: visible }] = visibilityRows;
			if (storeId === 0 && regionId === 0) {
				this.isVisible = visible;
			}

			for (const {
				storeId,
				regionId,
				isVisible: visible
			} of visibilityRows) {
				if (regionId === Config.regionId && storeId === 0) {
					this.isVisible = visible;
					break;
				}
			}

			for (const {
				storeId,
				regionId,
				isVisible: visible
			} of visibilityRows) {
				if (storeId === Config.storeId && regionId === 0) {
					this.isVisible = visible;
					break;
				}
			}
		}

		this.isValid = true;
	}
}

export default class Product
	implements LinkedProduct, Serializable<Serialized.Product> {
	readonly linked = true;

	id: number;

	productId: number;
	name: string;
	ticketName?: string;
	otherNames?: string;
	printable: boolean;
	productVersionId?: number;
	tokenProductId?: number;
	defaultSizeId?: number;
	autoModify: boolean;
	includeInSales: boolean;

	itemId?: number;
	productVersionIds: number[];
	otherProductIds: number[];
	sizeIds: number[];
	itemIds: number[];
	productGroupIds: number[];
	isVisible: boolean;

	productVersion?: ProductVersion;
	tokenProduct?: Product;
	item?: Item;
	productVersions: ProductVersion[] = [];
	otherProducts: Product[] = [];
	sizes: Size[] = [];
	items: Item[] = [];
	productGroups: ProductGroup[] = [];
	defaultSize?: Size;

	static initialized = false;
	static productIds: number[] = [];
	static invalidProductIds: number[] = [];
	static products: NumericDictionary<
		Product | UnlinkedProduct | undefined
	> = {};
	static visibleProducts: NumericDictionary<
		Product | UnlinkedProduct | undefined
	> = {};

	static isProduct(product: UnlinkedProduct | Product): product is Product {
		return product.linked;
	}

	static getProduct(productId: number): Product | undefined {
		const product = this.products[productId];

		if (product) {
			if (this.isProduct(product)) {
				return product;
			}

			// try {
			return new Product(product);
			// } catch (err) {
			// 	console.error(err);
			// 	this.invalidProductIds.push(product.productId);
			// }
		}
	}

	static getProductOrThrow(productId: number): Product {
		const product = this.products[productId];

		if (product) {
			if (product.linked) {
				return product;
			}

			// try {
			return new Product(product);
			// } catch (err) {
			// 	console.error(err);
			// 	this.invalidProductIds.push(product.productId);
			// }
		}

		throw new Error('Failed to get product id ' + productId);
	}

	static async initialize(tables: Database.MappedTables) {
		this.initialized = false;
		this.productIds = [];
		this.products = {};

		for (const row of tables.products) {
			const product = new UnlinkedProduct(row, tables);

			if (product.isValid) {
				this.productIds.push(product.productId);
				this.products[product.productId] = product;

				if (product.isVisible) {
					this.visibleProducts[product.productId] = product;
				}
			}
		}

		this.initialized = this.productIds.length > 0;

		return this.initialized;
	}

	static async linkAll() {
		for (const id of this.productIds) {
			const product = this.products[id];

			if (product && !product.linked) {
				// try {
				new Product(product);
				// } catch (err) {
				// 	console.error(err);
				// 	this.invalidProductIds.push(product.productId);
				// }
			}
		}

		return true;
	}

	constructor(product: Product | UnlinkedProduct) {
		Product.products[product.productId] = this;

		if (product.isVisible) {
			Product.visibleProducts[product.productId] = this;
		}

		this.id = product.productId;

		this.productId = product.productId;
		this.name = product.name;
		this.ticketName = product.ticketName;
		this.otherNames = product.otherNames;
		this.printable = product.printable;
		this.productVersionId = product.productVersionId;
		this.tokenProductId = product.tokenProductId;
		this.defaultSizeId = product.defaultSizeId;
		this.autoModify = product.autoModify;
		this.includeInSales = product.includeInSales;

		this.itemId = product.itemId;
		this.productVersionIds = product.productVersionIds;
		this.otherProductIds = product.otherProductIds;
		this.sizeIds = product.sizeIds;
		this.itemIds = product.itemIds;
		this.productGroupIds = product.productGroupIds;
		this.isVisible = product.isVisible;

		if (product.linked) {
			this.productVersion = product.productVersion;
			this.item = product.item;
			this.productVersions = product.productVersions;
			this.otherProducts = product.otherProducts;
			this.sizes = product.sizes;
			this.items = product.items;
			this.productGroups = product.productGroups;
			this.defaultSize = product.defaultSize;
		} else {
			// link product version
			if (this.productVersionId) {
				const productVersion = ProductVersion.getProductVersion(
					this.productVersionId
				);

				if (!productVersion) {
					throw new Error(
						'Failed to link product to product version'
					);
				}

				this.productVersion = productVersion;
			}

			// link token product
			if (this.tokenProductId) {
				if (this.tokenProductId === this.productId) {
					this.tokenProduct = this;
				} else {
					const tokenProduct = Product.getProduct(
						this.tokenProductId
					);

					if (!tokenProduct) {
						throw new Error(
							'Failed to link product to token product'
						);
					}

					this.tokenProduct = tokenProduct;
				}
			}

			// link product versions
			for (const id of this.productVersionIds) {
				const productVersion = ProductVersion.getProductVersion(id);

				if (!productVersion) {
					throw new Error(
						'Failed to link product to product versions'
					);
				}

				this.productVersions.push(productVersion);
			}

			// link other products
			for (const id of this.otherProductIds) {
				if (id === this.id) {
					this.otherProducts.push(this);
				} else {
					const otherProduct = Product.getProduct(id);

					if (!otherProduct) {
						throw new Error(
							'Failed to link product to other products'
						);
					}

					this.otherProducts.push(otherProduct);
				}
			}

			// link sizes
			for (const id of this.sizeIds) {
				const size = Size.getSize(id);

				if (!size) {
					throw new Error('Failed to link product to sizes');
				}

				this.sizes.push(size);
			}

			// link items
			this.items = [];
			for (const id of this.itemIds) {
				const item = Item.getItemById(id);

				if (!item) {
					throw new Error('Failed to link product to items');
				}

				this.items.push(item);
			}

			// link item
			if (this.itemId) {
				const item = Item.getItemById(this.itemId);

				if (!item) {
					throw new Error('Failed to link product to item');
				}

				this.item = item;
			}

			// link product groups
			for (const id of this.productGroupIds) {
				const productGroup = ProductGroup.getProductGroup(id);

				if (!productGroup) {
					throw new Error(
						'Failed to link product ' +
							this.id +
							' to product group ' +
							id
					);
				}

				this.productGroups.push(productGroup);
			}

			// link default size
			if (this.defaultSizeId) {
				const defaultSize = Size.getSize(this.defaultSizeId);

				if (!defaultSize) {
					throw new Error('Failed to link product to default size');
				}

				this.defaultSize = defaultSize;
			}
		}
	}

	getItem(size?: Size): Item {
		if (!size) {
			if (this.item) {
				return this.item;
			} else {
				return this.getItem(this.defaultSize);
			}
		}

		for (const index in this.sizes) {
			const { id } = this.sizes[index];

			if (id === size.id) {
				return this.items[index];
			}
		}

		throw new Error('Product does not exist in this size.');
	}

	getOtherVersion(productVersion: ProductVersion): Product | undefined {
		for (const otherProduct of this.otherProducts) {
			if (
				otherProduct.productVersion &&
				otherProduct.productVersion.id === productVersion.id
			) {
				return otherProduct;
			}
		}
	}

	serialize(): Serialized.Product {
		return {
			id: this.id,
			name: this.name,
			ticketName: this.ticketName,
			otherNames: this.otherNames,
			printable: this.printable,
			autoModify: this.autoModify,
			isVisible: this.isVisible,
			itemId: this.itemId,
			itemIds: this.itemIds,
			productGroupIds: this.productGroupIds,
			tokenProductId: this.tokenProductId,
			otherProductIds: this.otherProductIds,
			defaultSize: this.defaultSize,
			sizes: this.sizes,
			productVersion: this.productVersion?.serialize(),
			productVersions: this.productVersions.map(productVersion =>
				productVersion.serialize()
			)
		};
	}
}
