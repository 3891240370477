import { Data } from '../types';

import {
	TransItem,
	ModifierGroupModel,
	ModifierScaleModel,
	ProductVersionModel,
	SizeModel,
	Transaction,
	TransItemModel
} from '../lib';

export const serializeModels = (): Data => ({
	sizes: SizeModel.sizeModel.serialize(),
	productVersions: ProductVersionModel.productVersionModel.serialize(),
	modifierGroups: ModifierGroupModel.modifierGroupModel.serialize(),
	modifierScales: ModifierScaleModel.modifierScaleModel.serialize(),
	transItem: TransItem.transItem.serialize(),
	transactionModel: Transaction.transactions.serialize(),
	transItemModel: TransItemModel.transItemModel.serialize()
});
