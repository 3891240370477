import {
	Database,
	LinkedComponentGroup,
	Serializable,
	Serialized,
	NumericDictionary,
	UnlinkedComponentGroup as UnlinkedComponentGroupInterface
} from '../types';

import Component from './Component';
import Item from './Item';
import Unit from './Unit';

export class UnlinkedComponentGroup implements UnlinkedComponentGroupInterface {
	isValid = false;
	linked = false;

	componentGroupId: number;
	name: string;
	parentGroupId?: number;
	suppressNameFromRecipe: boolean;
	unitId: number;

	componentIds: number[];
	itemIds: number[];
	childGroupIds: number[];

	constructor(
		componentGroupFields: Database.MappedComponentGroupsRow,
		tables: Database.MappedTables
	) {
		this.componentGroupId = componentGroupFields.componentGroupId;
		this.name = componentGroupFields.name;
		this.parentGroupId = componentGroupFields.parentGroupId;
		this.suppressNameFromRecipe =
			componentGroupFields.suppressNameFromRecipe;
		this.unitId = componentGroupFields.unitId;

		// initialize component ids
		this.componentIds = tables.componentsComponentGroups
			.filter(
				({ componentGroupId }) =>
					componentGroupId === this.componentGroupId
			)
			.map(({ componentId }) => componentId)
			.sort();

		// initialize item ids
		this.itemIds = tables.itemsComponents
			.filter(
				({ componentGroupId }) =>
					componentGroupId === this.componentGroupId
			)
			.map(({ itemId }) => itemId)
			.sort();

		// initialize child group ids
		this.childGroupIds = tables.componentGroups
			.filter(
				({ parentGroupId }) => parentGroupId === this.componentGroupId
			)
			.map(({ componentGroupId }) => componentGroupId)
			.sort();

		this.isValid = true;
	}
}

export default class ComponentGroup
	implements LinkedComponentGroup, Serializable<Serialized.ComponentGroup> {
	linked = true;

	id: number;
	componentGroupId: number;
	name: string;
	parentGroupId?: number;
	suppressNameFromRecipe: boolean;
	unitId: number;

	componentIds: number[];
	itemIds: number[];
	childGroupIds: number[];

	components: Component[] = [];
	unit: Unit;
	recipeUnits: string;
	recipeUnitsPlural: string;
	items: Item[] = [];
	parentGroup?: ComponentGroup | undefined;
	childGroups: ComponentGroup[] = [];

	static initialized = false;
	static componentGroupIds: number[] = [];
	static componentGroups: NumericDictionary<
		ComponentGroup | UnlinkedComponentGroup | undefined
	> = {};

	static isComponentGroup(
		componentGroup: UnlinkedComponentGroup | ComponentGroup
	): componentGroup is ComponentGroup {
		return componentGroup.linked;
	}

	static getComponentGroup(
		componentGroupId: number
	): ComponentGroup | undefined {
		const componentGroup = this.componentGroups[componentGroupId];

		if (componentGroup) {
			if (this.isComponentGroup(componentGroup)) {
				return componentGroup;
			}

			return new ComponentGroup(componentGroup);
		}
	}

	static async initialize(tables: Database.MappedTables) {
		this.initialized = false;
		this.componentGroupIds = [];
		this.componentGroups = {};

		// TODO: state and local tax hashes

		for (const row of tables.componentGroups) {
			const componentGroup = new UnlinkedComponentGroup(row, tables);

			if (componentGroup.isValid) {
				this.componentGroupIds.push(componentGroup.componentGroupId);
				this.componentGroups[
					componentGroup.componentGroupId
				] = componentGroup;
			}
		}

		if (this.componentGroupIds.length > 0) this.initialized = true;

		return this.initialized;
	}

	static async linkAll() {
		for (const componentGroup of Object.values(
			ComponentGroup.componentGroups
		)) {
			if (componentGroup) {
				// try {
				new ComponentGroup(componentGroup);
				// } catch (err) {}
			}
		}

		return true;
	}

	constructor(componentGroup: UnlinkedComponentGroup | ComponentGroup) {
		ComponentGroup.componentGroups[componentGroup.componentGroupId] = this;

		this.id = componentGroup.componentGroupId;
		this.componentGroupId = componentGroup.componentGroupId;
		this.name = componentGroup.name;
		this.parentGroupId = componentGroup.parentGroupId;
		this.suppressNameFromRecipe = componentGroup.suppressNameFromRecipe;
		this.unitId = componentGroup.unitId;

		this.componentIds = componentGroup.componentIds;
		this.itemIds = componentGroup.itemIds;
		this.childGroupIds = componentGroup.childGroupIds;

		if (ComponentGroup.isComponentGroup(componentGroup)) {
			this.components = componentGroup.components;
			this.unit = componentGroup.unit;
			this.recipeUnits = componentGroup.recipeUnits;
			this.recipeUnitsPlural = componentGroup.recipeUnitsPlural;
			this.items = componentGroup.items;
			this.parentGroup = componentGroup.parentGroup;
			this.childGroups = componentGroup.childGroups;
		} else {
			// link components
			for (const id of this.componentIds) {
				const component = Component.getComponent(id);

				if (!component) {
					throw new Error(
						'Failed to link component group to component'
					);
				}

				this.components.push(component);
			}

			// link unit
			const unit = Unit.getUnit(this.unitId);

			if (!unit) {
				throw new Error('Failed to link component group to unit');
			}

			this.unit = unit;
			this.recipeUnits = unit.unitName;
			this.recipeUnitsPlural = unit.unitNamePlural;

			// link items
			for (const id of this.itemIds) {
				const item = Item.getItemById(id);

				if (!item) {
					throw new Error('Failed to link component group to item');
				}

				this.items.push(item);
			}

			// link parent group
			if (this.parentGroupId) {
				const parentGroup = ComponentGroup.getComponentGroup(
					this.parentGroupId
				);

				if (!parentGroup) {
					throw new Error(
						'Failed to link component group to parent group'
					);
				}

				this.parentGroup = parentGroup;
			}

			// link child groups
			for (const id of this.childGroupIds) {
				const childGroup = ComponentGroup.getComponentGroup(id);

				if (!childGroup) {
					throw new Error(
						'Failed to link component group to child group'
					);
				}

				this.childGroups.push(childGroup);
			}
		}
	}

	containsComponent(componentId: number) {
		// // console.log('ComponentGroup: containsComponent');
		let found =
			this.components.find(component => component.id === componentId) !==
			undefined;

		if (!found) {
			for (const child of this.childGroups) {
				if (child.containsComponent(componentId)) {
					found = true;

					break;
				}
			}
		}

		return found;
	}

	containsComponentGroup(componentGroupId: number): boolean {
		return this.childGroupIds.some(id => id === componentGroupId);
	}

	serialize(): Serialized.ComponentGroup {
		return {
			id: this.id,
			name: this.name,
			suppressNameFromRecipe: this.suppressNameFromRecipe,
			recipeUnits: this.recipeUnits,
			recipeUnitsPlural: this.recipeUnitsPlural,
			itemIds: this.itemIds,
			childGroupIds: this.childGroupIds,
			parentGroupId: this.parentGroupId,
			componentIds: this.componentIds,
			unit: this.unit.serialize()
		};
	}
}
