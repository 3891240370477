import {
	UnlinkedComponentVersion as UnlinkedComponentVersionInterface,
	Database,
	LinkedComponentVersion,
	NumericDictionary,
	Serializable,
	Serialized
} from '../types';

export class UnlinkedComponentVersion
	implements UnlinkedComponentVersionInterface {
	readonly linked = false;
	isValid = false;

	componentVersionId: number;
	name: string;

	constructor(
		componentVersionFields: Database.MappedComponentVersionsRow,
		tables: Database.MappedTables
	) {
		this.componentVersionId = componentVersionFields.componentVersionId;
		this.name = componentVersionFields.name;

		this.isValid = true;
	}
}

export default class ComponentVersion
	implements
		LinkedComponentVersion,
		Serializable<Serialized.ComponentVersion> {
	readonly linked = true;

	id: number;
	componentVersionId: number;
	name: string;

	static initialized = false;
	static componentVersionIds: number[] = [];
	static componentVersions: NumericDictionary<
		ComponentVersion | UnlinkedComponentVersion | undefined
	> = {};

	static isComponentVersion(
		componentVersion: UnlinkedComponentVersion | ComponentVersion
	): componentVersion is ComponentVersion {
		return componentVersion.linked;
	}

	static getComponentVersion(
		componentVersionId: number
	): ComponentVersion | undefined {
		const componentVersion = this.componentVersions[componentVersionId];

		if (componentVersion) {
			if (this.isComponentVersion(componentVersion)) {
				return componentVersion;
			}

			return new ComponentVersion(componentVersion);
		}
	}

	static async initialize(tables: Database.MappedTables) {
		this.initialized = false;
		this.componentVersionIds = [];
		this.componentVersions = {};

		for (const row of tables.componentVersions) {
			const componentVersion = new UnlinkedComponentVersion(row, tables);

			if (componentVersion.isValid) {
				this.componentVersionIds.push(
					componentVersion.componentVersionId
				);
				this.componentVersions[
					componentVersion.componentVersionId
				] = componentVersion;
			}
		}

		if (this.componentVersionIds.length > 0) this.initialized = true;

		return this.initialized;
	}

	static async linkAll() {
		for (const componentVersion of Object.values(
			ComponentVersion.componentVersions
		)) {
			if (componentVersion) {
				new ComponentVersion(componentVersion);
			}
		}

		return true;
	}

	constructor(componentVersion: UnlinkedComponentVersion | ComponentVersion) {
		ComponentVersion.componentVersions[
			componentVersion.componentVersionId
		] = this;

		this.id = componentVersion.componentVersionId;
		this.componentVersionId = componentVersion.componentVersionId;
		this.name = componentVersion.name;
	}

	serialize(): Serialized.ComponentVersion {
		return {
			id: this.id,
			name: this.name
		};
	}
}
