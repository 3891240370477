import { Slot } from '../types';

export const connect = <TSlotArgs extends any[]>(
	slot: Slot<TSlotArgs>,
	handlerId: string,
	context: any,
	handler: (...args: TSlotArgs) => void
): void => {
	slot[handlerId] = handler.bind(context);
};
