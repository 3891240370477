import {
	Database,
	LinkedProductVersion,
	NumericDictionary,
	Serializable,
	Serialized,
	UnlinkedProductVersion as UnlinkedProductVersionInterface
} from '../types';

export class UnlinkedProductVersion implements UnlinkedProductVersionInterface {
	readonly linked = false;
	isValid = false;

	productVersionId: number;
	name: string;
	priority: number;

	constructor(
		productVersionFields: Database.MappedProductVersionsRow,
		tables: Database.MappedTables
	) {
		this.productVersionId = productVersionFields.productVersionId;
		this.name = productVersionFields.name;
		this.priority = productVersionFields.priority;

		this.isValid = true;
	}
}

export default class ProductVersion
	implements LinkedProductVersion, Serializable<Serialized.ProductVersion> {
	readonly linked = true;

	id: number;

	productVersionId: number;
	name: string;
	priority: number;

	productVersions: ProductVersion[] = [];

	static initialized = false;
	static productVersionIds: number[] = [];
	static productVersions: NumericDictionary<
		ProductVersion | UnlinkedProductVersion | undefined
	> = {};

	static isProductVersion(
		productVersion: UnlinkedProductVersion | ProductVersion
	): productVersion is ProductVersion {
		return productVersion.linked;
	}

	static getProductVersion(
		productVersionId: number
	): ProductVersion | undefined {
		let productVersion = this.productVersions[productVersionId];

		if (productVersion) {
			if (this.isProductVersion(productVersion)) {
				return productVersion;
			}

			return new ProductVersion(productVersion);
		}
	}

	static async initialize(tables: Database.MappedTables) {
		this.initialized = false;
		this.productVersionIds = [];
		this.productVersions = {};

		for (const row of tables.productVersions) {
			const productVersion = new UnlinkedProductVersion(row, tables);

			if (productVersion.isValid) {
				this.productVersionIds.push(productVersion.productVersionId);
				this.productVersions[
					productVersion.productVersionId
				] = productVersion;
			}
		}

		if (this.productVersionIds.length > 0) this.initialized = true;

		return this.initialized;
	}

	static async linkAll() {
		for (const productVersion of Object.values(
			ProductVersion.productVersions
		)) {
			if (productVersion) {
				// try {
				new ProductVersion(productVersion);
				// } catch (err) {
				// 	// console.error(err);
				// }
			}
		}

		return true;
	}

	constructor(productVersion: ProductVersion | UnlinkedProductVersion) {
		ProductVersion.productVersions[productVersion.productVersionId] = this;

		this.id = productVersion.productVersionId;

		this.productVersionId = productVersion.productVersionId;
		this.name = productVersion.name;
		this.priority = productVersion.priority;
	}

	serialize(): Serialized.ProductVersion {
		return {
			id: this.id,
			name: this.name,
			prioirity: this.priority
		};
	}
}

export class ProductVersionModel
	implements Serializable<Serialized.ProductVersionModel> {
	private productVersions_: ProductVersion[] = [];
	selectedIndex: number = -1;

	static productVersionModel: ProductVersionModel;

	constructor() {
		ProductVersionModel.productVersionModel = this;
	}

	get productVersions(): ProductVersion[] {
		return this.productVersions_;
	}

	set productVersions(productVersions: ProductVersion[]) {
		// console.log('ProductVersionModel: setProductVersions');

		let foundProductVersion = false;

		if (this.selectedIndex >= 0) {
			for (
				let productVersionIndex = 0;
				productVersionIndex < productVersions.length &&
				productVersionIndex < this.productVersions_.length;
				++productVersionIndex
			) {
				if (
					productVersions[productVersionIndex].id ===
					this.productVersions_.length
				) {
					this.selectedIndex = productVersionIndex;
					foundProductVersion = true;

					break;
				}
			}

			if (!foundProductVersion) {
				this.selectedIndex = -1;
			}
		}

		this.productVersions_ = productVersions;
	}

	selectProductVersion(productVersion: ProductVersion) {
		// console.log('ProductVersionModel: selectProductVersion');
		console.log(
			'ProductVersionModel: selectProductVersion: productVersion',
			productVersion
		);

		this.selectedIndex = -1;

		for (let i = 0; i < this.productVersions_.length; ++i) {
			// console.log('ProductVersionModel: selectProductVersion: i', i);
			console.log(
				'ProductVersionModel: selectProductVersion: this.productVersions_[i].id',
				this.productVersions_[i].id
			);
			console.log(
				'ProductVersionModel: selectProductVersion: productVersion.id',
				productVersion.id
			);

			if (this.productVersions_[i].id === productVersion.id) {
				this.selectedIndex = i;

				break;
			}
		}

		console.log(
			'ProductVersionModel: selectProductVersion: this.selectedIndex',
			this.selectedIndex
		);
	}

	get selectedProductVersion(): ProductVersion | undefined {
		if (
			this.selectedIndex < 0 ||
			this.selectedIndex >= this.productVersions_.length
		) {
			return undefined;
		} else {
			return this.productVersions_[this.selectedIndex];
		}
	}

	serialize() {
		return {
			productVersions: this.productVersions_.map(productVersion =>
				productVersion.serialize()
			),
			selectedIndex: this.selectedIndex
		};
	}
}
