import {
	Serializable,
	Serialized,
	TransComponent as TransComponentInterface
} from '../types';

import { v4 } from 'uuid';

import Component from './Component';

export default class TransComponent
	implements
		TransComponentInterface,
		Serializable<Serialized.TransComponent> {
	id: string;
	component: Component;
	timeAdded: number;

	tax: number;
	quantity: number;
	isVoided: boolean = false;

	constructor(
		component: Component,
		quantity: number,
		transComponent?: TransComponent
	) {
		this.component = component;
		this.quantity = quantity;

		if (transComponent) {
			this.id = transComponent.id;
			this.timeAdded = transComponent.timeAdded;
			this.tax = transComponent.tax;
		} else {
			this.id = v4();
			this.timeAdded = Date.now();
			this.tax = component.tax;
		}
	}

	changeVerson(componentVersionId: number): void {
		const newComponent = this.component.getOtherVersion(componentVersionId);

		if (newComponent) {
			this.component = newComponent;
		}
	}

	serialize(): Serialized.TransComponent {
		return {
			id: this.id,
			component: this.component.serialize(),
			tax: this.tax,
			quantity: this.quantity
		};
	}
}
