import {
	UnlinkedItemQualifier as UnlinkedItemQualifierInterface,
	ItemQualifierType,
	Database,
	LinkedItemQualifier,
	NumericDictionary,
	Serializable,
	Serialized
} from '../types';

import {
	TransItem,
	ProductGroup,
	ComponentGroup,
	ModifierGroup,
	Component
} from '.';

export class UnlinkedItemQualifier implements UnlinkedItemQualifierInterface {
	readonly linked = false;
	isValid = false;

	itemQualifierId: number;
	itemQualifierType: number;
	name: string;
	isNot: boolean;
	qualifyingId: number;

	type: ItemQualifierType;

	constructor(itemQualifierFields: Database.MappedItemQualifiersRow) {
		this.itemQualifierId = itemQualifierFields.itemQualifierId;
		this.itemQualifierType = itemQualifierFields.itemQualifierType;
		this.name = itemQualifierFields.name;
		this.isNot = itemQualifierFields.isNot;
		this.qualifyingId = itemQualifierFields.qualifyingId;

		switch (this.itemQualifierType) {
			case 1:
				this.type = 'PRODUCT';

				break;
			case 2:
				this.type = 'PRODUCT_GROUP';

				break;
			case 3:
				this.type = 'COMPONENT';

				break;
			case 4:
				this.type = 'COMPONENT_GROUP';

				break;
			case 5:
				this.type = 'SIZE';

				break;
			case 6:
				this.type = 'MODIFIER';

				break;
			case 7:
				this.type = 'COMPONENT_VERSION';

				break;
			case 8:
				this.type = 'PRODUCT_VERSION';

				break;
			case 9:
				this.type = 'IN_RECIPE';

				break;
			case 10:
				this.type = 'ITEM';

				break;
			case 11:
				this.type = 'COMPONENT_GROUP_COMPONENT';

				break;
			case 12:
				this.type = 'MODIFIER_GROUP';

				break;
			case 13:
				this.type = 'IN_ONLINE_ORDER';

				break;
			case 14:
				this.type = 'ONLINE_PRODUCT';

				break;
			case 15:
				this.type = 'ONLINE_PRODUCT_GROUP';

				break;
			default:
				throw new Error('Invalid item qualifier type');
		}

		this.isValid = true;
	}
}

export default class ItemQualifier
	implements LinkedItemQualifier, Serializable<Serialized.ItemQualifier> {
	readonly linked = true;

	id: number;

	itemQualifierId: number;
	itemQualifierType: number;
	name: string;
	isNot: boolean;
	qualifyingId: number;

	type: ItemQualifierType;

	static initialized = false;
	static itemQualifierIds: number[] = [];
	static itemQualifiers: NumericDictionary<
		ItemQualifier | UnlinkedItemQualifier | undefined
	> = {};

	static isItemQualifier(
		itemQualifier: UnlinkedItemQualifier | ItemQualifier
	): itemQualifier is ItemQualifier {
		return itemQualifier.linked;
	}

	static getItemQualifier(
		itemQualifierId: number
	): ItemQualifier | undefined {
		const itemQualifier = this.itemQualifiers[itemQualifierId];

		if (itemQualifier) {
			if (this.isItemQualifier(itemQualifier)) {
				return itemQualifier;
			}

			return new ItemQualifier(itemQualifier);
		}

		return undefined;
	}

	static async initialize(tables: Database.MappedTables): Promise<boolean> {
		this.initialized = false;
		this.itemQualifierIds = [];
		this.itemQualifiers = {};

		for (const row of tables.itemQualifiers) {
			const itemQualifier = new UnlinkedItemQualifier(row);

			if (itemQualifier.isValid) {
				this.itemQualifierIds.push(itemQualifier.itemQualifierId);
				this.itemQualifiers[
					itemQualifier.itemQualifierId
				] = itemQualifier;
			}
		}

		if (this.itemQualifierIds.length > 0) this.initialized = true;

		return this.initialized;
	}

	static async linkAll(): Promise<boolean> {
		for (const itemQualifier of Object.values(
			ItemQualifier.itemQualifiers
		)) {
			if (itemQualifier) {
				new ItemQualifier(itemQualifier);
			}
		}

		return true;
	}

	constructor(itemQualifier: ItemQualifier | UnlinkedItemQualifier) {
		ItemQualifier.itemQualifiers[itemQualifier.itemQualifierId] = this;

		this.id = itemQualifier.itemQualifierId;

		this.itemQualifierId = itemQualifier.itemQualifierId;
		this.itemQualifierType = itemQualifier.itemQualifierType;
		this.name = itemQualifier.name;
		this.isNot = itemQualifier.isNot;
		this.qualifyingId = itemQualifier.qualifyingId;

		this.type = itemQualifier.type;

		// TODO: call "get" methods for each possible item qualifier type
		// to verify that the qualifyingId exists
	}

	qualifies(transItem: TransItem): boolean {
		let qualifies = false;
		switch (this.type) {
			case 'PRODUCT':
				qualifies = transItem.item.product.id === this.qualifyingId;
				break;
			case 'PRODUCT_GROUP': {
				const productGroupToFind = ProductGroup.getProductGroup(
					this.qualifyingId
				);

				if (!productGroupToFind)
					throw new Error(
						'Product group not found for PRODUCT_GROUP qualifier'
					);

				const productGroup = new ProductGroup(productGroupToFind);

				qualifies = productGroup.containsProduct(
					transItem.item.product
				);

				break;
			}
			case 'COMPONENT':
				qualifies = transItem.hasComponentById(this.qualifyingId, true);

				break;
			case 'COMPONENT_GROUP': {
				const componentGroup = ComponentGroup.getComponentGroup(
					this.qualifyingId
				);

				if (!componentGroup) {
					throw new Error(
						'Component group not found for COMPONENT_GROUP qualifier'
					);
				}

				qualifies = transItem.hasComponentGroup(componentGroup);

				break;
			}
			case 'SIZE':
				if (!transItem.item.size) {
					qualifies = !this.qualifyingId;
				} else {
					qualifies = this.qualifyingId === transItem.item.size.id;
				}

				break;
			case 'MODIFIER':
				qualifies = transItem.hasModifierById(this.qualifyingId);

				break;
			case 'COMPONENT_VERSION':
				qualifies = transItem.hasVersionableComponent(
					this.qualifyingId
				);

				break;
			case 'PRODUCT_VERSION':
				qualifies =
					transItem.item.product.productVersion?.id ===
					this.qualifyingId;

				break;
			case 'IN_RECIPE':
				for (const component of transItem.item.recipe
					.map(({ component }) => component)
					.filter<Component>(
						(c): c is Component => c !== undefined
					)) {
					if (component.id === this.qualifyingId) {
						qualifies = true;

						break;
					}
				}

				break;
			case 'ITEM':
				qualifies = transItem.item.id === this.qualifyingId;

				break;
			case 'COMPONENT_GROUP_COMPONENT': {
				const componentGroupToFind = ComponentGroup.getComponentGroup(
					this.qualifyingId
				);

				if (componentGroupToFind === undefined) {
					throw new Error(
						'No component group for COMPONENT_GROUP_COMPONENT qualifier'
					);
				}

				qualifies = false;

				if (transItem.hasComponentGroup(componentGroupToFind)) {
					for (const transComponent of transItem.components) {
						if (
							componentGroupToFind.containsComponent(
								transComponent.component.id
							)
						) {
							qualifies = true;
						}
					}
				}

				break;
			}
			case 'MODIFIER_GROUP': {
				const modifierGroupToFind = ModifierGroup.getModifierGroup(
					this.qualifyingId
				);

				if (!modifierGroupToFind) {
					throw new Error(
						'No modifier group for MODIFIER_GROUP qualifier'
					);
				}

				qualifies = transItem.hasModifierGroupModifier(
					modifierGroupToFind
				);

				break;
			}
			case 'IN_ONLINE_ORDER': {
				// qualifies = transItem.fromOnline === (this.qualifyingId !== 0); // TODO
				qualifies = false;

				break;
			}
			case 'ONLINE_PRODUCT': {
				// TODO
				// qualifies =
				// 	transItem.fromOnline &&
				// 	transItem.item.product.productId === this.qualifyingId;
				qualifies = false;

				break;
			}
			case 'ONLINE_PRODUCT_GROUP': {
				// TODO
				// const productGroup = ProductGroup.getProductGroupOrThrow(
				// 	this.qualifyingId
				// );

				// TODO
				// qualifies =
				// 	transItem.fromOnline &&
				// 	productGroup.containsProduct(transItem.item.product);
				qualifies = false;

				break;
			}
			default:
				throw new Error('Invalid item qualifier type: ' + this.type);
		}

		return this.isNot ? !qualifies : qualifies;
	}

	serialize(): Serialized.ItemQualifier {
		return {
			id: this.id,

			itemQualifierId: this.itemQualifierId,
			itemQualifierType: this.itemQualifierType,
			name: this.name,
			isNot: this.isNot,
			qualifyingId: this.qualifyingId,

			type: this.type
		};
	}
}
