import {
	LinkedComponentGroupRatio,
	NumericDictionary,
	Database,
	UnlinkedComponentGroupRatio as UnlinkedComponentGroupRatioInterface,
	Serializable,
	Serialized
} from '../types';

import {
	ComponentGroup,
	ComponentGroupRatioType,
	ItemQualifier,
	Size,
	TransItem
} from '.';

export class UnlinkedComponentGroupRatio
	implements UnlinkedComponentGroupRatioInterface {
	readonly linked = false;
	isValid = false;

	componentGroupRatioId: number;
	sizeId?: number;
	componentGroupRatioTypeId: number;

	componentGroupIds: number[];
	componentGroupCounts: number[];
	componentGroupQuantities: number[];
	itemQualifierIds: number[];
	itemQualifiersIsOr: boolean[];

	constructor(
		componentGroupRatioFields: Database.MappedComponentGroupRatiosRow,
		tables: Database.MappedTables
	) {
		this.componentGroupRatioId =
			componentGroupRatioFields.componentGroupRatioId;
		this.sizeId = componentGroupRatioFields.sizeId;
		this.componentGroupRatioTypeId =
			componentGroupRatioFields.componentGroupRatioTypeId;

		// initialize component group ids, component group counts, and component group quantities
		const [
			componentGroupIds,
			componentGroupCounts,
			componentGroupQuantities
		] = tables.componentGroupRatioQuantities
			.filter(
				({ componentGroupRatioId }) =>
					componentGroupRatioId === this.componentGroupRatioId
			)
			.reduce<[number[], number[], number[]]>(
				(
					[
						componentGroupIds,
						componentGroupCounts,
						componentGroupQuantities
					],
					{
						componentGroupId,
						componentGroupCount,
						componentGroupQuantity
					}
				) => [
					[...componentGroupIds, componentGroupId],
					[...componentGroupCounts, componentGroupCount],
					[...componentGroupQuantities, componentGroupQuantity]
				],
				[[], [], []]
			);
		this.componentGroupIds = componentGroupIds;
		this.componentGroupCounts = componentGroupCounts;
		this.componentGroupQuantities = componentGroupQuantities;

		// initialize item qualifier ids and item qualifiers "isOr"s
		const [
			itemQualifierIds,
			itemQualifiersIsOr
		] = tables.componentGroupRatiosItemQualifiers
			.filter(
				({ componentGroupRatioId }) =>
					componentGroupRatioId === this.componentGroupRatioId
			)
			.reduce<[number[], boolean[]]>(
				(
					[itemQualifierIds, itemQualifiersIsOr],
					{ itemQualifierId, isOr }
				) => [
					[...itemQualifierIds, itemQualifierId],
					[...itemQualifiersIsOr, isOr]
				],
				[[], []]
			);
		this.itemQualifierIds = itemQualifierIds;
		this.itemQualifiersIsOr = itemQualifiersIsOr;

		this.isValid = true;
	}
}

export default class ComponentGroupRatio
	implements
		LinkedComponentGroupRatio,
		Serializable<Serialized.ComponentGroupRatio> {
	readonly linked = true;

	id: number;

	componentGroupRatioId: number;
	sizeId?: number;
	componentGroupRatioTypeId: number;

	componentGroupIds: number[];
	componentGroupCounts: number[];
	componentGroupQuantities: number[];
	itemQualifierIds: number[];
	itemQualifiersIsOr: boolean[];

	size?: Size;
	componentGroupRatioType: ComponentGroupRatioType;
	componentGroups: ComponentGroup[] = [];
	itemQualifiers: ItemQualifier[] = [];

	static initialized = false;
	static componentGroupRatioIds: number[] = [];
	static componentGroupRatios: NumericDictionary<
		ComponentGroupRatio | UnlinkedComponentGroupRatio | undefined
	> = {};

	static isComponentGroupRatio(
		componentGroupRatio: UnlinkedComponentGroupRatio | ComponentGroupRatio
	): componentGroupRatio is ComponentGroupRatio {
		return componentGroupRatio.linked;
	}

	static getComponentGroupRatio(
		componentGroupRatioId: number
	): ComponentGroupRatio | undefined {
		let componentGroupRatio = this.componentGroupRatios[
			componentGroupRatioId
		];

		if (componentGroupRatio) {
			if (this.isComponentGroupRatio(componentGroupRatio)) {
				return componentGroupRatio;
			}

			return new ComponentGroupRatio(componentGroupRatio);
		}
	}

	static async initialize(tables: Database.MappedTables) {
		this.initialized = false;
		this.componentGroupRatioIds = [];
		this.componentGroupRatios = {};

		for (const row of tables.componentGroupRatios) {
			const componentGroupRatio = new UnlinkedComponentGroupRatio(
				row,
				tables
			);

			if (componentGroupRatio.isValid) {
				this.componentGroupRatioIds.push(
					componentGroupRatio.componentGroupRatioId
				);
				this.componentGroupRatios[
					componentGroupRatio.componentGroupRatioId
				] = componentGroupRatio;
			}
		}

		if (this.componentGroupRatioIds.length > 0) this.initialized = true;

		return this.initialized;
	}

	static async linkAll() {
		for (const componentGroupRatio of Object.values(
			ComponentGroupRatio.componentGroupRatios
		)) {
			if (componentGroupRatio) {
				// try {
				new ComponentGroupRatio(componentGroupRatio);
				// } catch (err) {
				// 	// console.error(err);
				// }
			}
		}

		return true;
	}

	constructor(
		componentGroupRatio: UnlinkedComponentGroupRatio | ComponentGroupRatio
	) {
		ComponentGroupRatio.componentGroupRatios[
			componentGroupRatio.componentGroupRatioId
		] = this;

		this.id = componentGroupRatio.componentGroupRatioId;

		this.componentGroupRatioId = componentGroupRatio.componentGroupRatioId;
		this.sizeId = componentGroupRatio.sizeId;
		this.componentGroupRatioTypeId =
			componentGroupRatio.componentGroupRatioTypeId;

		this.componentGroupIds = componentGroupRatio.componentGroupIds;
		this.componentGroupCounts = componentGroupRatio.componentGroupCounts;
		this.componentGroupQuantities =
			componentGroupRatio.componentGroupQuantities;
		this.itemQualifierIds = componentGroupRatio.itemQualifierIds;
		this.itemQualifiersIsOr = componentGroupRatio.itemQualifiersIsOr;

		if (componentGroupRatio.linked) {
			this.size = componentGroupRatio.size;
			this.componentGroupRatioType =
				componentGroupRatio.componentGroupRatioType;
			this.componentGroups = componentGroupRatio.componentGroups;
			this.itemQualifiers = componentGroupRatio.itemQualifiers;
		} else {
			// link component group ratio type
			const componentGroupRatioType = ComponentGroupRatioType.getComponentGroupRatioType(
				this.componentGroupRatioTypeId
			);

			if (!componentGroupRatioType) {
				throw new Error(
					'Failed to link component group ratio to component group ratio type'
				);
			}

			this.componentGroupRatioType = componentGroupRatioType;

			// link size
			if (this.sizeId) {
				const size = Size.getSize(this.sizeId);

				if (!size) {
					throw new Error(
						'Failed to link component group ratio to size'
					);
				}

				this.size = size;
			}

			for (const id of this.componentGroupIds) {
				const componentGroup = ComponentGroup.getComponentGroup(id);

				if (!componentGroup) {
					throw new Error(
						'Failed to link component group ratio to component group'
					);
				}

				this.componentGroups.push(componentGroup);
			}

			for (const id of this.itemQualifierIds) {
				const itemQualifier = ItemQualifier.getItemQualifier(id);

				if (!itemQualifier) {
					throw new Error(
						'Failed to link component group ratio to item qualifier'
					);
				}

				this.itemQualifiers.push(itemQualifier);
			}
		}
	}

	qualifies(transItem: TransItem): boolean {
		let orQualifies = false;
		let orExists = false;

		for (const index in this.itemQualifiers) {
			const qualifies = this.itemQualifiers[index].qualifies(transItem);

			if (this.itemQualifiersIsOr[index]) {
				orExists = true;

				if (qualifies) orQualifies = true;
			} else if (!qualifies) return false;
		}

		if (!orQualifies && orExists) return false;

		return true;
	}

	hasComponentGroup(componentGroupId: number) {
		for (const componentGroup of this.componentGroups) {
			if (componentGroup.id === componentGroupId) {
				return true;
			}
		}

		return false;
	}

	serialize(): Serialized.ComponentGroupRatio {
		return {
			id: this.id,

			componentGroupRatioId: this.componentGroupRatioId,
			sizeId: this.sizeId,
			componentGroupRatioTypeId: this.componentGroupRatioTypeId,

			componentGroupIds: this.componentGroupIds,
			componentGroupCounts: this.componentGroupCounts,
			componentGroupQuantities: this.componentGroupQuantities,
			itemQualifierIds: this.itemQualifierIds,
			itemQualifiersIsOr: this.itemQualifiersIsOr
		};
	}
}
