import {
	Database,
	LinkedSize,
	NumericDictionary,
	Serializable,
	Serialized,
	UnlinkedSize as UnlinkedSizeInterface
} from '../types';

export class UnlinkedSize implements UnlinkedSizeInterface {
	readonly linked = false;
	isValid = false;

	sizeId: number;
	name: string;
	itemName: string;
	itemNamePriority: number;
	priority: number;

	constructor(
		sizeFields: Database.MappedSizesRow,
		tables: Database.MappedTables
	) {
		this.isValid = true;

		this.sizeId = sizeFields.sizeId;
		this.name = sizeFields.name;
		this.itemName = sizeFields.itemName;
		this.itemNamePriority = sizeFields.itemNamePriority;
		this.priority = sizeFields.priority;
	}
}

export default class Size implements LinkedSize, Serializable<Serialized.Size> {
	readonly linked = true;

	id: number;

	sizeId: number;
	name: string;
	priority: number;
	itemName: string;
	itemNamePriority: number;

	static initialized = false;
	static sizeIds: number[] = [];
	static sizes: NumericDictionary<Size | UnlinkedSize | undefined> = {};

	static isSize(size: UnlinkedSize | Size): size is Size {
		return size.linked;
	}

	static getSize(sizeId: number): Size | undefined {
		let size = this.sizes[sizeId];

		if (size) {
			if (this.isSize(size)) {
				return size;
			}

			return new Size(size);
		}
	}

	static async initialize(tables: Database.MappedTables) {
		this.initialized = false;
		this.sizeIds = [];
		this.sizes = {};

		for (const row of tables.sizes) {
			const size = new UnlinkedSize(row, tables);

			if (size.isValid) {
				this.sizeIds.push(size.sizeId);
				this.sizes[size.sizeId] = size;
			}
		}

		if (this.sizeIds.length > 0) this.initialized = true;

		return this.initialized;
	}

	static async linkAll() {
		for (const size of Object.values(Size.sizes)) {
			if (size) {
				// try {
				new Size(size);
				// } catch (err) {
				// 	// console.error(err);
				// }
			}
		}

		return true;
	}

	constructor(size: Size | UnlinkedSize) {
		Size.sizes[size.sizeId] = this;

		this.id = size.sizeId;

		this.sizeId = size.sizeId;
		this.name = size.name;
		this.itemName = size.itemName;
		this.itemNamePriority = size.itemNamePriority;
		this.priority = size.priority;
	}

	serialize(): Serialized.Size {
		return {
			id: this.id,
			name: this.name,
			priority: this.priority
		};
	}
}

export class SizeModel implements Serializable<Serialized.SizeModel> {
	private sizes_: Size[] = [];
	selectedIndex: number = -1;

	static sizeModel: SizeModel;

	constructor() {
		SizeModel.sizeModel = this;
	}

	get sizes(): Size[] {
		return this.sizes_;
	}

	set sizes(sizes: Size[]) {
		// console.log('SizeModel: setSizes');

		let foundSize = false;

		if (this.selectedIndex >= 0) {
			for (
				let sizeIndex = 0;
				sizeIndex < sizes.length && sizeIndex < this.sizes_.length;
				++sizeIndex
			) {
				if (sizes[sizeIndex].id === this.sizes_.length) {
					this.selectedIndex = sizeIndex;
					foundSize = true;

					break;
				}
			}

			if (!foundSize) {
				this.selectedIndex = -1;
			}
		}

		this.sizes_ = sizes;
	}

	selectSize(size: Size) {
		// console.log('SizeModel: selectSize');
		// console.log('SizeModel: selectSize: size', size);

		this.selectedIndex = -1;

		for (let i = 0; i < this.sizes_.length; ++i) {
			// console.log('SizeModel: selectSize: i', i);
			console.log(
				'SizeModel: selectSize: this.sizes_[i].id',
				this.sizes_[i].id
			);
			// console.log('SizeModel: selectSize: size.id', size.id);

			if (this.sizes_[i].id === size.id) {
				this.selectedIndex = i;

				break;
			}
		}

		console.log(
			'SizeModel: selectSize: this.selectedIndex',
			this.selectedIndex
		);
	}

	get selectedSize(): Size | undefined {
		if (
			this.selectedIndex < 0 ||
			this.selectedIndex >= this.sizes_.length
		) {
			return undefined;
		} else {
			return this.sizes_[this.selectedIndex];
		}
	}

	serialize() {
		return {
			sizes: this.sizes_.map(size => size.serialize()),
			selectedIndex: this.selectedIndex
		};
	}
}
