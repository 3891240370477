import { Data } from '../types';

import { loadItem, serializeModels } from '../utils';

import {
	TransItem,
	ModifierGroupModel,
	ModifierScaleModel,
	ProductVersionModel,
	SizeModel
} from '../lib';

export const decreaseModifierLevel = async (
	times: number,
	modifierScaleIndex: number
): Promise<Data> => {
	console.log('decreaseModifierLevel');
	console.time('decreaseModifierLevel: time');

	const modifierScaleModel = ModifierScaleModel.modifierScaleModel;

	for (let i = 0; i < times; ++i) {
		const oldItemId = TransItem.transItem.item.id;

		modifierScaleModel.decreaseLevel(modifierScaleIndex);

		loadItem();

		// TODO: implement
		// // Display Liquid Base Message if capacity is reached
		// if(transItem_->getLiquidBaseMessageStatus())
		// {
		// 	transItem_->resetLiquidBaseMessageStatus();
		// 	ui_->popupMessage("The modification failed because it exceeds the item's capacity.  Please change the item's size.");

		// }

		const newItemId = TransItem.transItem.item.id;

		if (newItemId !== oldItemId) {
			console.log('Item changed');
		}
	}

	console.timeEnd('decreaseModifierLevel: time');

	const data = serializeModels();

	console.log('SizeModel.sizeModel', SizeModel.sizeModel);
	console.log(
		'ProductVersionModel.productVersionModel',
		ProductVersionModel.productVersionModel
	);
	console.log(
		'ModifierGroupModel.modifierGroupModel',
		ModifierGroupModel.modifierGroupModel
	);
	console.log(
		'ModifierScaleModel.modifierScaleModel',
		ModifierScaleModel.modifierScaleModel
	);
	console.log('TransItem.transItem', TransItem.transItem);

	console.log('data', data);

	return data;
};
