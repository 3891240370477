import {
	UnlinkedContainer as UnlinkedContainerInterface,
	Deduction,
	Database,
	LinkedContainer,
	NumericDictionary,
	Serializable,
	Serialized
} from '../types';

export class UnlinkedContainer implements UnlinkedContainerInterface {
	readonly linked = false;
	isValid = false;

	containerId: number;
	name: string;

	deductionVolumes: Deduction[];

	constructor(
		containerFields: Database.MappedContainersRow,
		tables: Database.MappedTables
	) {
		this.containerId = containerFields.containerId;
		this.name = containerFields.name;

		// initialize deduction volumes
		this.deductionVolumes = tables.containerVolumes
			.filter(({ containerId }) => containerId === this.containerId)
			.map(({ deductionInches, volumeFlOz }) => ({
				deduction: deductionInches,
				volume: volumeFlOz
			}));

		this.isValid = true;
	}
}

export default class Container
	implements LinkedContainer, Serializable<Serialized.Container> {
	readonly linked = true;

	id: number;

	containerId: number;
	name: string;

	deductionVolumes: Deduction[];

	static initialized = false;
	static containerIds: number[] = [];
	static containers: NumericDictionary<
		Container | UnlinkedContainer | undefined
	> = {};

	static isContainer(
		container: UnlinkedContainer | Container
	): container is Container {
		return container.linked;
	}

	static getContainer(containerId: number): Container | undefined {
		let container = this.containers[containerId];

		if (container) {
			if (this.isContainer(container)) {
				return container;
			}

			return new Container(container);
		}
	}

	static async initialize(tables: Database.MappedTables) {
		this.initialized = false;
		this.containerIds = [];
		this.containers = {};

		for (const row of tables.containers) {
			const container = new UnlinkedContainer(row, tables);

			if (container.isValid) {
				this.containerIds.push(container.containerId);
				this.containers[container.containerId] = container;
			}
		}

		if (this.containerIds.length > 0) this.initialized = true;

		return this.initialized;
	}

	static async linkAll() {
		for (const container of Object.values(Container.containers)) {
			if (container) {
				new Container(container);
			}
		}

		return true;
	}

	constructor(container: Container | UnlinkedContainer) {
		Container.containers[container.containerId] = this;

		this.id = container.containerId;

		this.containerId = container.containerId;
		this.name = container.name;

		this.deductionVolumes = container.deductionVolumes;
	}

	getVolume(deduction: number): number {
		for (const { deduction: deductionVolume, volume } of this
			.deductionVolumes) {
			if (deductionVolume === deduction) {
				return volume;
			}
		}

		return 0;
	}

	serialize(): Serialized.Container {
		return {
			containerId: this.containerId,
			name: this.name,
			deductionVolumes: this.deductionVolumes
		};
	}
}
