import {
	UnlinkedInventoryItem as UnlinkedInventoryItemInterface,
	Database,
	LinkedInventoryItem,
	NutritionFacts,
	NumericDictionary,
	Serializable,
	Serialized
} from '../types';

export class UnlinkedInventoryItem implements UnlinkedInventoryItemInterface {
	readonly linked = false;
	isValid = false;

	inventoryItemId: number;
	name: string;
	containerName: string;
	containerNamePlural?: string;
	units: string;
	unitsPlural?: string;
	containerSize: number;
	calories: number;
	caloriesFromFat: number;
	fat: number;
	saturatedFat: number;
	transFat: number;
	cholesterol: number;
	sodium: number;
	carbohydrates: number;
	dietaryFiber: number;
	sugar: number;
	protein: number;
	caffeine: number;

	constructor(
		inventoryItemFields: Database.MappedInventoryItemsRow,
		tables: Database.MappedTables
	) {
		this.inventoryItemId = inventoryItemFields.inventoryItemId;
		this.name = inventoryItemFields.name;
		this.containerName = inventoryItemFields.containerName;
		this.containerNamePlural = inventoryItemFields.containerNamePlural;
		this.units = inventoryItemFields.units;
		this.unitsPlural = inventoryItemFields.unitsPlural;
		this.containerSize = inventoryItemFields.containerSize;
		this.calories = inventoryItemFields.calories;
		this.caloriesFromFat = inventoryItemFields.caloriesFromFat;
		this.fat = inventoryItemFields.fat;
		this.saturatedFat = inventoryItemFields.saturatedFat;
		this.transFat = inventoryItemFields.transFat;
		this.cholesterol = inventoryItemFields.cholesterol;
		this.sodium = inventoryItemFields.sodium;
		this.carbohydrates = inventoryItemFields.carbohydrates;
		this.dietaryFiber = inventoryItemFields.dietaryFiber;
		this.sugar = inventoryItemFields.sugar;
		this.protein = inventoryItemFields.protein;
		this.caffeine = inventoryItemFields.caffeine;

		this.isValid = true;
	}
}

export default class InventoryItem
	implements LinkedInventoryItem, Serializable<Serialized.InventoryItem> {
	readonly linked = true;

	id: number;
	inventoryItemId: number;
	name: string;
	containerName: string;
	containerNamePlural: string;
	units: string;
	unitsPlural: string;
	containerSize: number;
	nutritionFacts: NutritionFacts;

	static initialized = false;
	static inventoryItemIds: number[] = [];
	static inventoryItems: NumericDictionary<
		InventoryItem | UnlinkedInventoryItem | undefined
	> = {};

	static isInventoryItem(
		inventoryItem: UnlinkedInventoryItem | InventoryItem
	): inventoryItem is InventoryItem {
		return inventoryItem.linked;
	}

	static getInventoryItem(
		inventoryItemId: number
	): InventoryItem | undefined {
		let inventoryItem = this.inventoryItems[inventoryItemId];

		if (inventoryItem) {
			if (this.isInventoryItem(inventoryItem)) {
				return inventoryItem;
			}

			return new InventoryItem(inventoryItem);
		}
	}

	static async initialize(tables: Database.MappedTables) {
		this.initialized = false;
		this.inventoryItemIds = [];
		this.inventoryItems = {};

		for (const row of tables.inventoryItems) {
			const inventoryItem = new UnlinkedInventoryItem(row, tables);

			if (inventoryItem.isValid) {
				this.inventoryItemIds.push(inventoryItem.inventoryItemId);
				this.inventoryItems[
					inventoryItem.inventoryItemId
				] = inventoryItem;
			}
		}

		if (this.inventoryItemIds.length > 0) this.initialized = true;

		return this.initialized;
	}

	static async linkAll() {
		for (const inventoryItem of Object.values(
			InventoryItem.inventoryItems
		)) {
			if (inventoryItem) {
				// try {
				new InventoryItem(inventoryItem);
				// } catch (err) {
				// 	// console.error(err);
				// }
			}
		}

		return true;
	}

	constructor(inventoryItem: InventoryItem | UnlinkedInventoryItem) {
		InventoryItem.inventoryItems[inventoryItem.inventoryItemId] = this;

		this.id = inventoryItem.inventoryItemId;

		this.inventoryItemId = inventoryItem.inventoryItemId;
		this.name = inventoryItem.name;
		this.containerName = inventoryItem.containerName;
		this.containerNamePlural =
			inventoryItem.containerNamePlural ||
			`${inventoryItem.containerName}s`;
		this.units = inventoryItem.units;
		this.unitsPlural =
			inventoryItem.unitsPlural || `${inventoryItem.units}s`;
		this.containerSize = inventoryItem.containerSize;

		if (InventoryItem.isInventoryItem(inventoryItem)) {
			this.nutritionFacts = inventoryItem.nutritionFacts;
		} else {
			this.nutritionFacts = {
				calories: inventoryItem.calories,
				caloriesFromFat: inventoryItem.caloriesFromFat,
				fat: inventoryItem.fat,
				saturatedFat: inventoryItem.saturatedFat,
				transFat: inventoryItem.transFat,
				cholesterol: inventoryItem.cholesterol,
				sodium: inventoryItem.sodium,
				carbohydrates: inventoryItem.carbohydrates,
				dietaryFiber: inventoryItem.dietaryFiber,
				sugar: inventoryItem.sugar,
				protein: inventoryItem.protein,
				caffeine: inventoryItem.caffeine
			};
		}
	}

	linkAll(): boolean {
		return true;
	}

	serialize(): Serialized.InventoryItem {
		return {
			id: this.id,
			inventoryItemId: this.inventoryItemId,
			name: this.name,
			containerName: this.containerName,
			containerNamePlural: this.containerNamePlural,
			units: this.units,
			unitsPlural: this.unitsPlural,
			containerSize: this.containerSize,
			nutritionFacts: this.nutritionFacts
		};
	}
}
