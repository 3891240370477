import { Database, LoadingProgress } from '../types';

import {
	Component,
	ComponentGroup,
	ComponentGroupRatio,
	ComponentGroupRatioType,
	ComponentQualifier,
	ComponentVersion,
	Config,
	Container,
	InventoryItem,
	Item,
	ItemQualifier,
	Modifier,
	ModifierFunction,
	ModifierGroup,
	ModifierScale,
	Product,
	ProductGroup,
	ProductVersion,
	Size,
	Unit
} from '../lib';

export const initAndLink = async (
	tables: Database.MappedTables,
	storeId: number,
	setLoadingProgress: (progress: LoadingProgress) => void
) => {
	tables = Object.freeze(tables);

	setLoadingProgress({
		initAndLinkProgress: 2,
		initAndLinkProgressMessage: 'Initializing'
	});

	Config.initialize(tables, storeId);
	console.log('Config.initialize complete');

	setLoadingProgress({
		initAndLinkProgress: 4,
		initAndLinkProgressMessage: 'Initializing Units'
	});

	const initializeUnitsResult = Unit.initialize(tables);

	if (!initializeUnitsResult) {
		throw new Error('Failed to initialize Units.');
	}

	console.log('Unit.initialize complete');

	setLoadingProgress({
		initAndLinkProgress: 6,
		initAndLinkProgressMessage: 'Initializing Components'
	});

	const initializeComponentsResult = Component.initialize(tables);

	if (!initializeComponentsResult) {
		throw new Error('Failed to initialize Components.');
	}

	console.log('Component.initialize complete');

	setLoadingProgress({
		initAndLinkProgress: 8,
		initAndLinkProgressMessage: 'Initializing Component Groups'
	});

	const initializeComponentGroupsResult = ComponentGroup.initialize(tables);

	if (!initializeComponentGroupsResult) {
		throw new Error('Failed to initialize ComponentGroups.');
	}

	console.log('ComponentGroup.initialize complete');

	setLoadingProgress({
		initAndLinkProgress: 8,
		initAndLinkProgressMessage: 'Initializing Component Group Ratios'
	});

	const initializeComponentGroupRatiosResult = ComponentGroupRatio.initialize(
		tables
	);

	if (!initializeComponentGroupRatiosResult) {
		throw new Error('Failed to initialize ComponentGroupRatios.');
	}

	console.log('ComponentGroupRatio.initialize complete');

	setLoadingProgress({
		initAndLinkProgress: 8,
		initAndLinkProgressMessage: 'Initializing Component Group Ratio Types'
	});

	const initializeComponentGroupRatioTypesResult = ComponentGroupRatioType.initialize(
		tables
	);

	if (!initializeComponentGroupRatioTypesResult) {
		throw new Error('Failed to initialize ComponentGroupRatioTypes.');
	}

	console.log('ComponentGroupRatioType.initialize complete');

	setLoadingProgress({
		initAndLinkProgress: 10,
		initAndLinkProgressMessage: 'Initializing Component Versions'
	});

	const initializeComponentVersionsResult = ComponentVersion.initialize(
		tables
	);

	if (!initializeComponentVersionsResult) {
		throw new Error('Failed to initialize ComponentVersions.');
	}

	console.log('ComponentVersion.initialize complete');

	setLoadingProgress({
		initAndLinkProgress: 12,
		initAndLinkProgressMessage: 'Initializing Containers'
	});

	const initializeContainersResult = Container.initialize(tables);

	if (!initializeContainersResult) {
		throw new Error('Failed to initialize Containers.');
	}

	console.log('Container.initialize complete');

	setLoadingProgress({
		initAndLinkProgress: 14,
		initAndLinkProgressMessage: 'Initializing Inventory'
	});

	const initializeInventoryItemsResult = InventoryItem.initialize(tables);

	if (!initializeInventoryItemsResult) {
		throw new Error('Failed to initialize InventoryItems.');
	}

	console.log('InventoryItem.initialize complete');

	setLoadingProgress({
		initAndLinkProgress: 18,
		initAndLinkProgressMessage: 'Initializing Items'
	});

	const initializeItemsResult = Item.initialize(tables);

	if (!initializeItemsResult) {
		throw new Error('Failed to initialize Items.');
	}

	console.log('Item.initialize complete');

	setLoadingProgress({
		initAndLinkProgress: 32,
		initAndLinkProgressMessage: 'Initializing Item Qualifiers'
	});

	const initializeItemQualifiersResult = ItemQualifier.initialize(tables);

	if (!initializeItemQualifiersResult) {
		throw new Error('Failed to initialize ItemQualifiers.');
	}

	console.log('ItemQualifier.initialize complete');

	setLoadingProgress({
		initAndLinkProgress: 34,
		initAndLinkProgressMessage: 'Initializing Component Qualifiers'
	});

	const initializeComponentQualifiersResult = ComponentQualifier.initialize(
		tables
	);

	if (!initializeComponentQualifiersResult) {
		throw new Error('Failed to initialize ComponentQualifiers.');
	}

	console.log('ComponentQualifier.initialize complete');

	setLoadingProgress({
		initAndLinkProgress: 36,
		initAndLinkProgressMessage: 'Initializing Modifiers'
	});

	const initializeModifiersResult = Modifier.initialize(tables);

	if (!initializeModifiersResult) {
		throw new Error('Failed to initialize Modifiers.');
	}

	console.log('Modifier.initialize complete');

	setLoadingProgress({
		initAndLinkProgress: 38,
		initAndLinkProgressMessage: 'Initializing Modifier Functions'
	});

	const initializeModifierFunctionsResult = ModifierFunction.initialize(
		tables
	);

	if (!initializeModifierFunctionsResult) {
		throw new Error('Failed to initialize ModifierFunctions.');
	}

	console.log('ModifierFunction.initialize complete');

	setLoadingProgress({
		initAndLinkProgress: 40,
		initAndLinkProgressMessage: 'Initializing Modifier Groups'
	});

	const initializeModifierGroupsResult = ModifierGroup.initialize(tables);

	if (!initializeModifierGroupsResult) {
		throw new Error('Failed to initialize ModifierGroups.');
	}

	console.log('ModifierGroup.initialize complete');

	setLoadingProgress({
		initAndLinkProgress: 42,
		initAndLinkProgressMessage: 'Initializing Modifier Scales'
	});

	const initializeModifierScalesResult = ModifierScale.initialize(tables);

	if (!initializeModifierScalesResult) {
		throw new Error('Failed to initialize ModifierScales.');
	}

	console.log('ModifierScale.initialize complete');

	setLoadingProgress({
		initAndLinkProgress: 44,
		initAndLinkProgressMessage: 'Initializing Products'
	});

	const initializeProductsResult = Product.initialize(tables);

	if (!initializeProductsResult) {
		throw new Error('Failed to initialize Products.');
	}

	console.log('Product.initialize complete');

	setLoadingProgress({
		initAndLinkProgress: 46,
		initAndLinkProgressMessage: 'Initializing Product Groups'
	});

	const initializeProductGroupsResult = ProductGroup.initialize(tables);

	if (!initializeProductGroupsResult) {
		throw new Error('Failed to initialize ProductGroups.');
	}

	console.log('ProductGroup.initialize complete');

	setLoadingProgress({
		initAndLinkProgress: 48,
		initAndLinkProgressMessage: 'Initializing Product Versions'
	});

	const initializeProductVersionsResult = ProductVersion.initialize(tables);

	if (!initializeProductVersionsResult) {
		throw new Error('Failed to initialize ProductVersions.');
	}

	console.log('ProductVersion.initialize complete');

	setLoadingProgress({
		initAndLinkProgress: 50,
		initAndLinkProgressMessage: 'Initializing Sizes'
	});

	const initializeSizesResult = Size.initialize(tables);

	if (!initializeSizesResult) {
		throw new Error('Failed to initialize Sizes.');
	}

	console.log('Size.initialize complete');

	// TODO: check return values and error if they are false

	setLoadingProgress({
		initAndLinkProgress: 52,
		initAndLinkProgressMessage: 'Linking'
	});

	setLoadingProgress({
		initAndLinkProgress: 54,
		initAndLinkProgressMessage: 'Linking Units'
	});

	Unit.linkAll();
	console.log('Unit.linkAll complete');

	setLoadingProgress({
		initAndLinkProgress: 56,
		initAndLinkProgressMessage: 'Linking Components'
	});

	Component.linkAll();
	console.log('Component.linkAll complete');

	setLoadingProgress({
		initAndLinkProgress: 58,
		initAndLinkProgressMessage: 'Linking Component Groups'
	});

	ComponentGroup.linkAll();
	console.log('ComponentGroup.linkAll complete');

	setLoadingProgress({
		initAndLinkProgress: 60,
		initAndLinkProgressMessage: 'Linking Component Group Ratios'
	});

	ComponentGroupRatio.linkAll();
	console.log('ComponentGroupRatio.linkAll complete');

	setLoadingProgress({
		initAndLinkProgress: 62,
		initAndLinkProgressMessage: 'Linking Component Group Ratio Types'
	});

	ComponentGroupRatioType.linkAll();
	console.log('ComponentGroupRatioType.linkAll complete');

	setLoadingProgress({
		initAndLinkProgress: 64,
		initAndLinkProgressMessage: 'Linking Component Versions'
	});

	ComponentVersion.linkAll();
	console.log('ComponentVersion.linkAll complete');

	setLoadingProgress({
		initAndLinkProgress: 66,
		initAndLinkProgressMessage: 'Linking Containers'
	});

	Container.linkAll();
	console.log('Container.linkAll complete');

	setLoadingProgress({
		initAndLinkProgress: 68,
		initAndLinkProgressMessage: 'Linking Inventory'
	});

	InventoryItem.linkAll();
	console.log('InventoryItem.linkAll complete');

	setLoadingProgress({
		initAndLinkProgress: 70,
		initAndLinkProgressMessage: 'Linking Items'
	});

	Item.linkAll();
	console.log('Item.linkAll complete');

	setLoadingProgress({
		initAndLinkProgress: 72,
		initAndLinkProgressMessage: 'Linking Item Qualifiers'
	});

	ItemQualifier.linkAll();
	console.log('ItemQualifier.linkAll complete');

	setLoadingProgress({
		initAndLinkProgress: 74,
		initAndLinkProgressMessage: 'Linking Component Qualifiers'
	});

	ComponentQualifier.linkAll();
	console.log('ComponentQualifier.linkAll complete');

	setLoadingProgress({
		initAndLinkProgress: 76,
		initAndLinkProgressMessage: 'Linking Modifiers'
	});

	Modifier.linkAll();
	console.log('Modifier.linkAll complete');

	setLoadingProgress({
		initAndLinkProgress: 78,
		initAndLinkProgressMessage: 'Linking Modifier Functions'
	});

	ModifierFunction.linkAll();
	console.log('ModifierFunction.linkAll complete');

	setLoadingProgress({
		initAndLinkProgress: 80,
		initAndLinkProgressMessage: 'Linking Modifier Groups'
	});

	ModifierGroup.linkAll();
	console.log('ModifierGroup.linkAll complete');

	setLoadingProgress({
		initAndLinkProgress: 82,
		initAndLinkProgressMessage: 'Linking Modifier Scales'
	});

	ModifierScale.linkAll();
	console.log('ModifierScale.linkAll complete');

	setLoadingProgress({
		initAndLinkProgress: 84,
		initAndLinkProgressMessage: 'Linking Products'
	});

	Product.linkAll();
	console.log('Product.linkAll complete');

	setLoadingProgress({
		initAndLinkProgress: 86,
		initAndLinkProgressMessage: 'Linking Product Groups'
	});

	ProductGroup.linkAll();
	console.log('ProductGroup.linkAll complete');

	setLoadingProgress({
		initAndLinkProgress: 88,
		initAndLinkProgressMessage: 'Linking Product Versions'
	});

	ProductVersion.linkAll();
	console.log('ProductVersion.linkAll complete');

	setLoadingProgress({
		initAndLinkProgress: 90,
		initAndLinkProgressMessage: 'Linking Sizes'
	});

	Size.linkAll();
	console.log('Size.linkAll complete');

	setLoadingProgress({
		initAndLinkProgress: 92,
		initAndLinkProgressMessage: 'Linking complete'
	});

	setLoadingProgress({
		initAndLinkProgress: 100,
		initAndLinkProgressMessage: 'Initialization complete'
	});

	return;
};
