export default class ComponentUnitRatio {
	__typename: 'ComponentUnitRatio' = 'ComponentUnitRatio';
	componentId: number;
	unitId: number;
	conversionRatio: number;

	constructor(componentUnitRatio: ComponentUnitRatio) {
		this.componentId = componentUnitRatio.componentId;
		this.unitId = componentUnitRatio.unitId;
		this.conversionRatio = componentUnitRatio.conversionRatio;
	}
}
