import { v4 } from 'uuid';

import { Serializable, Serialized } from '../types';

export default class TransDiscount
	implements Serializable<Serialized.TransDiscount> {
	id: string = v4();
	isVoided: boolean = false;

	constructor(id?: string) {
		this.id = id || this.id;
	}

	serialize(): Serialized.TransDiscount {
		return {
			id: this.id
		};
	}
}
