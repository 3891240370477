import { Data } from '../types';

import { loadItem, serializeModels } from '../utils';

import {
	TransItem,
	ModifierGroupModel,
	ModifierScaleModel,
	ProductVersionModel,
	SizeModel
} from '../lib';

export const changeVersion = async (index: number): Promise<Data> => {
	console.log('changeVersion');
	console.time('changeVersion: time');

	// const oldItemId = transItem.item.id;

	// const modifierIds: number[] = transItem.modifiers.map(
	// 	transModifier => transModifier.modifier.id
	// );

	// const oldSize = transItem.item.size;

	const productVersion =
		ProductVersionModel.productVersionModel.productVersions[index];

	ProductVersionModel.productVersionModel.selectProductVersion(
		productVersion
	);

	TransItem.transItem.changeVersion(productVersion);

	loadItem();

	// const newItemId = transItem.item.itemId;

	console.timeEnd('changeVersion: time');

	const data = serializeModels();

	console.log('SizeModel.sizeModel', SizeModel.sizeModel);
	console.log(
		'ProductVersionModel.productVersionModel',
		ProductVersionModel.productVersionModel
	);
	console.log(
		'ModifierGroupModel.modifierGroupModel',
		ModifierGroupModel.modifierGroupModel
	);
	console.log(
		'ModifierScaleModel.modifierScaleModel',
		ModifierScaleModel.modifierScaleModel
	);
	console.log('TransItem.transItem', TransItem.transItem);

	console.log('data', data);

	return data;
};
