import { v4 } from 'uuid';

import {
	Dictionary,
	Serializable,
	Serialized,
	Slot,
	TransErrorCode,
	VoidErrorCode
} from '../types';

import {
	connect,
	defined,
	insert,
	serializeDictionary,
	Signal
} from '../utils';
import {
	Customer,
	Item,
	Modifier,
	Payment,
	ProductGroup,
	TransDiscount,
	TransItem,
	VoidReason
} from '.';
import Money from './Money';

type CountAmount = [number, Money];
type TransOrderTime = [number, Date];
type IdCountAmount = [number, CountAmount];

export class TransactionModel
	implements Serializable<Serialized.TransactionModel> {
	private currentTransactionIndex: number = 0;
	private openTransactionIndices: number[] = [];
	private transactions: Transaction[] = [];

	get rowCount(): number {
		return this.openTransactionIndices.length;
	}

	getCurrentTransaction(): Transaction | undefined {
		let txn: Transaction | undefined;

		if (this.transactions.length > this.currentTransactionIndex) {
			txn = this.transactions[this.currentTransactionIndex];
		}

		return txn;
	}

	pickNewestOpenTransactionOrCreate(): Transaction {
		let txn: Transaction;

		if (this.openTransactionIndices.length < 1) {
			txn = new Transaction();
			this.addTransaction(txn);
			return txn;
		}

		if (
			this.currentTransactionIndex ===
			this.openTransactionIndices[this.openTransactionIndices.length - 1]
		) {
			txn = this.transactions[this.currentTransactionIndex];
			return txn;
		}

		this.currentTransactionIndex = this.openTransactionIndices[
			this.openTransactionIndices.length - 1
		];
		txn = this.transactions[this.currentTransactionIndex];
		return txn;
	}

	addTransaction(
		transaction: Transaction,
		isNew = true,
		makeActive = true
	): void {
		// this.beginResetModel(); // TODO: slots
		let oldSelected = this.currentTransactionIndex;

		if (isNew) {
			this.transactions.push(transaction);

			if (makeActive) {
				this.currentTransactionIndex = this.transactions.length - 1;
			}
		} else {
			// Make sure we are not adding a duplicate transaction
			let duplicateTransaction = false;

			for (let i = 0; i < this.transactions.length; ++i) {
				const txn = this.transactions[i];

				if (txn.id === transaction.id) {
					duplicateTransaction = true;
					this.currentTransactionIndex = i;
					break;
				}
			}

			if (!duplicateTransaction) {
				let transactionIndex: number;

				for (
					transactionIndex = 0;
					transactionIndex < this.transactions.length;
					++transactionIndex
				) {
					if (
						this.transactions[
							transactionIndex
						].startTime.getTime() > transaction.startTime.getTime()
					) {
						this.transactions = insert(
							this.transactions,
							transactionIndex,
							transaction
						);
						this.currentTransactionIndex = transactionIndex;
						break;
					}
				}

				if (transactionIndex === this.transactions.length) {
					this.transactions.push(transaction);
					this.currentTransactionIndex = this.transactions.length - 1;
				}

				for (
					let openTransactionIndex = 0;
					openTransactionIndex < this.openTransactionIndices.length;
					++openTransactionIndex
				) {
					if (
						this.openTransactionIndices[openTransactionIndex] >=
						this.currentTransactionIndex
					) {
						this.openTransactionIndices[openTransactionIndex] =
							this.openTransactionIndices[openTransactionIndex] +
							1;
					}
				}
			}
		}

		if (transaction.isOpen) {
			if (isNew) {
				if (makeActive) {
					this.openTransactionIndices.push(
						this.currentTransactionIndex
					);
				} else {
					this.openTransactionIndices.push(
						this.transactions.length - 1
					);
				}
			} else {
				let openTransactionIndex: number;

				for (
					openTransactionIndex = 0;
					openTransactionIndex < this.openTransactionIndices.length;
					++openTransactionIndex
				) {
					const openTransaction = this.transactions[
						this.openTransactionIndices[openTransactionIndex]
					];

					if (
						openTransaction.startTime.getTime() >
						transaction.startTime.getTime()
					) {
						insert(
							this.openTransactionIndices,
							openTransactionIndex,
							this.currentTransactionIndex
						);

						break;
					}
				}

				if (
					openTransactionIndex === this.openTransactionIndices.length
				) {
					this.openTransactionIndices.push(
						this.currentTransactionIndex
					);
				}
			}
		}

		// connect(transaction.data(), SIGNAL(transactionClosed()), this, SLOT(closeTransaction())) // TODO: slot
		// connect( transaction.data(), SIGNAL(transactionReopened()), this, SLOT(reopenTransaction()) ); // TODO: slot
		// connect( transaction.data(), SIGNAL(colorStateChanged()), this, SLOT(colorChanged()) ); // TODO: slot

		// if (oldSelected !== this.currentTransactionIndex) {
		// 	const newTxn = this.transactions[this.currentTransactionIndex];
		// 	emit(transactionChanged(newTxn)); // TODO: slot
		// }

		// this.endResetModel(); // TODO: slot
	}

	hasCustomer(customerId: number, includeClosed = false): boolean {
		throw new Error('Method not implemented.');
	}

	clear(): void {
		this.openTransactionIndices = [];
		this.transactions = [];
		this.currentTransactionIndex = 0;
	}

	setOpenTransaction(openTransactionIndex: number): void {
		if (
			openTransactionIndex >= 0 &&
			openTransactionIndex < this.openTransactionIndices.length
		) {
			this.currentTransactionIndex = this.openTransactionIndices[
				openTransactionIndex
			];

			// const txn = this.transactions[this.currentTransactionIndex];
			// emit(transactionChanged(txn)); // TODO: slot
		}
	}

	nextTransaction(): void {
		if (this.currentTransactionIndex < this.transactions.length - 1) {
			++this.currentTransactionIndex;

			// const txn = this.transactions[this.currentTransactionIndex];
			// emit(transactionChanged(txn)); // TODO: slot
		}
	}

	prevTransaction(): void {
		if (this.currentTransactionIndex > 0) {
			--this.currentTransactionIndex;

			if (this.currentTransactionIndex >= this.transactions.length) {
				this.currentTransactionIndex = this.transactions.length - 1;
			}

			const txn = this.transactions[this.currentTransactionIndex];

			if (!txn) {
				++this.currentTransactionIndex;
				return;
			}

			// emit(transactionChanged(txn)); // TODO: slot
		}
	}

	oldestTransaction(): void {
		if (this.currentTransactionIndex !== 0) {
			this.currentTransactionIndex = 0;

			// const txn = this.transactions[this.currentTransactionIndex];
			// emit(transactionChanged(txn)); // TODO: slot
		}
	}

	newestTransaction(): void {
		if (this.currentTransactionIndex !== this.transactions.length - 1) {
			this.currentTransactionIndex = this.transactions.length - 1;

			// const txn = this.transactions[this.currentTransactionIndex];
			// emit(transactionChanged(txn)); // TODO: slot
		}
	}

	setCurrentTransaction(id: string): void {
		for (let n = 0; n < this.transactions.length; ++n) {
			if (this.transactions[n].id === id) {
				this.currentTransactionIndex = n;

				// const txn = this.transactions[this.currentTransactionIndex];
				// emit(transactionChanged(txn)); // TODO: slot
			}
		}

		console.error(
			'TransactionModel: setCurrentTransaction: Transaction with id ' +
				id +
				' not found!'
		);
	}

	isTransactionOpenAndNotEmpty = (transaction: Transaction, index: number) =>
		!transaction.isEmpty && this.openTransactionIndices.includes(index);

	hasOpenNonEmptyTransactions(): boolean {
		return this.transactions.some(this.isTransactionOpenAndNotEmpty);
	}

	newestTransactionNumber(): number {
		if (this.transactions.length === 0) {
			return 0;
		}

		return this.transactions[this.transactions.length - 1].orderNumber;
	}

	getTransaction(id: string): Transaction | undefined {
		return this.transactions.find(transaction => transaction.id === id);
	}

	getOpenTransactions(): Transaction[] {
		return this.transactions.filter(this.isTransactionOpenAndNotEmpty);
	}

	serialize(): Serialized.TransactionModel {
		return {
			currentTransactionIndex: this.currentTransactionIndex,
			openTransactionIndices: this.openTransactionIndices,
			transactions: this.transactions.map(transaction =>
				transaction.serialize()
			)
		};
	}
}

export default class Transaction
	implements Serializable<Serialized.Transaction> {
	id: string = v4();
	startTime: Date = new Date();
	closeTime?: Date;
	timePlaced: Date = this.startTime;
	orderNumber: number;
	isOpen: boolean = true;
	isTaxExempt: boolean = false;
	isDineIn: boolean = false;
	isDigitalOrder: boolean = false;
	hasBeenSent: boolean = false;
	fromRemoteTerminal: boolean = false;
	fromOnline: boolean = false;
	onlineOrderId: string = this.id;
	fromOrderAhead: boolean = false;

	/**
	 * Amount due before tax
	 */
	subtotal: Money = new Money();
	subtotalString?: string;

	/**
	 * Amount due with tax
	 */
	total: Money = new Money();
	totalString?: string;

	/**
	 * How much tax is due
	 */
	taxAmount: Money = new Money();
	taxAmountString?: string;

	/**
	 * Amount for items that should not be included in sales
	 */
	nonSales: Money = new Money();
	nonSalesDiscounts: Money = new Money();

	discountAmount: Money = new Money();
	discountAmountString?: string;

	/**
	 * How much is still owed after payments
	 */
	owedAmount: Money = new Money();
	owedAmountString?: string;

	change: Money = new Money();
	changeString?: string;

	cashier?: number;

	private items: Dictionary<TransItem> = {};

	private discounts: Dictionary<TransDiscount> = {};
	private payments: Dictionary<Payment> = {};
	private customer?: Customer;

	removingCustomer: boolean = false;
	addingDiscount: boolean = false;
	updatingLoyaltyDiscounts: boolean = false;
	isLocked: boolean = false;
	isClosing: boolean = false;

	// /*
	//  * SLOTS
	//  */
	// onAmountChanged: Slot<[type: 'AMOUNT_CHANGED', transactionId: string]> = {};

	// /*
	//  * SIGNALS
	//  */
	// amountChanged = new Signal(this.onAmountChanged);

	private static orderNumCounter: number = 0;

	private static nextOrderNum(): number {
		return this.orderNumCounter++;
	}

	static transactions: TransactionModel = new TransactionModel();

	static initialize(transactions: Serialized.Transaction[] = []): boolean {
		this.transactions = new TransactionModel();

		this.orderNumCounter = 0;

		this.rebuildTransactions(transactions);

		return true;
	}

	static areOpenTransactions(): boolean {
		throw new Error('Method not implemented.');
	}

	static rebuildTransactions(transactions: Serialized.Transaction[]): void {
		if (transactions.length < 1) {
			return;
		}

		let highestOrderNum = 0;

		for (const transaction of transactions) {
			if (transaction.orderNumber > highestOrderNum) {
				highestOrderNum = transaction.orderNumber;
			}

			const tmpTns = new Transaction(transaction.id, transaction);

			this.transactions.addTransaction(tmpTns);
		}

		if (highestOrderNum !== 0) {
			this.orderNumCounter = highestOrderNum;
			this.orderNumCounter++;
		}
	}

	static clearTransactions(): void {
		throw new Error('Method not implemented.');
	}

	static getTransaction(id: string): Transaction {
		throw new Error('Method not implemented.');
	}

	static loadTransaction(id: string): void {
		throw new Error('Method not implemented.');
	}

	static getProductCount(
		start: Date,
		end: Date,
		productGroup: ProductGroup,
		tillId = 0
	) {
		throw new Error('Method not implemented.');
	}

	static getNetSales(start: Date, end: Date, tillId = 0): Money {
		throw new Error('Method not implemented.');
	}

	static getGrossSales(start: Date, end: Date, tillId = 0): Money {
		throw new Error('Method not implemented.');
	}

	static getNonSaleRevenue(start: Date, end: Date, tillId = 0): Money {
		throw new Error('Method not implemented.');
	}

	static getDiscountAmount(start: Date, end: Date, tillId = 0): Money {
		throw new Error('Method not implemented.');
	}

	static getTaxAmount(start: Date, end: Date, tillId = 0): Money {
		throw new Error('Method not implemented.');
	}

	static getNumTransactions(start: Date, end: Date, tillId = 0): number {
		throw new Error('Method not implemented.');
	}

	/**
	 * Get a count and net sales amount for a set of product groups
	 * @param start Start of the date range for counting.
	 * @param end End of the date range for counting.
	 * @param groupIds The ProductGroup IDs that we are getting counts for
	 * @param tillId Optional. Specifiy to only get counts for a specific till.
	 * @returns A hash that maps the product group ID to a Count / NetSales pair
	 */
	static getProductCounts(
		start: Date,
		end: Date,
		groupIds: number[],
		tillId = 0
	): IdCountAmount {
		throw new Error('Method not implemented.');
	}

	static getTaxCount(tillId: number, start: Date, end: Date): Money {
		throw new Error('Method not implemented.');
	}

	static getDiscountCounts(
		tillId: number,
		start: Date,
		end: Date
	): IdCountAmount {
		throw new Error('Method not implemented.');
	}

	static getVoidCounts(tillId: number, start: Date, end: Date): CountAmount {
		throw new Error('Method not implemented.');
	}

	static getPaymentCounts(
		tillId: number,
		start: Date,
		end: Date
	): IdCountAmount {
		throw new Error('Method not implemented.');
	}

	static getTipcounts(tillId: number, start: Date, end: Date): CountAmount {
		throw new Error('Method not implemented.');
	}

	static getPastOrders(): TransOrderTime[] {
		throw new Error('Method not implemented.');
	}

	/**
	 * Create a new, empty transaction with the next order number
	 * @param id Rebuild transaction from id
	 * @param transaction Creates a transaction object given all values. Used to
	 * rebuild a transaction after the application closes and reopens.
	 */
	constructor(id?: string, transaction?: Serialized.Transaction) {
		if (!transaction) {
			this.id = id || this.id;
			this.calculateTotals();
			this.orderNumber = Transaction.nextOrderNum();

			return;
		}

		this.id = transaction.id;
		this.startTime = transaction.startTime;
		this.closeTime = transaction.closeTime;
		this.orderNumber = transaction.orderNumber;
		this.isOpen = transaction.open;
		this.isTaxExempt = transaction.taxExempt;
		this.cashier = transaction.cashier;
		this.isDigitalOrder = transaction.isDigitalOrder;
		this.onlineOrderId = transaction.onlineOrderId;
		this.timePlaced = transaction.onlineTimePlaced;

		for (const transItem of transaction.items) {
			const item = Item.getItemById(transItem.item.id);

			if (!item) {
				throw new Error(
					`Transaction "${this.id}"'s transItem "${transItem.id}"'s item "${transItem.item.id}" does not exist.`
				);
			}

			this.items[transItem.id] = new TransItem(item, false, transItem.id);

			for (const transModifier of transItem.modifiers) {
				const modifier = Modifier.getModifier(
					transModifier.modifier.id
				);

				if (!modifier) {
					throw new Error(
						`Transaction "${this.id}"'s transItem "${transItem.id}"'s transModifier "${transModifier.id}"'s modifier "${transModifier.modifier.id}" does not exist.`
					);
				}

				this.items[transItem.id]!.modify(modifier);
			}
		}

		for (const transDiscount of transaction.discounts) {
			this.discounts[transDiscount.id] = new TransDiscount(
				transDiscount.id
			);
		}

		for (const payment of transaction.payments) {
			this.payments[payment.id] = new Payment(payment.id);
		}

		// TODO: customer
	}

	get isEmpty(): boolean {
		return (
			this.getItems().length === 0 &&
			this.getDiscounts().length === 0 &&
			this.getPayments().length === 0 &&
			this.customer === undefined
		);
	}

	/**
	 * Add a new transItem to the transaction based on item with given
	 * itemId.
	 * @param itemId id of the item to create the new TransItem with.
	 */
	addItem(itemId: number): TransErrorCode {
		throw new Error('Method not implemented.');
	}

	/**
	 * Add a transItem to the transaction
	 * @param transItem the TransItem to add
	 */
	addTransItem(transItem: TransItem): TransErrorCode {
		if (!this.isOpen) {
			return 'TRANS_LOCKED';
		}

		if (this.isLocked) {
			return 'TRANS_LOCKED';
		}

		connect(
			transItem.onSomethingChanged,
			this.id,
			this,
			this.somethingChanged
		);

		this.items[transItem.id] = transItem;
		// this.updateLoyaltyDiscounts(); // TODO
		this.calculateTotals();

		return 'TRANS_OK';
	}

	/**
	 * Get a transItem from this transaction
	 * @param id transItem uuid
	 */
	getItem(id: string): TransItem {
		throw new Error('Method not implemented.');
	}

	/**
	 * Remove a transItem from this transaction
	 * @param id transItem uuid
	 */
	removeItem(id: string): TransErrorCode {
		throw new Error('Method not implemented.');
	}

	/**
	 * Add the given discount to the transaction, applied to the given item. If
	 * no item provided, the discount is considered to be added to the
	 * transaction.
	 * @param discount Discount to add.
	 * @param itemId uuid of the TransItem to apply the discount to.
	 * @param sendIfSent If the transaction is already sent, whether
	 * or not to send it again automatically.
	 * @param displayName UI display name for the discount.
	 */
	addDiscount(
		discount: TransDiscount,
		itemId: string,
		sendIfSent = true,
		displayName = ''
	): TransErrorCode {
		throw new Error('Method not implemented.');
	}

	/**
	 * Applies discount % to all items.
	 */
	addApplyAllDiscountPercent(
		discount: TransDiscount,
		percent: number
	): TransErrorCode {
		throw new Error('Method not implemented.');
	}

	/**
	 * Same as addDiscount, but applies to every item.
	 */
	addApplyAllDiscount(
		discount: TransDiscount,
		items: TransItem[],
		amount: number
	): TransErrorCode {
		throw new Error('Method not implemented.');
	}

	/**
	 * Get a discount from this transaction.
	 * @param id uuid of the TransDiscount
	 */
	getDiscount(id: string): TransDiscount | undefined {
		throw new Error('Method not implemented.');
	}

	/**
	 * Remove a discount from this transaction.
	 * @param id uuid of the TransDiscount to remove.
	 */
	removeDiscount(id: string, deleteDiscount = true): TransErrorCode {
		throw new Error('Method not implemented.');
	}

	/**
	 * Add a payment to this transaction.
	 * @param payment The payment to add.
	 */
	addPayment(payment: Payment): TransErrorCode {
		throw new Error('Method not implemented.');
	}

	/**
	 * Get a payment from this transaction.
	 * @param id uuid of the payment.
	 */
	getPayment(id: string): Payment {
		throw new Error('Method not implemented.');
	}

	/**
	 * Assigns a customer to this transaction.
	 * @param customer Customer to assign.
	 */
	addCustomer(customer: Customer): TransErrorCode {
		throw new Error('Method not implemented.');
	}

	/**
	 * Get the customer that is assigned to the transaction.
	 */
	getCustomer(): Customer | undefined {
		return this.customer;
	}

	/**
	 * Remove the customer from the transaction.
	 */
	removeCustomer(): TransErrorCode {
		throw new Error('Method not implemented.');
	}

	/**
	 * Get all the transItems on this transaction.
	 */
	getItems(): TransItem[] {
		return Object.values(this.items).filter(defined);
	}

	// /**
	//  * Get all the transItems on this transaction with their models.
	//  */
	// getLinkedItems(): LinkedTransItem[] {
	// 	return Object.values(this.linkedItems).filter(defined);
	// }

	/**
	 * Get all the discounts on this transaction.
	 */
	getDiscounts(): TransDiscount[] {
		return Object.values(this.discounts).filter(defined);
	}

	/**
	 * Get all the payments on this transaction.
	 */
	getPayments(): Payment[] {
		return Object.values(this.payments).filter(defined);
	}

	/**
	 * Void all items in this transaction.
	 * @param reason Reason for voiding the items.
	 */
	voidAllItems(reason: VoidReason): VoidErrorCode {
		throw new Error('Method not implemented.');
	}

	hasItem(
		itemId: number,
		includeDiscounted = false,
		includeVoided = false
	): number {
		throw new Error('Method not implemented.');
	}

	getItemTotal(
		itemId: number,
		includeDiscounted = false,
		includeVoided = false
	): Money {
		throw new Error('Method not implemented.');
	}

	getOrderIdString(): string {
		throw new Error('Method not implemented.');
	}

	send(writeImmediately = false): TransErrorCode {
		throw new Error('Method not implemented.');
	}

	close(): TransErrorCode {
		throw new Error('Method not implemented.');
	}

	reopen(): TransErrorCode {
		throw new Error('Method not implemented.');
	}

	calculateTotals(): boolean {
		console.log('Transaction: calculateTotals');
		this.subtotal = new Money();
		this.taxAmount = new Money();
		this.nonSales = new Money();
		this.nonSalesDiscounts = new Money();
		this.total = new Money();
		this.owedAmount = new Money();
		this.change = new Money();
		this.discountAmount = new Money();

		let roundedTotal = new Money();
		let highestIndex = -1;
		let highestRounded = new Money();
		let taxedItems: number[] = [];

		const items = this.getItems();

		for (let n = 0; n < items.length; ++n) {
			const item = items[n];

			let discountAmount = new Money();

			if (item.isVoided) {
				continue;
			}

			if (item.discountId) {
				const disc = this.getDiscount(item.discountId);

				if (disc) {
					discountAmount.assign(item.getDiscountAmount());

					if (item.isReturn) {
						discountAmount.multiply(-1);
					}

					if (!disc.isVoided) {
						this.discountAmount.add(discountAmount);
					}
				}
			}

			if (!item.isReturn) {
				this.subtotal.add(item.price);

				if (!item.item.product.includeInSales) {
					this.nonSales.add(item.price);
					this.nonSalesDiscounts.add(discountAmount);
				} else {
					if (this.isTaxExempt) {
						item.taxRate = 0;
						continue;
					}

					let itemTax = Money.subtract(item.price, discountAmount);
					itemTax.multiply(item.taxRate);

					if (Money.notEqual(itemTax, 0)) {
						taxedItems.push(n);
					}

					this.taxAmount.add(itemTax);

					let itemTaxRounded = itemTax;
					itemTaxRounded.round();

					roundedTotal.add(itemTaxRounded);

					let diff = Money.subtract(itemTax, itemTaxRounded);

					if (Money.lessThan(diff, 0)) {
						diff.assign(Money.multiply(diff, -1));
					}

					if (Money.greaterThan(diff, highestRounded)) {
						highestRounded.assign(diff);
						highestIndex = n;
					}

					item.taxAmount = itemTaxRounded;
				}
			} else {
				this.subtotal.subtract(item.price);

				if (!item.item.product.includeInSales) {
					this.nonSales.subtract(item.price);
					this.nonSalesDiscounts.subtract(discountAmount);
				} else {
					if (this.isTaxExempt) {
						item.taxRate = 0;
						continue;
					}

					let itemTax = Money.subtract(item.price, discountAmount);
					itemTax.multiply(item.taxRate);

					if (Money.notEqual(itemTax, 0)) {
						taxedItems.push(n);
					}

					this.taxAmount.subtract(itemTax);

					let itemTaxRounded = itemTax;
					itemTaxRounded.round();

					roundedTotal.subtract(itemTaxRounded);

					let diff = Money.subtract(itemTax, itemTaxRounded);

					if (Money.lessThan(diff, 0)) {
						diff.assign(Money.multiply(diff, -1));
					}

					if (Money.greaterThan(diff, highestRounded)) {
						highestRounded.assign(diff);
						highestIndex = n;
					}

					item.taxAmount = itemTaxRounded;
				}
			}
		}

		if (this.isTaxExempt) {
			this.taxAmount.assign(0);
		} else {
			this.taxAmount.round();

			let roundingDiff = Money.subtract(this.taxAmount, roundedTotal);

			if (Money.notEqual(roundingDiff, 0)) {
				if (highestIndex === -1) {
					if (taxedItems.length !== 0) {
						highestIndex = taxedItems[0];
					} else {
						highestIndex = -2;
					}

					if (highestIndex !== -2) {
						let newTax = items[highestIndex].taxAmount;

						if (items[highestIndex].isReturn) {
							newTax.subtract(roundingDiff);
						} else {
							newTax.add(roundingDiff);
						}

						items[highestIndex].taxAmount = newTax;
					}
				}
			}
		}

		this.subtotal.round();
		this.nonSales.round();

		this.subtotal.subtract(this.discountAmount);

		debugger;

		this.total.assign(Money.add(this.subtotal, this.nonSales));
		this.owedAmount.assign(this.total);

		for (const payment of this.getPayments().filter(
			payment => !payment.isVoided
		)) {
			this.owedAmount.subtract(payment.amount);
			this.change.add(payment.overageAmount);
		}

		this.subtotalString = this.subtotal.toString();
		this.taxAmountString = this.taxAmount.toString();
		this.totalString = this.total.toString();
		this.owedAmountString = this.owedAmount.toString();
		this.changeString = this.owedAmount.toString();

		// this.amountChanged.emit(); // TODO: slots

		return true;
	}

	updateLoyaltyDiscounts(): void {
		throw new Error('Method not implemented.');
	}

	somethingChanged(): void {
		console.log('Transaction: somethingChanged');
		this.calculateTotals();
	}

	serialize(): Serialized.Transaction {
		return {
			id: this.id,
			startTime: this.startTime,
			closeTime: this.closeTime,
			orderNumber: this.orderNumber,
			open: this.isOpen,
			taxExempt: this.isTaxExempt,
			cashier: this.cashier,
			isDigitalOrder: this.isDigitalOrder,
			onlineOrderId: this.onlineOrderId,
			onlineTimePlaced: this.timePlaced,
			subTotal: this.subtotal.serialize(),
			total: this.total.serialize(),
			taxAmount: this.taxAmount.serialize(),
			nonSales: this.nonSales.serialize(),
			discountAmount: this.discountAmount.serialize(),
			owedAmount: this.owedAmount.serialize(),
			change: this.change.serialize(),
			discounts: serializeDictionary(this.discounts),
			items: serializeDictionary(this.items),
			payments: serializeDictionary(this.payments),
			customer: this.customer?.serialize()
		};
	}
}
